import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useInput } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import { update } from 'ramda';

export const DistancePackages = props => {
  const inputProps = useInput({
    ...props,
    id: 'distancePackages',
    source: 'distancePackages',
  });
  const { value: distancePackageValues, onChange } = inputProps.input;

  return (
    <Grid container spacing={3} style={{ margin: 0 }}>
      <h3 className="title" style={{ width: '100%', margin: '30px 0 20px' }}>
        Пакеты километров
      </h3>

      {distancePackageValues.length ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
          }}
        >
          {distancePackageValues.map((field, index) => {
            const { kmQuantity, price } = field;

            return (
              <Card style={{ margin: '14px 14px', width: '300px' }}>
                <CardContent>
                  <Typography color="text.secondary">
                    <strong>Километры: </strong>
                    <span>{kmQuantity}</span>
                  </Typography>

                  <TextField
                    fullWidth
                    label="Цена"
                    key="price"
                    margin="dense"
                    name="price"
                    onChange={({ target }) =>
                      onChange(
                        update(
                          index,
                          { ...field, [target.name]: parseInt(target.value, 10) },
                          distancePackageValues,
                        ),
                      )
                    }
                    value={price}
                    variant="outlined"
                  />
                </CardContent>
              </Card>
            );
          })}
        </div>
      ) : (
        <strong>Пакеты километров пока не заполнены</strong>
      )}
    </Grid>
  );
};
