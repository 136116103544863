import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  makeStyles,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import { formatInTimeZone } from 'date-fns-tz';
import { ru } from 'date-fns/locale';
import { Alert, AlertTitle } from '@material-ui/lab';
import { serverTimeZone } from '../../utils/date';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export const RentTableList = ({ record: { rents } }) => {
  const classes = useStyles();

  return (
    <div>
      {rents?.length ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="rents table">
            <TableHead>
              <TableRow>
                <StyledTableCell>№</StyledTableCell>
                <StyledTableCell>Идентификатор аренды</StyledTableCell>
                <StyledTableCell>Статус аренды</StyledTableCell>
                <StyledTableCell>Аренда застрахована</StyledTableCell>
                <StyledTableCell>Начало аренды</StyledTableCell>
                <StyledTableCell>Конец аренды</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rents.map((row, rowIndex) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>{rowIndex + 1}</StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <Link
                      target="_blank"
                      to={{
                        pathname: `/rent/${row.id}/show`,
                      }}
                    >
                      <span>
                        Аренда <sup>{row.id}</sup>
                      </span>
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell>{row.status}</StyledTableCell>
                  <StyledTableCell>{row.insured ? 'Да' : 'Нет'}</StyledTableCell>
                  <StyledTableCell>
                    {formatInTimeZone(
                      new Date(row.startDate),
                      serverTimeZone,
                      'd MMMM yyyy, HH:mm OOOO',
                      {
                        locale: ru,
                      },
                    )}
                  </StyledTableCell>
                  <StyledTableCell>
                    {formatInTimeZone(
                      new Date(row.endDate),
                      serverTimeZone,
                      'd MMMM yyyy, HH:mm OOOO',
                      {
                        locale: ru,
                      },
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert variant="filled" severity="info">
          <AlertTitle>Пусто</AlertTitle>Тут пока нет ни одной аренды!
        </Alert>
      )}
    </div>
  );
};

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
