import React from 'react';

import { Inspection } from './Inspection';
import { preRentalInspectionSteps, postRentalInspectionSteps } from './inspectionSteps';

export const RentalInspection = ({ record, stage = '' }) => {
  const data =
    stage === 'preRentalInspection'
      ? { inspection: record.preRentalInspection, inspectionSteps: preRentalInspectionSteps }
      : { inspection: record.postRentalInspection, inspectionSteps: postRentalInspectionSteps };
  return data ? (
    <div className="rent-container">
      <Inspection {...data} />
    </div>
  ) : null;
};
