import React from 'react';

import { RentInfo } from './RentInfo';
import { CarInfo } from './CarInfo';
import { UserInfo } from './UserInfo';

export const RentData = ({ record }) => {
  return (
    <div className="rent-container">
      <div className="block">
        <UserInfo user={record.guest} role="guest" />
        <UserInfo user={record.host} role="host" />
      </div>
      <div className="block -car-rent">
        <RentInfo rent={record.rent} insurancePayments={record.insurancePayments} />
        <CarInfo
          car={record.car}
          carSnapshot={record.carSnapshot}
          deliveryPopularPlaces={record.deliveryPopularPlaces}
          deliveryRadii={record.deliveryRadii}
        />
      </div>
    </div>
  );
};
