import React from 'react';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';

import { TextField } from '../components/TextField';
import { CheckboxField } from '../components/CheckboxField';
import { CheckActions } from '../components/CheckActions';
import { ErrorMessage } from '../components/ErrorMessage';

const initialFormState = {
  isBannedByRentals: false,
};
const mapToInitialValues = data => {
  if (!data) {
    return initialFormState;
  }

  return {
    isValid: data.isBannedByRentals,
    isBannedByRentals: data.isBannedByRentals,
  };
};

export const RentalBlackListCheck = ({ onSubmit, check = {} }) => {
  const { verdict, data = {}, isError } = check;
  const { factors = [] } = data;
  const initialValues = mapToInitialValues(data);

  const isInfoShown = !!factors.length;

  return (
    <div className="form-wrap">
      <h3 className="title">Черные списки прокатов</h3>
      {isError && <ErrorMessage />}
      <Form onSubmit={onSubmit(check)} initialValues={initialValues}>
        {({ handleSubmit, dirty }) => (
          <form onSubmit={handleSubmit} className="form">
            <TextField label="Статус проверки:" value={verdict} />
            <CheckboxField label="Находится в черном списке прокатов?" name="isBannedByRentals" />

            <CheckActions isSaveDisabled={!dirty} isInfoShown={isInfoShown}>
              <h4 className="title">Факторы</h4>
              {factors.map(factor => (
                <>
                  <Box className="factor-wrap">
                    <TextField label="Дата:" value={factor?.created} />
                    <TextField label="Происшествие:" value={factor?.violationInfo} />
                    <TextField label="Название прокатной компании:" value={factor?.authorName} />
                    <TextField label="Телефон прокатной компании:" value={factor?.authorPhone} />
                  </Box>
                </>
              ))}
            </CheckActions>
          </form>
        )}
      </Form>
    </div>
  );
};
