import {
  format as dateFnsFormat,
  parseISO,
  addDays,
  addHours,
  getMinutes,
  setMinutes,
} from 'date-fns';
import { ru } from 'date-fns/locale';
import { format as withoutZoneFormat, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

export const serverTimeZone = 'Europe/Moscow';
export const hoursForPrepearing = 5;

export const fromUTCtoLocalDate = utcDate => {
  const dateWithZone = utcToZonedTime(new Date(utcDate), serverTimeZone);
  const dateWithoutZone = withoutZoneFormat(dateWithZone, "yyyy-MM-dd'T'HH:mm", {
    timeZone: serverTimeZone,
  });
  return dateWithoutZone;
};

export const zonedDateToUTC = zonedDate => zonedTimeToUtc(zonedDate, serverTimeZone).toISOString();

export const getDate = (dateISO, format = 'dd MMM yyyy HH:mm', locale = 'en') => {
  const date = parseISO(dateISO);
  return dateFnsFormat(date, format, { locale: ru });
};

export const parseDate = date => dateFnsFormat(parseISO(date), "yyyy-MM-dd'T'HH:mm");

export const getDateWithDelay = (date, delayInDays) => {
  const dateWithDelay = addDays(parseISO(date), delayInDays);
  return dateFnsFormat(dateWithDelay, "yyyy-MM-dd'T'HH:mm");
};

export const toHumanDateInRussian = date => dateFnsFormat(date, 'dd MMM yy', { locale: ru });
