import React from 'react';
import RentInsurancePaymentsInfo from './RentInsurancePaymentsInfo';

const RentInsuranceData = ({ record }) => {
  return (
    <div className="rent-container">
      <div className="block">
        <RentInsurancePaymentsInfo insurancePayments={record.insurancePayments} />
      </div>
    </div>
  );
};

export default RentInsuranceData;
