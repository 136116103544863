import React from 'react';
import TextField from '@material-ui/core/TextField';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import InputLabel from '@material-ui/core/InputLabel';
import { CheckboxGroupInput } from 'react-admin';
import { TextAreaField } from './components/GuestScoringReport/components/TextAreaField';

export const renderer = (
  { name, label, key, type, options },
  { value, onChange },
  classes = {},
) => {
  switch (type) {
    case 'number': {
      return (
        <TextField
          label={label}
          key={key}
          margin="dense"
          name={name}
          onChange={({ target }) => {
            onChange(parseInt(target.value, 10));
          }}
          value={value || ''}
          variant="outlined"
          style={{ width: '90%' }}
          type="number"
        />
      );
    }
    case 'multitext': {
      return (
        <TextField
          label={label}
          key={name}
          margin="dense"
          name={name}
          onChange={({ target }) => {
            onChange(target.value);
          }}
          value={value}
          variant="outlined"
          style={{ width: '90%' }}
          multiline
          rows={3}
        />
      );
    }
    case 'float': {
      return (
        <TextField
          label={label}
          key={key}
          margin="dense"
          name={name}
          onChange={({ target }) => {
            onChange(parseFloat(target.value, 10));
          }}
          value={value || ''}
          variant="outlined"
          style={{ width: '90%' }}
          type="number"
        />
      );
    }
    case 'select': {
      const { margin, ...otherClasses } = classes;
      return (
        <FormControl style={{ width: '90%' }} className={margin}>
          <InputLabel shrink id={`${name}-select-label`}>
            {label}
          </InputLabel>
          <Select
            labelId={`${name}-select-label`}
            id={`${name}-select`}
            value={value}
            onChange={onChange}
            variant="outlined"
            classes={otherClasses}
          >
            {options.map(opt => {
              const isStructuredOption = typeof opt === 'object';
              const value = isStructuredOption ? opt.value : opt;
              const label = isStructuredOption ? opt.label : opt;

              return (
                <MenuItem value={value} key={opt}>
                  {label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      );
    }
    case 'checkbox': {
      return (
        <FormControlLabel
          control={<Checkbox checked={value} onChange={onChange} name={name} />}
          label={label}
          justify="flex-start"
        />
      );
    }
    case 'checkboxGroup': {
      return (
        <CheckboxGroupInput source={name} choices={options} onChange={onChange} label={label} />
      );
    }
    default: {
      return (
        <TextField
          label={label}
          key={name}
          margin="dense"
          name={name}
          onChange={({ target }) => {
            onChange(target.value);
          }}
          value={value}
          variant="outlined"
          style={{ width: '90%' }}
        />
      );
    }
  }
};
