import React, { useState } from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { PhotoPreview } from '../../../common/PhotoPreview';

const getPhotoKey = (url = '') => {
  const regexp = /-(\d|\w+)\./;
  const match = url.match(regexp)[0];
  return match.slice(1, match.length - 1);
};
const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const RentDecline = ({ record }) => {
  const [dialogOption, setDialogOption] = useState({ open: false, url: '' });
  const handleClose = () => {
    setDialogOption({ open: false, url: '' });
  };

  if (!record.rentDecline) {
    return null;
  }

  const { id = '', initiator = '', comment = '', photos = [] } = record.rentDecline;
  return (
    <div className="rent-container">
      <div className="info-wrapper">
        <Dialog
          disablePortal
          fullScreen
          open={dialogOption.open}
          TransitionComponent={Transition}
          className="photo-dialog"
        >
          <CardMedia image={dialogOption.url} className="image" />
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className="icon"
          >
            <CloseIcon />
          </IconButton>
        </Dialog>
        <div className="wrap">
          <h3 className="title -section">Данные отмены аренды</h3>
          <div className="text-field">
            <span className="label">ID:</span>
            <span className="text">{id}</span>
          </div>
          <div className="text-field">
            <span className="label">Инициатор:</span>
            <span className="text">{initiator}</span>
          </div>
          <div className="text-field">
            <span className="label">Комментарий:</span>
            <span className="text">{comment}</span>
          </div>
        </div>
        <div className="wrap">
          <h3 className="title -section">Фото отмены</h3>
          <div className="inspection-photos">
            {photos.map(photo => (
              <PhotoPreview photo={photo} />
            ))}
            {/* {photos.map(photoSrc => {
              const key = getPhotoKey(photoSrc);
              return (
                <CardMedia
                  image={photoSrc}
                  key={key}
                  className="image"
                  onClick={() => setDialogOption({ open: true, url: photoSrc })}
                />
              );
            })} */}
          </div>
        </div>
      </div>
    </div>
  );
};
