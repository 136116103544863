import React, { useState } from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { PhotoPreview } from '../../../common/PhotoPreview';
import './styles.scss';

const parseBoolean = item => (item ? 'да' : 'нет');
const getPhotoKey = (url = '') => {
  const regexp = /-(\d|\w+)\./;
  const match = url.match(regexp)[0];
  return match.slice(1, match.length - 1);
};
const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Inspection = ({ inspection, inspectionSteps = {} }) => {
  const [dialogOption, setDialogOption] = useState({ open: false, url: '' });
  const handleClose = () => {
    setDialogOption({ open: false, url: '' });
  };

  const inspectionName =
    inspection === 'preRentalInspection' ? 'Предарендный осмотр' : 'Постарендный осмотр';

  const { id = '', inspectionFinished, host = {}, guest = {} } = inspection || {};

  return (
    <div>
      <div className="info-wrapper">
        <Dialog
          disablePortal
          fullScreen
          open={dialogOption.open}
          TransitionComponent={Transition}
          className="photo-dialog"
        >
          <CardMedia image={dialogOption.url} className="image" />
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            className="icon"
          >
            <CloseIcon />
          </IconButton>
        </Dialog>
        <div className="wrap">
          <h3 className="title -section">Данные {inspectionName}</h3>
          <div className="text-field">
            <span className="label">ID:</span>
            <span className="text">{id}</span>
          </div>
          <div className="text-field">
            <span className="label">{inspectionName} закончен:</span>
            <span className="text">{parseBoolean(inspectionFinished)}</span>
          </div>
        </div>
        <div className="titles">
          <h3 className="title -section">Фото хоста</h3>
          <h3 className="title -section">Фото госта</h3>
        </div>
        <div className="photos-container">
          {Object.keys(inspectionSteps).map(step =>
            host[step]?.length || guest[step]?.length ? (
              <div className="wrap">
                <h3 className="title -step">{inspectionSteps[step]}</h3>
                <div className="inspection-photos">
                  {host[step] && host[step].map(photo => <PhotoPreview photo={photo} />)}
                </div>
                <div className="inspection-photos">
                  {guest[step] && guest[step].map(photo => <PhotoPreview photo={photo} />)}
                </div>
              </div>
            ) : null,
          )}
        </div>
      </div>
    </div>
  );
};
