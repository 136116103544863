import React, { useCallback } from 'react';
import { SaveButton, useDataProvider, useNotify } from 'react-admin';

export const UpdateButton = props => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { resource } = props;

  const handleSave = useCallback(
    async values => {
      try {
        await dataProvider.updateUser(resource, {
          id: values.id,
          data: { ...values, kbm: values.kbm ? Number(values.kbm) : values.kbm },
        });
        await dataProvider.getOne(resource, { id: values.id });

        notify('Данные успешно обновлены');
      } catch (error) {
        notify(`При обновление данных возникла ошибка: ${error}`);
      }
    },
    [dataProvider, notify, resource],
  );

  // TODO: remove pristine prop from SaveButton because of hardcode - create custom button instead SaveButton
  return <SaveButton {...props} onSave={handleSave} label="Обновить информацию" pristine={false} />;
};
