import includedFeatures from '../../../../fixtures/carIncludedFeatures.json';

export const fieldsSchema = [
  {
    name: 'includedFeatures',
    label: 'Включенные функции',
    key: 'includedFeatures',
    source: 'includedFeatures',
    type: 'checkboxGroup',
    options: includedFeatures,
  },
  {
    name: 'rentPricePerDay',
    label: 'Стоимость аренды за день',
    key: 'rentPricePerDay',
    type: 'number',
  },
  {
    name: 'rentPricePerHour',
    label: 'Стоимость аренды за час',
    key: 'rentPricePerHour',
    type: 'number',
  },
  {
    name: 'additionalKmPrice',
    label: 'Дополнительная цена за км',
    key: 'additionalKmPrice',
    type: 'float',
  },
  {
    name: 'twoDaysDiscount',
    label: '2х-дневная скидка',
    key: 'twoDaysDiscount',
    type: 'number',
  },
  {
    name: 'threeDaysDiscount',
    label: '3х-дневная скидка',
    key: 'threeDaysDiscount',
    type: 'number',
  },
  {
    name: 'fourDaysDiscount',
    label: '4х-дневная скидка',
    key: 'fourDaysDiscount',
    type: 'number',
  },
  {
    name: 'fiveDaysDiscount',
    label: '5-дневная скидка',
    key: 'fiveDaysDiscount',
    type: 'number',
  },
  {
    name: 'sixDaysDiscount',
    label: '6-дневная скидка',
    key: 'sixDaysDiscount',
    type: 'number',
  },
  {
    name: 'weekDiscount',
    label: 'Недельная скидка',
    key: 'weekDiscount',
    type: 'number',
  },
  {
    name: 'twoWeeksDiscount',
    label: '2х недельная скидка',
    key: 'twoWeeksDiscount',
    type: 'number',
  },
  {
    name: 'monthDiscount',
    label: 'Месячная скидка',
    key: 'monthDiscount',
    type: 'number',
  },
  {
    name: 'twoHoursDiscount',
    label: '2х-часовая скидка',
    key: 'twoHoursDiscount',
    type: 'number',
  },
  {
    name: 'threeHoursDiscount',
    label: '3х-часовая скидка',
    key: 'threeHoursDiscount',
    type: 'number',
  },
  {
    name: 'fourHoursDiscount',
    label: '4х-часовая скидка',
    key: 'fourHoursDiscount',
    type: 'number',
  },
  {
    name: 'fiveHoursDiscount',
    label: '5ти-часовая скидка',
    key: 'fiveHoursDiscount',
    type: 'number',
  },
  {
    name: 'sixHoursDiscount',
    label: '6ти-часовая скидка',
    key: 'sixHoursDiscount',
    type: 'number',
  },
  {
    name: 'sevenHoursDiscount',
    label: '7ми-часовая скидка',
    key: 'sevenHoursDiscount',
    type: 'number',
  },
  {
    name: 'eightHoursDiscount',
    label: '8ми-часовая скидка',
    key: 'eightHoursDiscount',
    type: 'number',
  },
  {
    name: 'nineHoursDiscount',
    label: '9ти-часовая скидка',
    key: 'ninetHoursDiscount',
    type: 'number',
  },
  {
    name: 'tenHoursDiscount',
    label: '10ти-часовая скидка',
    key: 'tenHoursDiscount',
    type: 'number',
  },
  {
    name: 'elevenHoursDiscount',
    label: '11ти-часовая скидка',
    key: 'elevenHoursDiscount',
    type: 'number',
  },
  {
    name: 'twelveHoursDiscount',
    label: '12ти-часовая скидка',
    key: 'twelveHoursDiscount',
    type: 'number',
  },
  {
    name: 'minRentDays',
    label: 'Минимальные дни аренды',
    key: 'minRentDays',
    type: 'select',
    options: [
      '1 hour',
      '2 hours',
      '3 hours',
      '4 hours',
      '5 hours',
      '6 hours',
      '7 hours',
      '8 hours',
      '9 hours',
      '10 hours',
      '11 hours',
      '12 hours',
      '1 day',
      '3 days',
      '7 days',
      '14 days',
      '30 days'
    ],
  },
  {
    name: 'maxRentDays',
    label: 'Максимальные дни аренды',
    key: 'maxRentDays',
    type: 'select',
    options: ['7 days', '14 days', '30 days', 'infinity'],
  },
  {
    name: 'dailyMilageLimit',
    label: 'Лимит дневного пробега',
    key: 'dailyMilageLimit',
    type: 'number',
  },
  {
    name: 'weeklyMilageLimit',
    label: 'Еженедельный лимит пробега',
    key: 'weeklyMilageLimit',
    type: 'number',
  },
  {
    name: 'monthlyMilageLimit',
    label: 'Ежемесячный лимит пробега',
    key: 'monthlyMilageLimit',
    type: 'number',
  },
  {
    name: 'offHoursReturnPrice',
    label: 'Цена возврата в нерабочее время',
    key: 'offHoursReturnPrice',
    type: 'number',
  },
  {
    name: 'afterRentWashingPrice',
    label: 'Стоимость мойки после аренды',
    key: 'afterRentWashingPrice',
    type: 'number',
  },
  {
    name: 'unlimitedMilagePrice',
    label: 'Стоимость неограниченного пробега',
    key: 'unlimitedMilagePrice',
    type: 'number',
  },
  {
    name: 'fuelLiterCompensationPrice',
    label: 'Компенсационная цена за литр топлива',
    key: 'fuelLiterCompensationPrice',
    type: 'number',
  },
  {
    name: 'driversAge',
    label: 'Возраст водителя',
    key: 'driversAge',
    type: 'number',
  },
  {
    name: 'drivingExperience',
    label: 'Стаж водителя',
    key: 'drivingExperience',
    type: 'number',
  },
  {
    name: 'seatsQuantity',
    label: 'Кол-во мест (2-9 max)',
    key: 'seatsQuantity',
    type: 'number',
  },
  {
    name: 'rentLocations',
    label: 'География аренды',
    key: 'rentLocations',
    type: 'select',
    options: [
      {
        value: 'CITY',
        label: 'Только в городе',
      },
      {
        value: 'COUNTRY',
        label: 'По региону и области',
      },
      {
        value: 'WORLD',
        label: 'По всей стране',
      },
    ],
  },
  {
    name: 'pledgePrice',
    label: 'Стоимость залога',
    key: 'pledgePrice',
    type: 'number',
  },
  {
    name: 'pledgeMaxTerm',
    label: 'Длительность залога',
    key: 'pledgeMaxTerm',
    type: 'number',
  },
  {
    name: 'greetingMessage',
    label: 'Приветственное сообщение',
    key: 'greetingMessage',
    type: 'text',
  },
  {
    name: 'pledgeNoRefundReason',
    label: 'Причина невозврата залога',
    key: 'pledgeNoRefundReason',
    type: 'multitext',
  },
  {
    name: 'rentRecomendations',
    label: 'Рекомендации по аренде',
    key: 'rentRecomendations',
    type: 'multitext',
  },
  {
    name: 'description',
    label: 'Описание',
    key: 'description',
    source: 'description',
    type: 'multitext',
  },
  {
    name: 'guestInstructions',
    label: 'Инструкции для гостов',
    key: 'guestInstructions',
    type: 'multitext',
  },
  {
    name: 'rentPricePerDayForGuest',
    label: 'Стоимость аренды в сутки ДЛЯ ГОСТА',
    key: 'rentPricePerDayForGuest',
    type: 'number',
  },
];
