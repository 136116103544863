import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';

export const colors = {
  NOT_VERIFIED: '#E24C4C',
  VERIFICATION: '#FE9D3F',
  INVALID: '#E24C4C',
  PUBLISHED: '#06BB9B',
  STASHED: '#5D5D5D',
  BANNED: 'E24C4C',
  RENT: '#1973E8',
};

export const StatusBar = ({ source, record }) => {
  const identity = {
    id: record.id,
    ownerId: record.ownerId,
  };
  const meta = {
    createdAt: record.createdAt,
    updatedAt: record.updatedAt,
    deletedAt: record.deletedAt,
  };
  const statuses = {
    status: record.status,
    scoringStatus: record.scoringStatus,
    moderationStatus: record.moderationStatus,
  };

  return (
    <Grid
      container
      spacing={1}
      wrap="wrap"
      direction="row"
      justify="space-between"
      alignItems="flex-start"
    >
      <Grid container alignItems="center" direction="column" item xs={4}>
        <Typography variant="h6" gutterBottom>
          Identity data
        </Typography>
        <Grid item key="id">
          <Typography variant="subtitle2" gutterBottom display="inline">
            id:
          </Typography>
          <Typography variant="subtitle1" gutterBottom display="inline">
            {record.id}
          </Typography>
        </Grid>
        <Grid item key="id">
          <Typography variant="subtitle2" gutterBottom display="inline">
            ownerId:
          </Typography>
          <Link
            to={{
              pathname: `/user/${record.ownerId}`,
            }}
          >
            <Typography variant="subtitle1" gutterBottom display="inline">
              {record.ownerId}
            </Typography>
          </Link>
        </Grid>
      </Grid>
      <Grid container alignItems="center" direction="column" item xs={4}>
        <Typography variant="h6" gutterBottom>
          Metadata
        </Typography>
        {Object.keys(meta).map(key => (
          <Grid item key={key}>
            <Typography variant="subtitle2" gutterBottom display="inline">
              {key}:
            </Typography>
            <Typography variant="subtitle1" gutterBottom display="inline">
              {meta[key]}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid container alignItems="center" direction="column" item xs={4}>
        <Typography variant="h6" gutterBottom>
          Statuses
        </Typography>
        {Object.keys(statuses).map(key => (
          <Grid item key={key}>
            <Typography variant="subtitle2" gutterBottom display="inline">
              {key}:
            </Typography>
            <Typography variant="subtitle1" gutterBottom display="inline">
              {statuses[key]}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
