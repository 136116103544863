import React, { useCallback, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useDataProvider, useNotify } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Box from '@material-ui/core/Box';
import './styles.scss';

import { Loader } from '../../../../common/Loader';

export const Actions = ({ record, resource }) => {
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { labels = [], id: userId = '', role } = record;
  const isSuperHost = labels.includes('SUPERHOST');
  const isSuperGuest = labels.includes('SUPERGUEST');

  const onSubmit = useCallback(
    async ({ password }, { reset }) => {
      setLoading(true);
      try {
        await dataProvider.setTemporaryPassword({
          password,
          id: userId,
        });

        setTimeout(() => {
          reset();
        }, 0);
        setLoading(false);
        notify('Временный пароль успешно установлен');
      } catch (error) {
        setLoading(false);
        const err = error?.response?.data?.message || error;
        notify(`Ошибка: ${err}`);
      }
    },
    [dataProvider, notify, userId],
  );
  const onReset = useCallback(async () => {
    setLoading(true);
    try {
      await dataProvider.resetTemporaryPassword({ id: userId });

      setLoading(false);
      notify('Пользовательский пароль успешно установлен');
    } catch (error) {
      setLoading(false);
      const err = error?.response?.data?.message || error;
      notify(`Ошибка: ${err}`);
    }
  }, [dataProvider, notify, userId]);

  const detachPaymentCard = useCallback(async () => {
    setLoading(true);
    try {
      await dataProvider.detachPaymentCard({ id: userId });
      setLoading(false);
      notify('Платежная карта успешно отвязана');
    } catch (error) {
      setLoading(false);
      const err = error?.response?.data?.message || error;
      notify(`Ошибка: ${err}`);
    }
  }, [dataProvider, notify, userId]);

  return (
    <div className="container -actions">
      <Box className="card">
        <h3 className="title">Временный пароль</h3>
        <div className="form-wrap">
          <Form
            onSubmit={onSubmit}
            initialValues={{
              password: '',
            }}
          >
            {props => (
              <form onSubmit={props.handleSubmit} className="form">
                <Loader open={loading} />
                <Field name="password">
                  {props => (
                    <TextField
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      className="field"
                      variant="outlined"
                      placeholder="Минимум 5 знаков"
                      inputProps={{ className: 'input' }}
                      type="password"
                    />
                  )}
                </Field>
                <Button type="submit" className="btn -set">
                  Установить
                </Button>
                <Button className="btn" onClick={onReset}>
                  Вернуть
                </Button>
              </form>
            )}
          </Form>
        </div>
      </Box>
      <Box className="card">
        <h3 className="title">Платежная карта</h3>
        <div className="text-field">
          <Button className="btn" onClick={detachPaymentCard}>
            Отвязать карту
          </Button>
        </div>
      </Box>
    </div>
  );
};
