import React from 'react';
import Card from '@material-ui/core/Card';
import { num2word } from '../../../utils/num2word';

const RentInsurancePaymentsInfo = ({ insurancePayments = [] }) => {
  return (
    <div className="info-wrapper">
      <div className="wrap">
        <h3 className="title -section">Данные страховых взносов:</h3>
        <div className="cards-wrapper">
          {insurancePayments.length ? (
            insurancePayments.map(insurancePayment => (
              <Card className="card" key={`insurancePayment-${insurancePayment.index}`}>
                <h4 className="title -subsection">
                  {insurancePayment.purpose === 'RENT_INITIAL'
                    ? 'Первичная аренда'
                    : `Продление аренды`}
                </h4>
                <div className="text-field">
                  <span className="label">Кол-во дней:</span>
                  <span className="text">
                    {insurancePayment.daysInterval}{' '}
                    {num2word(insurancePayment.daysInterval, ['день', 'дня', 'дней'])}
                  </span>
                </div>

                <div className="text-field">
                  <span className="label">Тип платежа:</span>
                  <span className="text">
                    {insurancePayment.isHigherPayment ? 'Повышенный' : 'Стандартный'}
                  </span>
                </div>

                {typeof insurancePayment.amountInsurancePayments === 'number' && (
                  <div className="text-field">
                    <span className="label">Сумма страховых взносов:</span>
                    <span className="text">{insurancePayment.amountInsurancePayments}</span>
                  </div>
                )}

                {typeof insurancePayment.amountCarIngosPayments === 'number' && (
                  <div className="text-field">
                    <span className="label">Сумма взносов в ИНГОССТРАХ:</span>
                    <span className="text">
                      {typeof insurancePayment.amountInsurancePayments !== 'number' ||
                      insurancePayment.amountInsurancePayments <= 0
                        ? 0
                        : insurancePayment.amountCarIngosPayments}
                    </span>
                  </div>
                )}

                {typeof insurancePayment.amountServiceMargin === 'number' && (
                  <div className="text-field">
                    <span className="label">Маржа сервиса:</span>
                    <span className="text">{insurancePayment.amountServiceMargin}</span>
                  </div>
                )}
              </Card>
            ))
          ) : (
            <p>Данные по выплатам не найдены</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default RentInsurancePaymentsInfo;
