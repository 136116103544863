import React, { useCallback, useState, useEffect } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import Card from '@material-ui/core/Card';

import { Loader } from '../../../common/Loader';
import { PhotoPreview } from '../../../common/PhotoPreview';
import '../cards-wrapper.scss';
import './styles.scss';

const compensationTypeMap = {
  ACCIDENT: 'ДТП, аварии, повреждения',
  WASHING: 'Мойка',
  FUEL: 'Заправка топлива',
  DELAY: 'Опоздание',
  OTHER: 'Другое',
};

export const RentCompensations = ({ record }) => {
  const { id: rentId } = record;
  const [compensations, setCompensations] = useState([]);
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const getCompensations = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await dataProvider.getCompensations({
        id: rentId,
      });
      setCompensations(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify(`Error: ${error}`);
    }
  }, [dataProvider, notify, rentId]);

  useEffect(() => {
    getCompensations();
  }, [getCompensations]);

  return (
    <div className="cards-wrapper rent-compensations">
      <Loader open={loading} />
      {compensations.map(compensation => {
        const { createdAt, type, description, photoKeys } = compensation;
        return (
          <Card className="card">
            <div className="text-field">
              <span className="label">Дата запроса:</span>
              <span className="text">{createdAt}</span>
            </div>
            <div className="text-field">
              <span className="label">Категория:</span>
              <span className="text">{compensationTypeMap[type]}</span>
            </div>
            <div className="text-field">
              <span className="label">Комментарий:</span>
              <span className="text">{description}</span>
            </div>
            <div className="text-field">
              <span className="label">Фото:</span>
              <div className="photos-wrap">
                {' '}
                {photoKeys.map(photo => (
                  <PhotoPreview photo={photo} />
                ))}
              </div>
            </div>
          </Card>
        );
      })}
    </div>
  );
};
