import React, { useState, useCallback, useEffect } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import { Field } from 'react-final-form';
import { renderer } from '../../renderer';
import { fieldsSchema } from './form-schema';

const useStyles = makeStyles(theme => ({
  outlined: {
    padding: '10.5px 14px',
  },
  margin: {
    margin: '8px 0 4px',
  },
}));

export const Moderation = ({ record, resource }) => {
  const [bestPrice, setBestPrice] = useState(null);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const classes = useStyles();

  const { id = '', bestPrice: carBestPrice } = record;

  const changeBestPrice = useCallback(
    ({ target: { value } }) => setBestPrice(value !== '' ? parseInt(value, 10) : null),
    [],
  );
  const submitBestPrice = useCallback(async () => {
    if (typeof bestPrice !== 'number' || bestPrice === 0) {
      notify('Введите корректное число');
      return;
    }

    if (bestPrice === carBestPrice) {
      notify('Введите цену отличную от существующей');
      return;
    }

    try {
      await dataProvider.setBestPrice(resource, { id, price: bestPrice });
      await dataProvider.getOne(resource, { id });
      notify('Best price was updated successfuly');
    } catch (error) {
      notify(`Best price update was failed with error: ${error}`);
    }
  }, [dataProvider, notify, resource, id, bestPrice, carBestPrice]);

  useEffect(() => {
    if (carBestPrice !== undefined) {
      setBestPrice(carBestPrice);
    }
  }, [carBestPrice]);

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      justify="space-between"
      alignItems="center"
      style={{ margin: '40px 0' }}
    >
      <Typography variant="h5" gutterBottom display="block" style={{ margin: '0 0 30px' }}>
        Модерация данных автомобиля
      </Typography>
      <Grid container direction="row" spacing={2} xs={12} item>
        <Grid
          container
          item
          xs={3}
          direction="column"
          wrap="nowrap"
          style={{ margin: '0 20px 0 0' }}
        >
          {fieldsSchema.map(field => (
            <Grid container item xs={12} justify="center" key={field.name}>
              <Field name={field.name}>{({ input }) => renderer(field, input, classes)}</Field>
            </Grid>
          ))}
          <Grid container item xs={12} justify="center">
            <TextField
              id="outlined-basic"
              label="Лучшая цена"
              variant="outlined"
              type="number"
              margin="dense"
              style={{ width: '90%', marginTop: 40 }}
              onChange={changeBestPrice}
              value={bestPrice}
            />
            <Button
              onClick={submitBestPrice}
              style={{
                marginTop: 15,
                background: '#3f51b5',
                color: '#fff',
                marginLeft: 'auto',
                marginRight: '15px',
              }}
            >
              Установить
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
