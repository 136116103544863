import React from 'react';
import { Field } from 'react-final-form';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

export const Refinement = () => {
  return (
    <Grid
      container
      spacing={1}
      direction="column"
      justify="space-between"
      alignItems="center"
      style={{ margin: '40px 0' }}
    >
      <Typography variant="h5" gutterBottom display="block" style={{ margin: '0 0 30px' }}>
        Причина уточнения
      </Typography>
      <Grid container item xs={6} justify="center" key="reason">
        <Field name="reason">
          {({ input: { value, onChange } }) => (
            <TextField
              label="Причина"
              key="reason"
              margin="dense"
              name="reason"
              multiline
              rows={3}
              onChange={({ target }) => {
                onChange(target.value);
              }}
              value={value}
              variant="outlined"
              style={{ width: '90%' }}
            />
          )}
        </Field>
      </Grid>
    </Grid>
  );
};
