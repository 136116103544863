import React, { useCallback } from 'react';
import { SaveButton, useDataProvider, useNotify } from 'react-admin';

export const RefinementButton = props => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { resource } = props;

  const handleSave = useCallback(
    async values => {
      try {
        await dataProvider.sendToRefinement(resource, { id: values.id, reason: values.reason });
        await dataProvider.getOne(resource, { id: values.id });

        notify('Car sent to refinement successfuly');
      } catch (error) {
        notify(`Car refinement was failed with error: ${error}`);
      }
    },
    [dataProvider, notify, resource],
  );

  // TODO: remove pristine prop from SaveButton because of hardcode - create custom button instead SaveButton
  return (
    <SaveButton {...props} onSave={handleSave} label="Отправить на доработку" pristine={false} />
  );
};
