import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import '../styles.scss';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ReportActions = ({ report, onRefresh, onRegenerate }) => {
  const [open, setDialogOption] = useState(false);

  const { checks = [], providerReportId } = report || {};
  const reliabilityReport = checks.find(check => check.type === 'RELIABILITY');

  const handleClose = () => {
    setDialogOption(false);
  };

  return (
    <div className="report-actions">
      <Dialog disablePortal open={open} TransitionComponent={Transition} className="dialog">
        <div
          className="reliability-report"
          dangerouslySetInnerHTML={{
            __html: reliabilityReport ? reliabilityReport.data.details : '',
          }}
        />
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          className="icon"
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
      <Box className="actions">
        {reliabilityReport && (
          <Button className="text-btn" onClick={() => setDialogOption(true)}>
            Отчет благонадежности
          </Button>
        )}
        <Button className="warning-btn" onClick={onRegenerate}>
          Генерация по текущим данным
        </Button>
        {providerReportId && (
          <Button className="warning-btn" onClick={onRefresh}>
            Обновить отчет
          </Button>
        )}
      </Box>
    </div>
  );
};
