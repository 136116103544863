import React from 'react';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';

import { TextField } from '../components/TextField';
import { CheckboxField } from '../components/CheckboxField';
import { CheckActions } from '../components/CheckActions';
import { ErrorMessage } from '../components/ErrorMessage';

const initialFormState = {
  hasCriticalFactors: false,
  hasCriminalFactors: false,
  hasAdministrativeFactores: false,
  hasSDAFactores: false,
};
const mapToInitialValues = data => {
  if (!data) {
    return initialFormState;
  }

  return {
    hasCriticalFactors: data.hasCriticalFactors,
    hasCriminalFactors: data.hasCriminalFactors,
    hasAdministrativeFactores: data.hasAdministrativeFactores,
    hasSDAFactores: data.hasSDAFactores,
  };
};

export const ProceedingsCheck = ({ onSubmit, check = {} }) => {
  const { verdict, data = {}, isError } = check;
  const { factors = [] } = data;
  const initialValues = mapToInitialValues(data);

  const isInfoShown = !!factors.length;

  return (
    <div className="form-wrap">
      <h3 className="title">Уголовные и Административные правонарушения</h3>
      {isError && <ErrorMessage />}
      <Form onSubmit={onSubmit(check)} initialValues={initialValues}>
        {({ handleSubmit, dirty }) => (
          <form onSubmit={handleSubmit} className="form">
            <TextField label="Статус проверки:" value={verdict} />
            <CheckboxField label="Найдены правонарушения?" name="hasCriticalFactors" />
            <CheckboxField label="Найдены уголовные правонарушения?" name="hasCriminalFactors" />
            <CheckboxField
              label="Найдены административные правонарушения?"
              name="hasAdministrativeFactores"
            />
            <CheckboxField label="Найдены нарушения ПДД?" name="hasSDAFactores" />
            <CheckActions isSaveDisabled={!dirty} isInfoShown={isInfoShown}>
              <h4 className="title">Факторы</h4>
              {factors.map(factor => (
                <>
                  <Box>
                    <TextField label="Код:" value={factor?.code} />
                    <TextField label="Комментарий:" value={factor?.title} />
                  </Box>
                </>
              ))}
            </CheckActions>
          </form>
        )}
      </Form>
    </div>
  );
};
