import { differenceInYears, format, parse } from 'date-fns';
import { num2word } from './num2word';

export const getBirthday = initialBirthDate => {
  const dateNow = new Date();
  let birthDate = initialBirthDate;

  if (typeof birthDate === 'string') {
    birthDate = parse(birthDate, 'd.M.yyyy', new Date());
  }

  const age = differenceInYears(dateNow, birthDate);
  const ageDeclination = num2word(age, ['год', 'года', 'лет']);

  return {
    age,
    ageDeclination,
    birthDate,
    toString() {
      return `${age} ${ageDeclination}`;
    },
  };
};
