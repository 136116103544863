import React, { useState, useCallback } from 'react';
import { useNotify } from 'react-admin';
// eslint-disable-next-line
import qs from 'qs';
import { utils, writeFileXLSX } from 'xlsx';
import { Form, Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { SERVICE_URL } from '../../core/constants';
import { makeCall } from '../../services/api-service';
import { MaskedInput } from '../../common/MaskedInput';
import { scoringFailedReport } from './mappers';

export const ScoringStatsForm = ({ setLoading }) => {
  const [{ failedChecksCount, successChecksCount, failedChecks }, setData] = useState({
    failedChecksCount: '',
    successChecksCount: '',
    failedChecks: [],
  });
  const notify = useNotify();

  const onSubmit = useCallback(
    async ({ startDate, endDate }) => {
      try {
        setLoading(true);

        const query = {
          startDate,
          endDate,
        };
        const { data } = await makeCall(`${SERVICE_URL}/stats/scoring?${qs.stringify(query)}`, {
          method: 'GET',
        });
        setData(data);
        return setLoading(false);
      } catch (error) {
        setLoading(false);
        const err = error?.response?.data?.message || error;
        return notify(`Ошибка: ${err}`);
      }
    },
    [notify, setLoading],
  );

  const onDownload = useCallback(() => {
    const formattedData = failedChecks.map(record => {
      return Object.keys(record).reduce((acc, key) => {
        const newAcc = { ...acc, [scoringFailedReport.columns[key]]: record[key] };
        return newAcc;
      }, {});
    });
    const { tableName } = scoringFailedReport;

    const ws = utils.json_to_sheet(formattedData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, tableName);
    return writeFileXLSX(wb, `${tableName}.xlsx`);
  }, [failedChecks]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        startDate: null,
        endDate: null,
      }}
    >
      {({ handleSubmit, values: { startDate, endDate } }) => (
        <form onSubmit={handleSubmit}>
          <Box className="scoring-stats">
            <h3 className="title -section">Статистика скоринга</h3>
            <Box className="date-selection">
              <Field name="startDate">
                {props => (
                  <MaskedInput
                    name={props.input.name}
                    value={props.input.value}
                    onChange={props.input.onChange}
                    className="field -start"
                    variant="outlined"
                    placeholder="Дата от ГГГГ-ММ-ДД"
                    inputProps={{ className: 'input' }}
                    type="text"
                    mask={[/\d/, /\d/, /\d/, /\d/, /-/, /\d/, /\d/, /-/, /\d/, /\d/]}
                  />
                )}
              </Field>
              <Field name="endDate">
                {props => (
                  <MaskedInput
                    name={props.input.name}
                    value={props.input.value}
                    onChange={props.input.onChange}
                    className="field"
                    variant="outlined"
                    placeholder="Дата до ГГГГ-ММ-ДД"
                    inputProps={{ className: 'input' }}
                    type="text"
                    mask={[/\d/, /\d/, /\d/, /\d/, /-/, /\d/, /\d/, /-/, /\d/, /\d/]}
                  />
                )}
              </Field>
              <Box className="btn-wrap">
                <Button className="btn" type="submit" disabled={!startDate || !endDate}>
                  Загрузить
                </Button>
              </Box>
            </Box>
            {typeof failedChecksCount === 'number' && typeof successChecksCount === 'number' && (
              <>
                <div className="wrap">
                  <div className="text-field">
                    <span className="label">Успешных отчетов:</span>
                    <span className="text">{successChecksCount}</span>
                  </div>
                  <div className="text-field">
                    <span className="label">Отчетов с ошибками:</span>
                    <span className="text">{failedChecksCount}</span>
                  </div>
                </div>
                <Box className="download-box">
                  <Button className="btn" onClick={onDownload}>
                    Скачать отчеты с ошибками
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </form>
      )}
    </Form>
  );
};
