import React from 'react';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';
import { serverTimeZone } from '../../../utils/date';

const parseBoolean = item => (item ? 'да' : 'нет');

export const RentInfo = ({ rent = {}, insurancePayments = {} }) => {
  const {
    id = '',
    startDate,
    endDate,
    carTransferLocation = {},
    status,
    services,
    features = [],
    guestsGreetingMessage = '',
    insurance,
  } = rent;
  const { data: locationData } = carTransferLocation || {};
  const { emptyTankReturn, unlimitedMilage, afterRentWashing } = services || {};

  const $startDate = new Date(startDate);
  const $endDate = new Date(endDate);

  return (
    <div className="info-wrapper">
      <div className="wrap">
        <h3 className="title -section">Данные аренды</h3>
        <div className="text-field">
          <span className="label">ID:</span>
          <span className="text">{id}</span>
        </div>
        <div className="text-field">
          <span className="label">Статус:</span>
          <span className="text">{status}</span>
        </div>
        <div className="text-field">
          <span className="label">Есть страховка:</span>
          <span className="text">{insurance ? 'Да' : 'Нет'}</span>
        </div>
        <div className="text-field">
          <span className="label">Начало аренды:</span>
          <span className="text">
            {startDate
              ? `${formatInTimeZone($startDate, serverTimeZone, 'd MMMM yyyy, HH:mm OOOO', {
                  locale: ru,
                })}`
              : ''}
          </span>
        </div>
        <div className="text-field">
          <span className="label">Конец аренды:</span>
          <span className="text">
            {endDate
              ? `${formatInTimeZone($endDate, serverTimeZone, 'd MMMM yyyy, HH:mm OOOO', {
                  locale: ru,
                })}`
              : ''}
          </span>
        </div>
      </div>
      <div className="wrap">
        <h3 className="title -section">Приветственное сообщение от водителя</h3>
        <div className="text-field">
          <span className="label">Сообщение:</span>
          <span className="text">{guestsGreetingMessage}</span>
        </div>
      </div>
      {locationData ? (
        <div className="wrap">
          <h3 className="title -section">Геолокация</h3>
          <div className="text-field">
            <span className="label">Адрес:</span>
            <span className="text">{locationData.address}</span>
          </div>
          <div className="text-field">
            <span className="label">Координаты машины:</span>
            <span className="text">{`${locationData.latitude}, ${locationData.longitude}`}</span>
          </div>
        </div>
      ) : (
        <div className="wrap">
          <h3 className="title -section">Геолокация</h3>
          <div className="text-field">
            <span className="label">Адрес:</span>
            <span className="text">местоположение машины</span>
          </div>
        </div>
      )}
      <div className="wrap">
        <h3 className="title -section">Услуги и устройства</h3>
        <div className="box">
          <h4 className="title -subsection">Услуги:</h4>
          <div className="text-field">
            <span className="label">Возврат с пустым баком:</span>
            <span className="text">{parseBoolean(emptyTankReturn)}</span>
          </div>
          <div className="text-field">
            <span className="label">Неограниченный пробег:</span>
            <span className="text">{parseBoolean(unlimitedMilage)}</span>
          </div>
          <div className="text-field">
            <span className="label">Мойка после аренды:</span>
            <span className="text">{parseBoolean(afterRentWashing)}</span>
          </div>
        </div>
        <div className="box">
          <h4 className="title -subsection -separate">Устройства:</h4>
          <div className="text-field -vertical">
            {features.map(feature => (
              <span className="text">{feature}</span>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
