import Talk from 'talkjs';
import { Link } from 'react-router-dom';
import Card from '@material-ui/core/Card';
import Avatar from '@material-ui/core/Avatar';
import React, { Component, useEffect, useRef, useState } from 'react';
import { Title, useAuthenticated } from 'react-admin';
import CardContent from '@material-ui/core/CardContent';

import './styles.scss';
import { stringify } from 'querystring';
import { SERVICE_URL } from '../core/constants';
import { makeCall } from '../services/api-service';

const isDevelopment =
  process.env.NODE_ENV === 'development' ||
  window.location.origin.includes('admin.iew8iesh.k8s.getarent.ru');

const appId = isDevelopment ? 'tdcdkAzZ' : 'r6QYSHxp';

export default function Chat() {
  const [selectedConversation, setSelectedConversation] = useState({});
  const [usersList, setUsersList] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [clickedUserId, setClickedUserId] = useState('');
  const [userToInit, setUserToInit] = useState(null);
  const [usersWithoutChat, setUsersWithoutChat] = useState([]);

  const timerRef = useRef(null);

  const user = selectedConversation?.other;

  useEffect(() => {
    clearTimeout(timerRef.current);

    timerRef.current = setTimeout(async () => {
      try {
        if (searchValue.length < 3) return;

        const query = {
          order: JSON.stringify({ lastName: 'ASC' }),
          range: JSON.stringify([0, 9]),
          where: JSON.stringify(
            ['email' /* , 'firstName', 'lastName', 'midName' */].reduce(
              (acc, key) => ({
                ...acc,
                [key]: searchValue,
              }),
              {},
            ),
          ),
        };

        const url = `${SERVICE_URL}/user?${stringify(query)}`;
        const res = await makeCall(url, { method: 'GET' });
        if (!Array.isArray(res?.data)) {
          throw new Error('User searh error');
        }

        setUsersWithoutChat(
          res.data.length
            ? res.data
                .filter(user => !usersList.some(u => u.id === user.id))
                .map(user => ({
                  id: user.id,
                  email: [user.email],
                  toInit: true,
                  name: [user.lastName, user.firstName].join(' '),
                  photoUrl: user.avatar,
                }))
            : [],
        );
      } catch (err) {
        console.error(err);
      }
    }, 200);

    return () => {
      clearTimeout(timerRef.current);
    };
  }, [searchValue, usersList]);

  useAuthenticated({ path: '/chat' });

  return (
    <Card>
      <Title title="Чат" />
      <CardContent>
        <div className="row">
          <div className="chat">
            <ChatComponent
              clickedUserId={clickedUserId}
              userToInit={userToInit}
              setUserToInit={setUserToInit}
              setSelectedConversation={setSelectedConversation}
              setUsersList={setUsersList}
              setSearchValue={setSearchValue}
              setClickedUserId={setClickedUserId}
            />
          </div>
          <div className="chat-link">
            <Link
              className="link-a"
              target="_blank"
              to={{
                pathname: `/user/${user?.id}`,
              }}
            >
              <span className="user-name">Перейти в профиль</span>
            </Link>
          </div>
          {usersList.length !== 0 && (
            <div className="search">
              <div className="search-header">
                <input
                  type="text"
                  name="search"
                  placeholder="Поиск"
                  value={searchValue}
                  className="search-input"
                  onChange={e => setSearchValue(e.target.value)}
                />
              </div>
              <div className="search-list">
                {[...usersList, ...usersWithoutChat].map(
                  user =>
                    !!searchValue &&
                    !!user.name &&
                    !!user.email &&
                    !!user.email[0] &&
                    (user.name.toLowerCase().includes(searchValue.toLowerCase()) ||
                      user.email[0].toLowerCase().includes(searchValue.toLowerCase())) && (
                      <div
                        key={user.id}
                        className="search-item"
                        onClick={() =>
                          user.toInit ? setUserToInit(user) : setClickedUserId(user.id)
                        }
                      >
                        <Avatar className="search-avatar" alt={user.name} src={user.photoUrl} />
                        <div>
                          <div className="search-name">{user.name}</div>
                          <div className="search-email">{user.email[0]}</div>
                          {user.toInit && (
                            <span
                              style={{ color: '#037df9', fontSize: 12 }}
                              className="search-email"
                            >
                              Создать чат
                            </span>
                          )}
                        </div>
                      </div>
                    ),
                )}
              </div>
            </div>
          )}
        </div>
      </CardContent>
    </Card>
  );
}

const SUPPORT_USER = {
  id: 'support@getarent.ru',
  name: 'Поддержка Getarent',
  email: 'support@getarent.ru',
  phone: '+78003507780',
  photoUrl: 'https://fonts.gstatic.com/s/i/materialiconsoutlined/support_agent/v12/24px.svg',
  welcomeMessage: 'Приветствую! Чем могу быть полезен?',
};

class ChatComponent extends Component {
  constructor(props) {
    super(props);

    this.inbox = undefined;

    this.state = {
      selectedConversation: {},
      conversationsList: [],
      usersList: [],
    };
  }

  componentDidMount() {
    Talk.ready
      .then(async () => {
        // https://talkjs.com/docs/Reference/Concepts/Users/
        // https://talkjs.com/docs/Reference/JavaScript_Chat_SDK/User/
        const me = new Talk.User(SUPPORT_USER);

        if (!window.talkSession) {
          window.talkSession = new Talk.Session({
            appId,
            me,
          });
        }

        this.inbox = window.talkSession.createInbox();
        this.inbox.mount(this.container);

        this.inbox.onConversationSelected(e => {
          this.props.setSearchValue('');
          this.props.setClickedUserId('');
          this.setState({ selectedConversation: e });
          this.state.selectedConversation.conversation.id !== e.conversation.id &&
            this.inbox.select(e.conversation.id);
        });

        const fetchUsersList = async startingAfter => {
          let usersList;

          if (startingAfter) {
            usersList = await fetch(
              `https://api.talkjs.com/v1/${appId}/users?limit=30${startingAfter &&
                `&startingAfter=${startingAfter}`}`,
              {
                method: 'GET',
                headers: {
                  Authorization: `Bearer ${
                    isDevelopment
                      ? 'sk_test_nSU89BZPOMwAUVjgQDYMukvHHZFFYcGn'
                      : 'sk_live_jJnUlHR977D9Mn7UPx4axeKNFulKi0Dc'
                  }`,
                },
              },
            );
          } else {
            usersList = await fetch(`https://api.talkjs.com/v1/${appId}/users?limit=30`, {
              method: 'GET',
              headers: {
                Authorization: `Bearer ${
                  isDevelopment
                    ? 'sk_test_nSU89BZPOMwAUVjgQDYMukvHHZFFYcGn'
                    : 'sk_live_jJnUlHR977D9Mn7UPx4axeKNFulKi0Dc'
                }`,
              },
            });
          }

          const data = await usersList.json();
          this.setState(prevState => ({ usersList: [...prevState.usersList, ...data.data] }));

          const lastItemId = data.data[data.data.length - 1]?.id;

          if (lastItemId) {
            fetchUsersList(lastItemId);
          } else {
            const { usersList } = this.state;
            const { conversationsList } = this.state;

            if (usersList.length !== 0 && conversationsList.length !== 0) {
              const filteredUsersList = usersList.filter(
                user =>
                  user.id !== 'support@getarent.ru' &&
                  conversationsList.find(conversation => conversation.participants[user.id]),
              );
              this.props.setUsersList(filteredUsersList);
            }
          }
        };

        fetchUsersList();

        const fetchConversationsList = async startingAfter => {
          let conversationsList;

          if (startingAfter) {
            conversationsList = await fetch(
              `https://api.talkjs.com/v1/${appId}/conversations?limit=30${startingAfter &&
                `&startingAfter=${startingAfter}`}`,
              {
                method: 'GET',
                headers: {
                  Authorization: `Bearer ${
                    isDevelopment
                      ? 'sk_test_nSU89BZPOMwAUVjgQDYMukvHHZFFYcGn'
                      : 'sk_live_jJnUlHR977D9Mn7UPx4axeKNFulKi0Dc'
                  }`,
                },
              },
            );
          } else {
            conversationsList = await fetch(
              `https://api.talkjs.com/v1/${appId}/conversations?limit=30`,
              {
                method: 'GET',
                headers: {
                  Authorization: `Bearer ${
                    isDevelopment
                      ? 'sk_test_nSU89BZPOMwAUVjgQDYMukvHHZFFYcGn'
                      : 'sk_live_jJnUlHR977D9Mn7UPx4axeKNFulKi0Dc'
                  }`,
                },
              },
            );
          }

          const conversationsListJson = await conversationsList.json();
          const conversationsData = conversationsListJson.data;

          const filteredConversationsData = conversationsData?.filter(
            conversation =>
              !!conversation.participants['support@getarent.ru'] && !!conversation.lastMessage,
          );

          this.setState(prevState => ({
            conversationsList: [...prevState.conversationsList, ...filteredConversationsData],
          }));

          const lastItemId = conversationsData[conversationsData.length - 1]?.id;

          if (lastItemId) {
            fetchConversationsList(lastItemId);
          }
        };

        fetchConversationsList();
      })
      .catch(e => console.error(e));
  }

  componentDidUpdate() {
    !!this.state.selectedConversation &&
      this.props.setSelectedConversation(this.state.selectedConversation);

    if (this.props.clickedUserId) {
      this.selectChat(this.props.clickedUserId);
    }

    if (this.props.userToInit) {
      this.initChat(this.props.userToInit);
    }
  }

  componentWillUnmount() {
    if (this.inbox) {
      this.inbox.destroy();
    }
  }

  findChatByUserId(userId) {
    const conversation = this.state.conversationsList.find(
      conversation => !!conversation.participants[userId],
    );

    return conversation;
  }

  selectChat(userId) {
    const conversationId = this.findChatByUserId(userId)?.id;
    conversationId && this.inbox.select(conversationId);
  }

  initChat(user) {
    Talk.ready.then(() => {
      const exist = this.findChatByUserId(user.id);
      if (exist) {
        this.selectChat(user.id);
        return;
      }

      const me = new Talk.User(SUPPORT_USER);

      const session = new Talk.Session({
        me,
        appId,
      });

      const customer = new Talk.User({
        id: user.id,
        name: user.name,
        email: user.email[0],
        photoUrl: user.photoUrl,
        role: 'default',
      });

      const conversation = session.getOrCreateConversation(Talk.oneOnOneId(customer, me));
      conversation.setParticipant(me);
      conversation.setParticipant(customer);

      this.inbox.select(conversation);
      this.props.setUserToInit(null);

      // const chatbox = session.createChatbox();
      // chatbox.select(conversation);
      // chatbox.mount(this.container);
    });
  }

  render() {
    return (
      <span>
        <div
          style={{ height: '700px' }}
          ref={el => {
            this.container = el;
          }}
        >
          Loading...
        </div>
      </span>
    );
  }
}
