import React, { useCallback } from 'react';
import { SaveButton, useDataProvider, useNotify } from 'react-admin';

export const ScoringRejectButton = props => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { resource } = props;

  const handleSave = useCallback(
    async values => {
      try {
        await dataProvider.scoringReject(resource, { id: values.id });
        await dataProvider.getOne(resource, { id: values.id });

        notify('User scoring rejected successfuly');
      } catch (error) {
        notify(`User scoring reject was failed with error: ${error}`);
      }
    },
    [dataProvider, notify, resource],
  );

  // TODO: remove pristine prop because of hardcode - create custom button instead SaveButton
  return <SaveButton {...props} onSave={handleSave} label="Отклонить скоринг" pristine={false} />;
};
