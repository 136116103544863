export const fieldsSchema = [
  {
    name: 'brand',
    label: 'Бренд',
    key: 'brand',
    type: 'text',
  },
  {
    name: 'model',
    label: 'Модель',
    key: 'model',
    type: 'text',
  },
  {
    name: 'bodyType',
    label: 'Тип кузова',
    key: 'bodyType',
    type: 'select',
    options: [
      'SEDAN',
      'HATCHBACK',
      'CABRIOLET',
      'COUPE',
      'MINIVAN',
      'PICKUP',
      'CROSSOVER',
      'SUV',
      'LIFTBACK',
      'STATIONWAGON',
      'MINIBUS',
      'LONGVAN',
    ],
  },
  {
    name: 'productionYear',
    label: 'Год выпуска',
    key: 'productionYear',
    type: 'text',
  },
  {
    name: 'engineDisplacement',
    label: 'Объем двигателя',
    key: 'engineDisplacement',
    type: 'select',
    options: [
      '0.2',
      '0.3',
      '0.4',
      '0.5',
      '0.6',
      '0.7',
      '0.8',
      '0.9',
      '1.0',
      '1.1',
      '1.2',
      '1.3',
      '1.4',
      '1.5',
      '1.6',
      '1.7',
      '1.8',
      '1.9',
      '2.0',
      '2.1',
      '2.2',
      '2.3',
      '2.4',
      '2.5',
      '2.6',
      '2.7',
      '2.8',
      '2.9',
      '3.0',
      '3.5',
      '4.0',
      '4.5',
      '5.0',
      '5.5',
      '6.0',
      '7.0',
      '8.0',
      '9.0',
      '10.0',
    ],
  },
  {
    name: 'enginePower',
    label: 'Мощность двигателя в л.с',
    key: 'enginePower',
    type: 'text',
  },
  {
    name: 'transmissionLayout',
    label: 'Привод',
    key: 'transmissionLayout',
    type: 'select',
    options: ['FRONT_WHEEL', 'REAR_WHEEL', 'FOUR_WHEEL'],
  },
  {
    name: 'transmissionType',
    label: 'Коробка',
    key: 'transmissionType',
    type: 'select',
    options: ['MANUAL', 'AUTOMATIC'],
  },
  {
    name: 'engineType',
    label: 'Тип двигателя',
    key: 'engineType',
    type: 'select',
    options: [
      {
        value: 'GASOLINE',
        label: 'Бензин',
      },
      {
        value: 'DIESEL',
        label: 'Дизель',
      },
      {
        value: 'ELECTRO',
        label: 'Электричество',
      },
    ],
  },
  {
    name: 'price',
    label: 'Рыночная цена',
    key: 'carPrice',
    type: 'number',
  },
  {
    name: 'registrationNumber',
    label: 'Рег. номер',
    key: 'carRegistrationNumber',
    type: 'text',
  },
  {
    name: 'location.address',
    label: 'Адрес',
    key: 'carAddress',
    type: 'text',
  },
  {
    name: 'location.city.index',
    label: 'Индекс города',
    key: 'carCityIndex',
    type: 'text',
  },
  {
    name: 'location.latitude',
    label: 'Местоположение - latitude',
    key: 'carLatitude',
    type: 'float',
  },
  {
    name: 'location.longitude',
    label: 'Местоположение - longitude',
    key: 'carLongitude',
    type: 'float',
  },
  {
    name: 'vin',
    label: 'Вин номер',
    key: 'vin',
    type: 'text',
  },
];
