import React from 'react';
import { Form } from 'react-final-form';

import { TextAreaField } from '../components/TextAreaField';
import { CheckboxField } from '../components/CheckboxField';
import { TextField } from '../components/TextField';
import { CheckActions } from '../components/CheckActions';
import { ErrorMessage } from '../components/ErrorMessage';

const initialFormState = {
  isBannedByBanks: false,
  comment: '',
};
const mapToInitialValues = data => {
  if (!data) {
    return initialFormState;
  }

  return {
    isBannedByBanks: data.isBannedByBanks,
    comment: data.comment || '',
  };
};

export const BanksBlackListCheck = ({ check = {}, onSubmit }) => {
  const { verdict, data = {}, comment, isError } = check;
  const initialValues = mapToInitialValues({ ...data, comment });

  return (
    <div className="form-wrap">
      <h3 className="title">Черные списки банков</h3>
      {isError && <ErrorMessage />}
      <Form onSubmit={onSubmit(check)} initialValues={initialValues}>
        {({ handleSubmit, dirty }) => (
          <form onSubmit={handleSubmit} className="form">
            <TextField label="Статус проверки:" value={verdict} />
            <CheckboxField label="Находится в черном списке банков?" name="isBannedByBanks" />
            <TextAreaField
              title="Комментарий поддержки"
              name="comment"
              placeholder="Необязательно к заполнению"
            />
            <CheckActions isSaveDisabled={!dirty} isInfoShown={false} />
          </form>
        )}
      </Form>
    </div>
  );
};
