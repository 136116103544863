import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { PhotoPreview } from '../../../../common/PhotoPreview';
import './styles.scss';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const VehiclePassport = ({ record, ...rest }) => {
  const { id } = record;
  const { resource } = rest;
  const [vehiclePass, updateLocalVehiclePass] = useState([]);
  const [dialogOption, setDialogOption] = useState({ open: false, url: '' });
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleClose = () => {
    setDialogOption({ open: false, url: '' });
  };

  useEffect(() => {
    const fetchRegCert = async () => {
      try {
        const { data } = await dataProvider.fetchVehiclePassport(resource, { id });
        updateLocalVehiclePass(data.vehiclePassport);
        return data;
      } catch (error) {
        notify(`During vehicle passport fetching following error was occured: ${error}`);
        return error;
      }
    };
    fetchRegCert();
  }, [dataProvider, notify, resource, id]);

  if (!vehiclePass) {
    return null;
  }
  const {
    id: vehiclePassId = '',
    type = '',
    series = '',
    number = '',
    frontSidePhotoUrl = '',
    reverseSidePhotoUrl = '',
  } = vehiclePass;

  return (
    <div className="documents-tab">
      <Dialog
        disablePortal
        fullScreen
        open={dialogOption.open}
        TransitionComponent={Transition}
        className="photo-dialog"
      >
        <CardMedia image={dialogOption.url} className="image" />
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          className="icon"
        >
          <CloseIcon />
        </IconButton>
      </Dialog>{' '}
      <div className="wrap">
        <h3 className="title -section">ПТС</h3>
        <div className="text-field">
          <span className="label">ID Документа:</span>
          <span className="text">{vehiclePassId}</span>
        </div>
        <div className="text-field">
          <span className="label">Тип:</span>
          <span className="text">{type === 'ELECTRONIC' ? 'Электронный' : 'Старый'}</span>
        </div>
        <div className="text-field">
          <span className="label">Серия:</span>
          <span className="text">{series}</span>
        </div>
        <div className="text-field">
          <span className="label">Номер:</span>
          <span className="text">{number}</span>
        </div>
      </div>
      {type === 'MANUAL' && (
        <div className="photos-wrap">
          {frontSidePhotoUrl && <PhotoPreview photo={frontSidePhotoUrl} />}
          {reverseSidePhotoUrl && <PhotoPreview photo={reverseSidePhotoUrl} />}
        </div>
      )}
    </div>
  );
};
