import React, { useState, useCallback } from 'react';
import { Form, Field } from 'react-final-form';
import { useDataProvider, useNotify } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { parseISO } from 'date-fns';

import { Loader } from '../../../common/Loader';
import { parseDate } from '../../../utils/date';

export const ChangeDatesForm = ({ resource, rentId, disabled, startDate, endDate }) => {
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const onSubmit = useCallback(
    async ({ startDate, endDate }) => {
      setLoading(true);
      try {
        await dataProvider.changeDates({
          id: rentId,
          data: {
            startDate: parseISO(startDate).toISOString(),
            endDate: parseISO(endDate).toISOString(),
          },
        });
        await dataProvider.getOne(resource, { id: rentId });

        setLoading(false);
        notify('Даты аренды успешно изменены');
      } catch (error) {
        setLoading(false);
        notify(`Error: ${error}`);
      }
    },
    [dataProvider, notify, rentId],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        startDate: parseDate(startDate),
        endDate: parseDate(endDate),
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit} className="form">
          <Loader open={loading} />
          <Field name="startDate">
            {props => (
              <div className="text-field">
                <span className="label">Начало:</span>
                <TextField
                  id="datetime-local"
                  type="datetime-local"
                  name={props.input.name}
                  value={props.input.value}
                  onChange={props.input.onChange}
                  className="date-field"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            )}
          </Field>
          <Field name="endDate">
            {props => (
              <div className="text-field">
                <span className="label">Конец:</span>
                <TextField
                  id="datetime-local"
                  type="datetime-local"
                  name={props.input.name}
                  value={props.input.value}
                  onChange={props.input.onChange}
                  className="date-field"
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </div>
            )}
          </Field>
          <Button type="submit" className="btn" disabled={disabled}>
            Изменить даты
          </Button>
        </form>
      )}
    </Form>
  );
};
