import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import { SaveButton, useDataProvider, useNotify } from 'react-admin';
import { roles } from '../../../../core/constants';

export const SetRoleButton = props => {
  const {
    resource,
    record: { role: currentRole },
  } = props;
  const currentRoleIndex = roles.indexOf(currentRole);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(currentRoleIndex);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleSave = useCallback(
    async values => {
      try {
        await dataProvider.roleUpdate(resource, { id: values.id, role: roles[selectedIndex] });
        await dataProvider.getOne(resource, { id: values.id });

        notify('User role was updated successfuly');
      } catch (error) {
        notify(`User role update was failed with error: ${error}`);
      }
    },
    [dataProvider, notify, resource, selectedIndex],
  );

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // TODO: remove pristine prop from SaveButton because of hardcode - create custom button instead SaveButton
  return (
    <Grid style={{ marginRight: '10px' }}>
      <Grid item>
        <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
          <SaveButton
            {...props}
            onSave={handleSave}
            label={roles[selectedIndex]}
            pristine={false}
          />
          <Button
            color="primary"
            size="small"
            aria-controls={open ? 'split-button-menu' : undefined}
            aria-expanded={open ? 'true' : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          placement="top"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {roles.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={event => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Grid>
    </Grid>
  );
};
