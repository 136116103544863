import { SERVICE_URL } from '../../core/constants';
import { modifyCarResponse } from './utils/modifyResponse';
import { makeCall } from '../../services/api-service';

export const carDataProvider = {
  getHistory: async id => {
    const url = `${SERVICE_URL}/car/${id}/history`;

    const { data } = await makeCall(url, { method: 'GET' });
    return {
      data,
    };
  },
  updateCar: async (resource, params) => {
    const requestData = modifyCarResponse(params.data);
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}`, {
      method: 'PATCH',
      data: { ...requestData },
    });
    return { data };
  },
  publishCar: async (resource, params) => {
    const requestData = modifyCarResponse(params.data);
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/approve-and-publish`, {
      method: 'PATCH',
      data: requestData,
    });
    return { data };
  },
  startModeration: async (resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/start-moderation`, {
      method: 'PATCH',
      data: {},
    });
    return { data };
  },
  rejectCar: async (resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/reject-moderation`, {
      method: 'PATCH',
      data: {},
    });
    return { data };
  },
  sendToRefinement: async (resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/refinement`, {
      method: 'PATCH',
      data: {
        reason: params.reason,
      },
    });
    return { data };
  },
  banCar: async (resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/ban`, {
      method: 'PATCH',
      data: {},
    });
    return { data };
  },
  unbanCar: async (resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/unban`, {
      method: 'PATCH',
      data: {},
    });
    return { data };
  },
  listCarPhotos: async (resource, params) => {
    const { data } = await makeCall(
      `${SERVICE_URL}/${resource}/${params.id}/photo/list?variants=medium`,
      {
        method: 'GET',
      },
    );
    return { data };
  },
  addCarPhoto: async (resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/photo`, {
      method: 'POST',
      data: params.photo,
    });
    return { data };
  },
  deleteCarPhoto: async (resource, params) => {
    const { data } = await makeCall(
      `${SERVICE_URL}/${resource}/${params.id}/photo/${params.photoId}`,
      {
        method: 'DELETE',
      },
    );
    return { data };
  },
  setPrimaryCarPhoto: async (resource, params) => {
    const { data } = await makeCall(
      `${SERVICE_URL}/${resource}/${params.id}/photo/${params.photoId}/set-primary`,
      {
        method: 'PATCH',
        data: {},
      },
    );
    return { data };
  },
  fetchOwnerPassport: async (resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/docs/owner-passport`, {
      method: 'GET',
    });
    return { data };
  },
  fetchRegistrationCertificate: async (resource, params) => {
    const { data } = await makeCall(
      `${SERVICE_URL}/${resource}/${params.id}/docs/registration-certificate`,
      {
        method: 'GET',
      },
    );
    return { data };
  },
  fetchVehiclePassport: async (resource, params) => {
    const { data } = await makeCall(
      `${SERVICE_URL}/${resource}/${params.id}/docs/vehicle-passport`,
      {
        method: 'GET',
      },
    );
    return { data };
  },
  setBestPrice: async (resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/set-best-price`, {
      method: 'POST',
      data: { price: params.price },
    });
    return { data };
  },
  toggleLabel: async (resource, params) => {
    const method = params.isSetLabel ? 'set-label' : 'remove-label';

    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/${method}`, {
      method: 'POST',
      data: { label: params.label },
    });
    return { data };
  },
  getInsurance: async (resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/insurance/${params.carId}`, {
      method: 'GET',
    });
    return { data };
  },
  updateInsurance: async (resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/insurance/${params.carId}`, {
      method: 'PATCH',
      data: params.data,
    });
    return { data };
  },
  updateCascoFeeTable: async data => {
    const { data: response } = await makeCall(`${SERVICE_URL}/car/casco-fee/table`, {
      method: 'PATCH',
      data,
    });
    return { data: response };
  },
  updateVehiclePricesTable: async data => {
    const { data: response } = await makeCall(`${SERVICE_URL}/car/vehicle-pricing/table`, {
      method: 'PATCH',
      data,
    });
    return { data: response };
  },
};
