import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Route } from 'react-router-dom';
import { dataProvider } from './data-provider';
import { authProvider } from './auth-provider';
import { UserModule } from './modules/User';
import { CarModule } from './modules/Car';
import { RentModule } from './modules/Rent';
import { RentExpiredModule } from './modules/Rent/RentExpired';
import { PromocodeModule } from './modules/Promocode';
import { StatsPage } from './modules/Stats';
import { CutomLayout } from './common/CutomLayout';
import Chat from './custom/Chat';
import { SettingsPage } from './modules/Settings';

const App = () => (
  <Admin
    customRoutes={[
      <Route exact path="/settings" component={SettingsPage} />,
      <Route exact path="/stats" component={StatsPage} />,
      <Route path="/chat" component={Chat} />,
    ]}
    dataProvider={dataProvider}
    authProvider={authProvider}
    layout={CutomLayout}
  >
    <Resource name="user" options={{ label: 'Пользователи' }} {...UserModule} />
    <Resource name="car" options={{ label: 'Машины' }} {...CarModule} />
    <Resource name="rent" options={{ label: 'Аренды' }} {...RentModule} />
    <Resource name="rent-expired" options={{ label: 'Истекшие аренды' }} {...RentExpiredModule} />
    <Resource name="promocode" options={{ label: 'Промокоды' }} {...PromocodeModule} />
  </Admin>
);

export default App;
