import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Field } from 'react-final-form';

import { PhotoPreview } from '../../../common/PhotoPreview';
import './styles.scss';

export const DriversLicenseTab = ({ record: { driversLicense = [] } }) => {
  const informationFields = [
    {
      name: 'serialNumber',
      label: 'Серия и номер',
    },
    {
      name: 'dateOfIssue',
      label: 'Дата выдачи',
    },
    {
      name: 'firstLicenseIssueYear',
      label: 'Год выдачи первых прав',
    },
  ];

  return driversLicense.map((license = {}, licenseIndex) => {
    const identity = {
      uuid: license.uuid,
      createdAt: license.createdAt,
      updatedAt: license.updatedAt,
    };
    const photos = [license.frontSidePhoto, license.reverseSidePhoto];
    return (
      <Grid
        container
        spacing={1}
        className="document-wrapper"
        wrap="wrap"
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        <Grid container alignItems="center" direction="column" xs={4}>
          <Typography variant="h6" gutterBottom>
            Identity
          </Typography>
          <Grid container alignItems="flex-start" direction="column" xs={12}>
            {Object.keys(identity).map(key => {
              const value =
                typeof identity[key] === 'boolean' ? String(identity[key]) : identity[key];
              return (
                <Grid item>
                  <Typography
                    variant="subtitle2"
                    gutterBottom
                    display="inline"
                    style={{ marginRight: '10px' }}
                  >
                    {key}:
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom display="inline">
                    {value}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <Grid container xs={8} justify="center" alignItems="center">
          <Typography variant="h6" gutterBottom display="block">
            Информация
          </Typography>
          <Grid container xs={12}>
            {informationFields.map(field => {
              const formField = `driversLicense[${licenseIndex}].${field.name}`;
              return (
                <Grid container xs={6} justify="center">
                  <Field name={formField}>
                    {({ input: { name, value, onChange } }) => (
                      <TextField
                        label={field.label}
                        key={name}
                        margin="dense"
                        name={name}
                        onChange={({ target }) => {
                          onChange(target.value);
                        }}
                        value={value}
                        variant="outlined"
                        style={{ width: '80%' }}
                      />
                    )}
                  </Field>
                </Grid>
              );
            })}
          </Grid>
        </Grid>

        <div>
          <h3>Фотографии</h3>
          <div className="photos-wrap">
            {photos.map(photo => (
              <PhotoPreview photo={photo} />
            ))}
          </div>
        </div>
      </Grid>
    );
  });
};
