import React, { createElement } from 'react';
import { Layout, MenuItemLink, getResources } from 'react-admin';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@material-ui/core';
import AppsIcon from '@material-ui/icons/Apps';
import SettingsIcon from '@material-ui/icons/Settings';
import ChatIcon from '@material-ui/icons/Chat';
import TableChartIcon from '@material-ui/icons/TableChart';

import { getUserInfo } from '../services/auth-service';

const CustomMenu = ({ onMenuClick, logout }) => {
  const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
  const open = useSelector(state => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);
  const user = getUserInfo();

  return (
    <div style={{ width: '240px', marginTop: '20px' }}>
      {resources.map(resource => {
        return (
          <MenuItemLink
            key={resource.name}
            to={`/${resource.name}`}
            primaryText={(resource.options && resource.options.label) || resource.name}
            leftIcon={<AppsIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        );
      })}
      {user && user.role === 'ADMIN' && (
        <>
          <MenuItemLink
            to="/stats"
            primaryText="Отчеты"
            leftIcon={<TableChartIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/chat"
            primaryText="Чат"
            leftIcon={<ChatIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
          <MenuItemLink
            to="/settings"
            primaryText="Настройки"
            leftIcon={<SettingsIcon />}
            onClick={onMenuClick}
            sidebarIsOpen={open}
          />
        </>
      )}
      {isXSmall && logout}
    </div>
  );
};

export const CutomLayout = props => <Layout {...props} menu={CustomMenu} />;
