import React from 'react';
import { Field, useFormState } from 'react-final-form';
import TextField from '@material-ui/core/TextField';

export const TextInput = ({ label = '', name, placeholder = '' }) => {
  const { values } = useFormState();

  return (
    <div className="input-field">
      <h4 className="label">{label}</h4>
      <Field
        name={name}
        // validate={(value, allValues) => {
        //   return value || allValues.available ? undefined : 'Обязательное поле';
        // }}
      >
        {({ input, meta }) => (
          <>
            <TextField
              name={input.name}
              value={input.value}
              onChange={input.onChange}
              className={`field ${meta.error && meta.touched ? '-error' : ''} ${
                values.available ? '-disabled' : ''
              }`}
              variant="outlined"
              placeholder={placeholder}
              inputProps={{ className: 'input' }}
              type="text"
            />
            {meta.error && meta.touched && <span className="error-msg">{meta.error}</span>}
          </>
        )}
      </Field>
    </div>
  );
};
