import React from 'react';
import { Show, TabbedShowLayout, Tab, useAuthenticated } from 'react-admin';
import { RentData } from './RentData';
import { RentalInspection } from './RentInspection';
import { RentDecline } from './RentDecline';
import { RentHistory } from './RentHistory';
import { Bills } from './Bills';
import { RentActions } from './Actions';
import { RentCompensations } from './Compensations';
import './styles.scss';
import RentInsuranceData from './RentInsurancePaymentsInfo/RentInsuranceData';
import { RentAdditionalPhoto } from './RentAdditionalPhoto';
import { ObjectCommentSection } from '../../common/ObjectCommentSection/ObjectComment.Section';

export const RentShow = props => {
  useAuthenticated();
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Аренда">
          <RentData />
        </Tab>
        <Tab label="Страховые взносы">
          <RentInsuranceData />
        </Tab>
        <Tab label="ПСО">
          <RentalInspection stage="preRentalInspection" />
          <RentalInspection stage="postRentalInspection" />
        </Tab>
        <Tab label="Отмена аренды">
          <RentDecline />
        </Tab>
        <Tab label="Платежи">
          <Bills />
        </Tab>
        <Tab label="Доп. фотографии">
          <RentAdditionalPhoto />
        </Tab>
        <Tab label="История">
          <RentHistory />
        </Tab>
        <Tab label="Управление">
          <RentActions />
        </Tab>
        <Tab label="Возмещения">
          <RentCompensations />
        </Tab>
        <Tab label="Комментарии">
          <ObjectCommentSection title="Комментарии к аренде" />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
