import React from 'react';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';

import { TextField } from '../components/TextField';
import { CheckboxField } from '../components/CheckboxField';
import { CheckActions } from '../components/CheckActions';
import { ErrorMessage } from '../components/ErrorMessage';

const initialFormState = {
  isPassportConfirmed: false,
  isPassportExpired: false,
  isUserWanted: false,
};
const mapToInitialValues = data => {
  if (!data) {
    return initialFormState;
  }

  return {
    isPassportConfirmed: data.isPassportConfirmed,
    isPassportExpired: data.isPassportExpired,
    isUserWanted: data.isUserWanted,
  };
};

export const PassportCheck = ({ onSubmit, check = {} }) => {
  const { verdict, data = {}, isError } = check;
  const { factors = [] } = data;
  const initialValues = mapToInitialValues(data);

  const isInfoShown = !!factors.length;

  return (
    <div className="form-wrap">
      <h3 className="title">Паспорт</h3>
      {isError && <ErrorMessage />}
      <Form onSubmit={onSubmit(check)} initialValues={initialValues}>
        {({ handleSubmit, dirty }) => (
          <form onSubmit={handleSubmit} className="form">
            <TextField label="Статус проверки:" value={verdict} />
            <CheckboxField label="Паспорт подтвержден?" name="isPassportConfirmed" />
            <CheckboxField label="Паспорт просрочен?" name="isPassportExpired" />
            <CheckboxField label="Пользователь в розыске?" name="isUserWanted" />
            <CheckActions isSaveDisabled={!dirty} isInfoShown={isInfoShown}>
              <h4 className="title">Факторы</h4>
              {factors.map(factor => (
                <>
                  <Box>
                    <TextField label="Код:" value={factor?.code} />
                    <TextField label="Комментарий:" value={factor?.title} />
                  </Box>
                </>
              ))}
            </CheckActions>
          </form>
        )}
      </Form>
    </div>
  );
};
