import React from 'react';
import { Edit, TabbedForm, FormTab, useAuthenticated, useEditContext } from 'react-admin';
import {
  UserInformation,
  ScoringLog,
  PassportTab,
  DriversLicenseTab,
  UserToolbar,
  UserEditTitle,
  PaymentMethods,
  AdditionalPhotos,
  Actions,
  HostPreferences,
  GuestScoringReport,
} from './components';
import { RentTableList } from '../../common/RentTableList';
import { CarsList } from './components/CarsList';
import { ObjectCommentSection } from '../../common/ObjectCommentSection/ObjectComment.Section';

const commonTabs = [
  <FormTab label="Профиль">
    <UserInformation />
  </FormTab>,
  <FormTab label="Аренды">
    <RentTableList />
  </FormTab>,
  <FormTab label="Доп. фотографии">
    <AdditionalPhotos />
  </FormTab>,
  <FormTab label="Действия">
    <Actions />
  </FormTab>,
];
const hostTabs = [
  <FormTab label="Автомобили">
    <CarsList />
  </FormTab>,
  <FormTab label="Платежные данные">
    <PaymentMethods />
  </FormTab>,
  <FormTab label="Настройки">
    <HostPreferences />
  </FormTab>,
];
const guestTabs = [
  <FormTab label="Паспорт">
    <PassportTab />
  </FormTab>,
  <FormTab label="В / У">
    <DriversLicenseTab />
  </FormTab>,
  <FormTab label="Проверка">
    <GuestScoringReport />
  </FormTab>,
];

const Tabs = props => {
  const {
    record: { role = '' },
  } = useEditContext();

  return (
    <TabbedForm {...props} toolbar={<UserToolbar alwaysEnableSaveButton />}>
      {commonTabs}
      {role === 'HOST' && hostTabs}
      {role === 'GUEST' && guestTabs}
      <FormTab label="Комментарии">
        <ObjectCommentSection title="Комментарии к пользователю" />
      </FormTab>
    </TabbedForm>
  );
};

export const UserEdit = props => {
  useAuthenticated();

  return (
    <Edit {...props} title={<UserEditTitle />}>
      <Tabs />
    </Edit>
  );
};
