import React, { useCallback } from 'react';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';

import { TextField } from '../components/TextField';
import { TextInput } from '../components/TextInput';
import { CheckboxField } from '../components/CheckboxField';
import { CheckActions } from '../components/CheckActions';
import { ErrorMessage } from '../components/ErrorMessage';

const initialFormState = {
  isValid: false,
  experience: '',
  categories: '',
};
const mapToInitialValues = data => {
  if (!data) {
    return initialFormState;
  }

  const { isValid, experience = '', categories = [] } = data;

  return {
    isValid,
    experience,
    categories: categories.map(({ category }) => category).join(', '),
  };
};

export const DriversLicenseCheck = ({ onSubmit, check = {}, kbm }) => {
  const { verdict, data = {}, isError } = check;
  console.log('isError', isError);
  const { factors = [], categories: initialCategories } = data;
  const initialValues = mapToInitialValues(data);

  const isInfoShown = !!factors.length;

  const submitCallback = useCallback(
    (fields, formProps) => {
      const callback = onSubmit(check);
      const defaultDate = new Date().toISOString();
      const formState = {
        ...fields,
        categories:
          isError && typeof fields.categories === 'string'
            ? fields.categories
                .replace(/ /, '')
                .split(',')
                .map(category => ({ since: defaultDate, until: defaultDate, category }))
            : initialCategories,
      };
      return callback(formState, formProps);
    },
    [onSubmit, check, initialCategories, isError],
  );

  return (
    <div className="form-wrap">
      <h3 className="title">В / У</h3>
      {isError && <ErrorMessage />}
      <Form onSubmit={submitCallback} initialValues={initialValues}>
        {({ handleSubmit, values, dirty }) => {
          const { experience, categories } = values;

          return (
            <form onSubmit={handleSubmit} className="form">
              <TextField label="Статус проверки:" value={verdict} />
              <CheckboxField label="В / У подтвержден?" name="isValid" />
              {isError ? (
                <TextInput label="Начало стажа:" name="experience" placeholder="Например, 2018" />
              ) : (
                <TextField label="Начало стажа:" value={experience} />
              )}
              {isError ? (
                <TextInput
                  label="Категории прав:"
                  name="categories"
                  placeholder="Большими буквами через запятую"
                />
              ) : (
                <TextField label="Категории прав:" value={categories} />
              )}
              <TextField label="КБМ:" value={kbm} />
              <CheckActions
                isSaveDisabled={!dirty || !categories || !experience}
                isInfoShown={isInfoShown}
              >
                <h4 className="title">Факторы</h4>
                {factors.map(factor => (
                  <>
                    <Box className="factor-wrap">
                      <TextField label="Дата:" value={factor?.date} />
                      <TextField label="Тип:" value={factor?.comment} />
                      <TextField label="Срок:" value={factor?.limitation} />
                      <TextField label="Описание:" value={factor?.stateDescription} />
                      <TextField label="Доп. информация:" value={factor?.stateWarn} />
                    </Box>
                  </>
                ))}
              </CheckActions>
            </form>
          );
        }}
      </Form>
    </div>
  );
};
