import React, { useEffect, useState } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNotify } from 'react-admin';
import { MobileVersionForm } from './pages/MobileVersionForm';
import { makeCall } from '../../services/api-service';
import { SERVICE_URL } from '../../core/constants';

export const SettingsPage = () => {
  const [loading, setLoading] = useState(true);
  const [settings, setSettings] = useState({});

  const notify = useNotify();

  useEffect(() => {
    fetchSettings();
  }, []);

  const onFieldUpdate = (parent, field, value) => {
    setSettings({
      ...settings,
      [parent]: {
        ...settings[parent],
        [field]: value,
      },
    });
  };
  const onSettingsUpdate = async () => {
    try {
      await makeCall(`${SERVICE_URL}/settings/update-mobile-app-version`, {
        method: 'PATCH',
        data: {
          ...settings.mobileVersions,
        },
      });

      notify('Версии приложения обновлены!');
    } catch (e) {
      notify('Не удалось выполнить обновление версий приложений', 'error');
    }
  };

  const fetchSettings = async () => {
    const { data } = await makeCall(`${SERVICE_URL}/settings`, {
      method: 'GET',
    });
    setSettings(data);
    setLoading(false);
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div>
      <MobileVersionForm
        data={settings.mobileVersions}
        onUpdate={onSettingsUpdate}
        onFieldUpdate={(field, value) => onFieldUpdate('mobileVersions', field, value)}
      />
    </div>
  );
};
