import React from 'react';
import { useNotify } from 'react-admin';
import ImageUploader from 'react-images-upload';
import './styles.scss';

const uploaderErrors = {
  NOT_SUPPORTED_EXTENSION: 'Данный тип фото не поддерживается',
  FILESIZE_TOO_LARGE: 'Слишком большой размер фото',
};

const DefaultUploaderAction = () => (
  <div className="uploader-action">
    <svg className="icon">
      <path
        d="M10,14 C11.656,14 13,12.656 13,11 C13,9.344 11.656,8 10,8 C8.344,8 7,9.344 7,11 C7,12.656 8.344,14 10,14 M30,32 L7.122,32 L21.132,20.31 C21.624,19.892 22.516,19.894 22.998,20.308 L32,27.988 L32,30 C32,31.104 31.104,32 30,32 M6,4 L30,4 C31.104,4 32,4.896 32,6 L32,22.728 L25.594,17.264 C23.614,15.58 20.516,15.58 18.554,17.252 L4,29.396 L4,6 C4,4.896 4.896,4 6,4 M30,0 L6,0 C2.692,0 0,2.692 0,6 L0,30 C0,33.308 2.692,36 6,36 L30,36 C33.308,36 36,33.308 36,30 L36,6 C36,2.692 33.308,0 30,0"
        id="path-1"
      />
    </svg>
    <div className="box">
      <span className="title -plus">+</span>
      <span className="title">Загрузите фото</span>
    </div>
  </div>
);

const uploadPhoto = async (photo, onDrop) => {
  const formData = new FormData();
  formData.append('file', photo);
  onDrop(formData);
};

export const Uploader = ({ uploaderProps = {}, onDrop }) => {
  const ref = React.useRef(null);
  const notify = useNotify();

  const handleDrop = async picture => {
    if (ref.current && ref.current.state.fileErrors.length) {
      notify(uploaderErrors[ref.current.state.fileErrors[0].type]);
      ref.current.setState({ fileErrors: [] });
    }

    await Promise.all(picture.map(photo => uploadPhoto(photo, onDrop)));

    ref.current.state.pictures = [];
    ref.current.state.files = [];
  };

  return (
    <div className="uploader-wrapper">
      <ImageUploader
        ref={ref}
        withLabel={false}
        withIcon={false}
        onChange={handleDrop}
        singleImage={false}
        buttonText={<DefaultUploaderAction />}
        buttonClassName="uploader-btn"
        className="uploader-wrap"
        imgExtension={['.jpg', '.jpeg', '.png']}
        fileSizeError="Слишком большой размер фото"
        fileTypeError="Данный тип фото не поддерживается"
        maxFileSize={9999999}
        {...uploaderProps}
      />
    </div>
  );
};
