import React, { useCallback } from 'react';
import Button from '@material-ui/core/Button';
import { useDataProvider, useRedirect, useNotify } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

export const PaymentMethods = ({ record, ...rest }) => {
  const { paymentMethods = [], id } = record;
  const dataProvider = useDataProvider();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const { basePath, resource } = rest;

  const onClick = useCallback(async () => {
    try {
      await dataProvider.attachCard(resource, { id });

      notify('Card was attached successfuly');
      redirectTo('list', basePath);
    } catch (error) {
      notify(`Card attachment was failed with error: ${error}`);
    }
  }, [dataProvider, notify, redirectTo, basePath, resource, id]);

  if (!paymentMethods.length) {
    return null;
  }

  return (
    <div>
      {paymentMethods.map(method => {
        switch (method.type) {
          case 'PAYMENT_ACCOUNT': {
            return <PaymentAccountMethod {...method} />;
          }

          default: {
            return <></>;
          }
        }
      })}
    </div>
  );
};

const PaymentAccountMethod = ({ paymentAccount }) => {
  return (
    <div>
      <Card style={{ marginBottom: 14, width: '100%' }}>
        <CardContent>
          <Typography color="text.secondary" gutterBottom>
            <strong style={{ fontSize: 18 }}>Платежные данные Юр лица / ИП:</strong>
          </Typography>
          <Typography color="text.secondary">
            <strong>Идентификатор данных в базе </strong>
            <span>{paymentAccount.uuid}</span>
          </Typography>
          <Typography color="text.secondary">
            <strong>Название компании: </strong>
            <span>{paymentAccount.companyName}</span>
          </Typography>
          <Typography color="text.secondary">
            <strong>ИНН: </strong>
            <span>{paymentAccount.inn}</span>
          </Typography>
          <Typography color="text.secondary">
            <strong>КПП: </strong>
            <span>{paymentAccount.kpp}</span>
          </Typography>
          <Typography color="text.secondary">
            <strong>БИК: </strong>
            <span>{paymentAccount.bik}</span>
          </Typography>
          <Typography color="text.secondary">
            <strong>Наименование банка: </strong>
            <span>{paymentAccount.bankName}</span>
          </Typography>
          <Typography color="text.secondary">
            <strong>Корп. номер аккаунта: </strong>
            <span>{paymentAccount.corrAccountNumber}</span>
          </Typography>
          <Typography color="text.secondary">
            <strong>Номер аккаунта: </strong>
            <span>{paymentAccount.accountNumber}</span>
          </Typography>
        </CardContent>
      </Card>
    </div>
  );
};
