export const cancelBtnDisableStatuses = [
  'IN_PROGRESS',
  'FINISHED',
  'DECLINED_AUTOMATICALLY',
  'DECLINED_BY_HOST',
  'DECLINED_BY_GUEST',
  'DECLINED_BY_ADMIN',
];

export const confirmBtnDisableStatuses = [
  'DECLINED_AUTOMATICALLY',
  'DECLINED_BY_HOST',
  'DECLINED_BY_GUEST',
  'DECLINED_BY_ADMIN',
  'AWAITS_INITIAL_PAYMENT',
  'AWAITS_UPDATE_PAYMENT',
  'AWAITS_GUEST_SCORING_COMPLETION',
  'AWAITS_HOST_APPROVAL',
  'IN_PROGRESS',
  'FINISHED',
];
