import React from 'react';
import { Form } from 'react-final-form';
import Box from '@material-ui/core/Box';

import { TextField } from '../components/TextField';
import { CheckboxField } from '../components/CheckboxField';
import { CheckActions } from '../components/CheckActions';
import { ErrorMessage } from '../components/ErrorMessage';

const initialFormState = {
  hasDebts: false,
};
const mapToInitialValues = data => {
  if (!data) {
    return initialFormState;
  }

  return {
    hasDebts: data.hasDebts,
  };
};

export const OpenFsspDebtsCheck = ({ onSubmit, check = {} }) => {
  const { verdict, data = {}, isError } = check;
  const { count: debtsCount, total: debtsTotal, factors = [] } = data;
  const initialValues = mapToInitialValues(data);

  const isInfoShown = !!factors.length;

  return (
    <div className="form-wrap">
      <h3 className="title">Открытые долги ФССП</h3>
      {isError && <ErrorMessage />}
      <Form onSubmit={onSubmit(check)} initialValues={initialValues}>
        {({ handleSubmit, dirty }) => (
          <form onSubmit={handleSubmit} className="form">
            <TextField label="Статус проверки:" value={verdict} />
            <CheckboxField label="Есть задолженности?" name="hasDebts" />
            <TextField label="Кол-во задолженностей:" value={debtsCount} />
            <TextField label="Общая сумма задолженностей:" value={debtsTotal} />
            <CheckActions isSaveDisabled={!dirty} isInfoShown={isInfoShown}>
              <h4 className="title">Факторы</h4>
              {factors.map(factor => (
                <>
                  <Box className="factor-wrap">
                    <TextField label="Дата:" value={factor?.date} />
                    <TextField label="Сумма:" value={factor?.amount} />
                    <TextField label="Предмет Исполнения:" value={factor?.reason} />
                  </Box>
                </>
              ))}
            </CheckActions>
          </form>
        )}
      </Form>
    </div>
  );
};
