import React from 'react';
import { Toolbar } from 'react-admin';
import { PublishButton } from './ApprovePublish';
import { BanButton } from './Ban';
import { UnbanButton } from './Unban';
import { RejectButton } from './Reject';
import { UpdateButton } from './Update';
import { StartModerationButton } from './StartModeration';
import { RefinementButton } from './Refinement';

export const CarToolbar = props => (
  <Toolbar {...props} style={{ display: 'flex', flexWrap: 'wrap' }}>
    <StartModerationButton style={{ margin: '0 10px 10px 0' }} />
    <UpdateButton style={{ margin: '0 10px 10px 0' }} />
    <PublishButton style={{ margin: '0 10px 10px 0' }} />
    <RejectButton style={{ margin: '0 10px 10px 0' }} />
    <RefinementButton style={{ margin: '0 10px 10px 0' }} />
    <BanButton style={{ margin: '0 10px 10px 0' }} />
    <UnbanButton />
  </Toolbar>
);
