import React from 'react';
import { List, Datagrid, TextField, useAuthenticated } from 'react-admin';

import { ActiveCheckbox } from './ActiveCheckbox';

export const PromocodeList = props => {
  useAuthenticated();
  return (
    <List {...props} exporter={false}>
      <Datagrid isRowSelectable={() => false}>
        <TextField source="name" label="Название" />
        <TextField source="discount" label="Скидка" />
        <ActiveCheckbox label="Активный?" />
      </Datagrid>
    </List>
  );
};
