import React, { useCallback, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useDataProvider, useNotify } from 'react-admin';
import { format } from 'date-fns';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Loader } from '../../../common/Loader';
import { getDate, parseDate, zonedDateToUTC, getDateWithDelay } from '../../../utils/date';

export const RentProlongationForm = ({ record, resource }) => {
  const {
    id: rentId,
    car: { brand = '', model = '', productionYear = '', registrationNumber = '' } = {},
    rent: { endDate: initialRentEndDate },
  } = record;
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const onSubmit = useCallback(
    async ({ startDate, endDate, reason }, { reset }) => {
      setLoading(true);
      try {
        await dataProvider.requestRentProlongation(resource, {
          rentId,
          data: {
            startDate: zonedDateToUTC(startDate),
            endDate: zonedDateToUTC(endDate),
            reason,
          },
        });
        await dataProvider.getOne(resource, { id: rentId });

        setTimeout(() => {
          reset();
        }, 0);
        setLoading(false);
        notify('Аренда успешно продлена');
      } catch (error) {
        setLoading(false);
        notify(`Error: ${error}`);
      }
    },
    [dataProvider, notify, resource, rentId],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        startDate: parseDate(initialRentEndDate),
        endDate: getDateWithDelay(initialRentEndDate, 3),
        reason: `${brand} ${model} ${productionYear} ${registrationNumber}`,
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit} className="form">
          <Loader open={loading} />
          <Field name="startDate">
            {props => (
              <>
                <label className="label" htmlFor={props.input.name}>
                  Дата начала:
                </label>
                <TextField
                  id="datetime-local"
                  type="datetime-local"
                  name={props.input.name}
                  value={props.input.value}
                  onChange={props.input.onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="date-field"
                />
              </>
            )}
          </Field>
          <Field name="endDate">
            {props => (
              <>
                <label className="label" htmlFor={props.input.name}>
                  Дата окончания:
                </label>
                <TextField
                  id="datetime-local"
                  type="datetime-local"
                  name={props.input.name}
                  value={props.input.value}
                  onChange={props.input.onChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  className="date-field"
                />
              </>
            )}
          </Field>
          <Field name="reason">
            {props => (
              <>
                <label className="label" htmlFor={props.input.name}>
                  Пояснительная записка:
                </label>
                <TextField
                  name={props.input.name}
                  value={props.input.value}
                  onChange={props.input.onChange}
                  className="field"
                  variant="outlined"
                  placeholder="Например, нарушения правил сервиса"
                  inputProps={{ className: 'textarea' }}
                  multiline
                  rows={3}
                  type="text"
                  defaultValue={`${brand} ${model} ${productionYear} ${registrationNumber}`}
                />
              </>
            )}
          </Field>
          <Button type="submit" className="btn">
            Продлить
          </Button>
        </form>
      )}
    </Form>
  );
};
