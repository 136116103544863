import React, { useEffect, useState, useCallback } from 'react';
import { useDataProvider, useRedirect, useNotify } from 'react-admin';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import CloseIcon from '@material-ui/icons/Close';

import { Uploader } from '../../../../common/Uploader';
import './styles.scss';

export const TabPhotos = ({ record, ...rest }) => {
  const { id } = record;
  const { basePath, resource } = rest;
  const [carPhotos, updateCarPhotos] = useState([]);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  //   const redirectTo = useRedirect();

  useEffect(() => {
    const fetchCarPhotos = async () => {
      try {
        const { data } = await dataProvider.listCarPhotos(resource, { id });
        updateCarPhotos(data);
        return data;
      } catch (error) {
        notify(`During car photos fetching following error was occured: ${error}`);
        return error;
      }
    };
    fetchCarPhotos();
  }, [dataProvider, notify, resource, id]);

  const handleAdd = useCallback(
    async photo => {
      try {
        const { data } = await dataProvider.addCarPhoto(resource, { id, photo });
        updateCarPhotos([...carPhotos, data]);

        notify('Photo was successfuly added');
      } catch (error) {
        notify(`Photo adding was failed with error: ${error}`);
      }
    },
    [dataProvider, notify, resource, id, carPhotos],
  );

  const handleRemove = useCallback(
    async photoId => {
      try {
        await dataProvider.deleteCarPhoto(resource, { id, photoId });
        updateCarPhotos(carPhotos.filter(({ id }) => id !== photoId));

        notify('Photo was successfuly removed');
      } catch (error) {
        notify(`Photo removing was failed with error: ${error}`);
      }
    },
    [dataProvider, notify, resource, id, carPhotos],
  );

  const handleSetPrimary = useCallback(
    async photoId => {
      try {
        await dataProvider.setPrimaryCarPhoto(resource, { id, photoId });
        const updatedCarPhotos = carPhotos.reduce((acc, photo) => {
          if (photo.isPrimary) {
            return [...acc, { ...photo, isPrimary: false }];
          }
          if (photo.id === photoId) {
            return [...acc, { ...photo, isPrimary: true }];
          }
          return [...acc, photo];
        }, []);
        updateCarPhotos(updatedCarPhotos);

        notify('Photo was set primary');
      } catch (error) {
        notify(`Operation was failed with error: ${error}`);
      }
    },
    [dataProvider, notify, resource, id, carPhotos],
  );

  return (
    <div className="photos-tab">
      <div className="photos-wrap">
        <Uploader access="public" onDrop={handleAdd} />
        {carPhotos.map(({ id, urls: { medium: carPhotoUrl = '' }, isPrimary }) => (
          <div key={id} className="photo-container">
            <img className="photo" src={carPhotoUrl} alt="car" />
            <div className="close" onClick={() => handleRemove(id)}>
              <CloseIcon className="closing-icon" />
            </div>
            <div className="primary-photo" onClick={() => handleSetPrimary(id)}>
              {isPrimary ? (
                <RadioButtonCheckedIcon className="icon" />
              ) : (
                <RadioButtonUncheckedIcon className="icon" />
              )}
              <span className="label">Главное фото</span>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
