import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import Card from '@material-ui/core/Card';
import '../cards-wrapper.scss';

export const CarHistory = ({ record = {} }) => {
  const dataProvider = useDataProvider();
  const [carHistory, setCarHistory] = useState([]);

  useEffect(() => {
    const getCarHistory = async () => {
      const { data } = await dataProvider.getHistory(record.id);
      setCarHistory(data);
    };
    getCarHistory();
  }, [dataProvider, setCarHistory, record.id]);

  const filterHistoryItemData = data => {
    const copyData = { ...data };

    delete copyData.owner;
    delete copyData.createdAt;
    delete copyData.updatedAt;

    return copyData;
  };

  return (
    <div className="cards-wrapper">
      {carHistory.map(note => {
        const { recordId, event, dateOccurred, data } = note;
        const filteredData = filterHistoryItemData(data);

        if (Object.keys(filteredData).length === 0 && event === 'UPDATED') {
          return null;
        }

        return (
          <Card className="card">
            <div className="text-field">
              <span className="label">ID:</span>
              <span className="text">{recordId}</span>
            </div>
            <div className="text-field">
              <span className="label">Событие:</span>
              <span className="text">{event}</span>
            </div>
            <div className="text-field">
              <span className="label">Дата изменения:</span>
              <span className="text">{dateOccurred}</span>
            </div>
            <div className="text-field">
              <span className="label">Содержание:</span>
              <pre className="text">{JSON.stringify(filteredData, null, 2)}</pre>
            </div>
          </Card>
        );
      })}

      {!carHistory.length && <h3>Пусто</h3>}
    </div>
  );
};
