import { SIGNIN_URL, SIGNOUT_URL } from './core/constants';
import { makeAuthCall, makeCall } from './services/api-service';
import { deleteUserFromStorage, getUserInfo } from './services/auth-service';

export const authProvider = {
  login: async ({ username, password }) => {
    const data = await makeAuthCall(SIGNIN_URL, {
      data: { password, email: username },
      method: 'POST',
    });
    console.log(data);
    if (data.role !== 'ADMIN' && data.role !== 'SUPPORT') {
      return Promise.reject();
    }
    return Promise.resolve();
  },
  logout: async () => {
    if (!getUserInfo()) {
      return Promise.resolve();
    }
    await makeCall(SIGNOUT_URL, { method: 'POST' });
    deleteUserFromStorage();
    return Promise.resolve();
  },
  checkAuth: ({ path }) => {
    const user = getUserInfo();
    if (path === '/stats') {
      return user.role === 'ADMIN' ? Promise.resolve() : Promise.reject();
    }
    return getUserInfo() ? Promise.resolve() : Promise.reject();
  },
  checkError: error => {
    if (error.isAxiosError && !error.response) {
      return Promise.reject();
    }
    if (error.response.status === 401) {
      deleteUserFromStorage();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getPermissions: () => Promise.resolve(),
};
