import React, { useCallback } from 'react';
import { SaveButton, useDataProvider, useNotify } from 'react-admin';

export const StartModerationButton = props => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { resource } = props;

  const handleSave = useCallback(
    async values => {
      try {
        await dataProvider.startModeration(resource, { id: values.id });
        await dataProvider.getOne(resource, { id: values.id });

        notify('Moderation is in progress');
      } catch (error) {
        notify(`Starting moderation was failed with error: ${error}`);
      }
    },
    [resource, dataProvider, notify],
  );

  // TODO: remove pristine prop from SaveButton because of hardcode - create custom button instead SaveButton
  return <SaveButton {...props} onSave={handleSave} label="Запустить модерацию" pristine={false} />;
};
