import React, { useState, useCallback } from 'react';
import { useAuthenticated, useNotify, useDataProvider } from 'react-admin';
import { utils, read } from 'xlsx';
import Button from '@material-ui/core/Button';

import { Loader } from '../../../common/Loader';

const columns = ['brand', 'model', 'fee', 'youngDriverFee', 'ingosFee'];

export const CascoFeeUploader = () => {
  // useAuthenticated({ path: '/stats' });
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const onClick = useCallback(async e => {
    e.stopPropagation();
    e.preventDefault();
    const file = e.target.files[0];
    const fileBuffer = await file.arrayBuffer();
    const workbook = read(fileBuffer);
    const sheet = workbook.Sheets[workbook.SheetNames[0]];
    const sheetArray = utils.sheet_to_json(sheet, { header: 1, blankrows: false });
    const data = sheetArray.slice(1).map(el =>
      el.reduce(
        (acc, record, i) => ({
          ...acc,
          [columns[i]]:
            typeof record === 'number' ? Math.ceil(record) : record.toUpperCase().trim(),
        }),
        {},
      ),
    );
    try {
      await dataProvider.updateCascoFeeTable({
        data,
      });
      setLoading(false);
      notify('Страховые взносы успешно обновлены');
    } catch (error) {
      setLoading(false);
      const err = error?.response?.data?.message || error;
      notify(`Ошибка: ${err}`);
    }
  }, []);

  return (
    <>
      <Loader open={loading} />
      <Button className="btn" component="label" variant="contained" onChange={onClick}>
        Загрузить взносы Каско
        <input type="file" hidden style={{ width: '100%', height: '100%' }} />
      </Button>
    </>
  );
};
