// eslint-disable-next-line
const SERVICE_BASE_URL = window._env_.REACT_APP_SERVICE_BASE_URL;
// eslint-disable-next-line
const TALK_JS_APP_ID = window._env_.TALK_JS_APP_ID;
if (!SERVICE_BASE_URL) {
  throw new Error(`Improperly configured: Env var REACT_APP_SERVICE_BASE_URL is not defined`);
}

const AUTH_BASE_URL = `${SERVICE_BASE_URL}/auth`;
const UPLOADER_BASE_URL = `${SERVICE_BASE_URL}/uploader`;

module.exports = {
  SERVICE_URL: SERVICE_BASE_URL,
  UPLOADER_URL: UPLOADER_BASE_URL,
  UPLOADER_LINK_URL: `${UPLOADER_BASE_URL}/get-upload-url`,
  SIGNIN_URL: `${AUTH_BASE_URL}/login`,
  SIGNOUT_URL: `${AUTH_BASE_URL}/logout`,
  REFRESH_TOKENS_URL: `${AUTH_BASE_URL}/refresh`,
  roles: ['OBSERVER', 'GUEST', 'HOST', 'MEMBER'],
  TALK_JS_APP_ID,
};
