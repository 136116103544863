import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNotify } from 'react-admin';
import { ObjectCommentList } from './ObjectComment.List';
import { makeCall } from '../../services/api-service';
import { SERVICE_URL } from '../../core/constants';
import { Loader } from '../Loader';
import { ObjectCommentForm } from './ObjectComment.Form';
import { getUserInfo } from '../../services/auth-service';

export const ObjectCommentSection = props => {
  const [comments, setComments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [contentValue, setContentValue] = useState('');
  const notify = useNotify();

  const objectId = useMemo(() => {
    return props.record.id;
  }, [props]);

  useEffect(() => {
    updateCommentList();
  }, []);

  const updateCommentList = async () => {
    try {
      setLoading(true);
      const { data } = await makeCall(`${SERVICE_URL}/object-comments/${objectId}`);
      setComments(data);
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const handleCommentCreate = useCallback(async () => {
    try {
      const comment = {
        objectId,
        content: contentValue,
      };

      await makeCall(`${SERVICE_URL}/object-comments`, {
        method: 'POST',
        data: comment,
      });

      await updateCommentList();
      setContentValue('');
      notify('Комментарий успешно добавлен');
    } catch (e) {
      console.error(e);
      notify('Не удалось сохранить комментарий :(');
    }
  }, [contentValue, objectId]);

  return (
    <div>
      <h2>
        {props?.title || 'Комментарии'}
        <sup style={{ fontSize: 12 }}>({objectId})</sup>
      </h2>
      <hr />
      <ObjectCommentForm
        handleCommentCreate={handleCommentCreate}
        contentValue={contentValue}
        setContentValue={setContentValue}
      />
      <hr />
      <ObjectCommentList comments={comments} loading={loading} />
    </div>
  );
};
