import React from 'react';
import { Field, useFormState } from 'react-final-form';
import Checkbox from '@material-ui/core/Checkbox';

export const CheckboxField = ({ label = '', name = '' }) => {
  // const { values } = useFormState();

  return (
    <div className="text-field checkbox-wrap">
      <span className="label">{label}</span>
      <span className="text">
        <Field name={name}>
          {props => (
            <Checkbox
              className="checkbox"
              edge="start"
              checked={props.input.value}
              disableRipple
              onChange={props.input.onChange}
            />
          )}
        </Field>
      </span>
    </div>
  );
};
