import React from 'react';
import {
  List,
  Datagrid,
  FunctionField,
  useAuthenticated,
  FilterList,
  FilterListItem,
  Pagination,
} from 'react-admin';
import { Card, CardContent } from '@material-ui/core';

import './styles.scss';
import { ru } from 'date-fns/locale';
import { format } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { serverTimeZone } from '../../utils/date';

const RentStatusFilterFilter = () => (
  <FilterList label="Статус аренды">
    <FilterListItem label="Ожидает оплаты" value={{ status: 'AWAITS_INITIAL_PAYMENT' }} />
    <FilterListItem
      label="Ожидает скоринга"
      value={{ status: 'AWAITS_GUEST_SCORING_COMPLETION' }}
    />
    <FilterListItem label="Ожидает подтверждения" value={{ status: 'AWAITS_HOST_APPROVAL' }} />
    <FilterListItem label="Подтверждена" value={{ status: 'APPROVED_BY_HOST' }} />
    <FilterListItem label="В процессе" value={{ status: 'IN_PROGRESS' }} />
    <FilterListItem label="Завершена" value={{ status: 'FINISHED' }} />
    <FilterListItem label="Отменена админом" value={{ status: 'DECLINED_BY_ADMIN' }} />
    <FilterListItem label="Отменена хостом" value={{ status: 'DECLINED_BY_HOST' }} />
    <FilterListItem label="Отменена гостом" value={{ status: 'DECLINED_BY_GUEST' }} />
    <FilterListItem label="Отменена автоматически" value={{ status: 'DECLINED_AUTOMATICALLY' }} />
  </FilterList>
);
const FilterSidebar = () => (
  <Card className="rent-sidebar">
    <CardContent>
      <RentStatusFilterFilter />
    </CardContent>
  </Card>
);

export const RentList = props => {
  useAuthenticated();
  const { resource } = props;
  return (
    <List
      {...props}
      aside={resource === 'rent' ? <FilterSidebar /> : null}
      bulkActionButtons={false}
      sort={{ field: 'startDate', order: 'DESC' }}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    >
      <Datagrid rowClick="show" isRowSelectable={() => false}>
        <FunctionField
          label="Дата начала"
          render={record =>
            record.startDate
              ? `${formatInTimeZone(
                  new Date(record.startDate),
                  serverTimeZone,
                  'd MMMM yyyy, HH:mm OOOO',
                  {
                    locale: ru,
                  },
                )}`
              : ''
          }
          sortBy="startDate"
        />
        <FunctionField
          label="Дата окончания"
          render={record =>
            record.endDate
              ? `${formatInTimeZone(
                  new Date(record.endDate),
                  serverTimeZone,
                  'd MMMM yyyy, HH:mm OOOO',
                  {
                    locale: ru,
                  },
                )}`
              : ''
          }
          sortBy="endDate"
        />
        <FunctionField
          label="Хост"
          render={record => `${record.host.firstName} ${record.host.lastName}`}
        />
        <FunctionField
          label="Гост"
          render={record => `${record.guest.firstName} ${record.guest.lastName}`}
        />
        <FunctionField
          label="Авто"
          render={record =>
            `${record.car.brand} ${record.car.model} ${record.car.productionYear}, ${record.car.registrationNumber}`
          }
        />
      </Datagrid>
    </List>
  );
};
