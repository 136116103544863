export const statsMap = [
  { status: 'OBSERVERS', label: 'Обзерверы' },
  { status: 'GUESTS_SCORING_APPROVED', label: 'Госты - апрув' },
  { status: 'GUEST_SCORING_REJECTED', label: 'Госты - реджект' },
  { status: 'GUEST_BLOCKED', label: 'Госты - блок' },
  { status: 'GUEST_RATING', label: 'Рейтинг гостов' },
  { status: 'HOST', label: 'Хосты' },
  { status: 'HOST_RATING', label: 'Рейтинг хостов' },
  { status: 'CARS', label: 'Машины' },
  { status: 'CARS_RATING', label: 'Рейтинг машин' },
  { status: 'BILLS', label: 'Платежи' },
  { status: 'RENT_DAYS', label: 'Арендодни' },
  { status: 'INSURANCE', label: 'Ингос' },
  { status: 'INSURANCE_CASCO', label: 'Ингос-Каско' },
  // { status: 'COMMON_STATS', label: 'Общая статистика' },
];

export const columnsMap = [
  {
    name: 'OBSERVERS',
    tableName: 'Обзерверы',
    columns: {
      userLink: 'Ссылка на пользователя',
      createdAt: 'Дата регистрации',
      yearMonth: 'Год / месяц',
      role: 'Роль',
    },
  },
  {
    name: 'GUESTS_SCORING_APPROVED',
    tableName: 'Госты (апр)',
    columns: {
      userLink: 'Ссылка на пользователя',
      updatedAt: 'Дата апрува',
      yearMonth: 'Год / месяц',
      role: 'Роль',
    },
  },
  {
    name: 'GUEST_SCORING_REJECTED',
    tableName: 'Госты (редж)',
    columns: {
      userLink: 'Ссылка на пользователя',
      updatedAt: 'Дата реджекта',
      yearMonth: 'Год / месяц',
      role: 'Роль',
    },
  },
  {
    name: 'GUEST_BLOCKED',
    tableName: 'Госты (блок)',
    columns: {
      userLink: 'Ссылка на пользователя',
      updatedAt: 'Дата блокировки',
      yearMonth: 'Год / месяц',
      role: 'Роль',
    },
  },
  {
    name: 'GUEST_RATING',
    tableName: 'Рейтинг гостов',
    columns: {
      userLink: 'Ссылка на пользователя',
      userData: 'ФИО госта',
      email: 'Почта госта',
      phone: 'Телефон госта',
      rentsDaysCount: 'Арендодни',
      rentsCount: 'Кол-во аренд',
      reviewsCount: 'Кол-во отзывов',
      lastRentDate: 'Дата посл. аренды',
      yearMonth: 'Год / месяц',
    },
  },
  {
    name: 'HOST',
    tableName: 'Хосты',
    columns: {
      userLink: 'Ссылка на пользователя',
      createdAt: 'Дата регистрации',
      yearMonth: 'Год / месяц',
    },
  },
  {
    name: 'HOST_RATING',
    tableName: 'Рейтинг хостов',
    columns: {
      userLink: 'Ссылка на пользователя',
      userData: 'ФИО хоста',
      cityName: 'Город',
      rentsDaysCount: 'Арендодни',
      rentsCount: 'Кол-во аренд',
      reviewsCount: 'Кол-во отзывов',
      lastRentDate: 'Дата посл. аренды',
      yearMonth: 'Год / месяц',
    },
  },
  {
    name: 'CARS',
    tableName: 'Машины',
    columns: {
      carLink: 'Ссылка на машину',
      createdAt: 'Дата регистрации',
      yearMonth: 'Год / месяц',
      hostData: 'ФИО хоста',
      cityName: 'Город',
    },
  },
  {
    name: 'CARS_RATING',
    tableName: 'Рейтинг машин',
    columns: {
      brand: 'Марка авто',
      model: 'Модель авто',
      productionYear: 'Год авто',
      rentsDaysCount: 'Арендодни',
      rentsCount: 'Кол-во аренд',
      cityName: 'Город',
      lastRentDate: 'Дата посл. аренды',
      yearMonth: 'Год / месяц',
    },
  },
  {
    name: 'BILLS',
    tableName: 'Платежи',
    columns: {
      rentLink: 'Ссылка на аренду',
      amount: 'Сумма',
      updatedAt: 'Дата платежа',
      yearMonth: 'Год / месяц',
    },
  },
  {
    name: 'RENT_DAYS',
    tableName: 'Арендодни',
    columns: {
      rentLink: 'Ссылка на аренду',
      rentsDaysCount: 'Арендодни',
      dateOccured: 'Подтверждение',
      yearMonth: 'Год / месяц',
      carInfo: 'Авто',
      casco: 'Каско',
    },
  },
  {
    name: 'INSURANCE',
    tableName: 'Ингос',
    columns: {
      rentId: 'ID Аренды',
      startDate: 'Начало аренды',
      endDate: 'Конец аренды',
      rentsDaysCount: 'Арендодни',
      brand: 'Марка авто',
      model: 'Модель авто',
      registrationNumber: 'Госномер авто',
      vin: 'Vin-номер авто',
      casco: 'Каско',
    },
  },
  {
    name: 'INSURANCE_CASCO',
    tableName: 'Ингос-Каско',
    columns: {
      rentId: 'ID Аренды',
      startDate: 'Начало аренды',
      yearMonthStart: 'Год / месяц начала',
      endDate: 'Конец аренды',
      yearMonthEnd: 'Год / месяц конца',
      rentsDaysCount: 'Арендодни',
      brand: 'Марка авто',
      model: 'Модель авто',
      registrationNumber: 'Госномер авто',
      vin: 'Vin-номер авто',
      casco: 'Каско',
    },
  },
];

export const scoringFailedReport = {
  tableName: 'Проверки с ошибками',
  columns: {
    reportId: 'ID отчета',
    createdAt: 'Дата',
    failedChecks: 'Блоки с ошибками',
  },
};
