import React, { useState, useCallback } from 'react';
import { Button, Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { Form } from 'react-final-form';
import { TextInput, changeListParams } from 'react-admin';
import { useDispatch } from 'react-redux';
import { omit } from 'ramda';

export const FilterForm = ({ filterValues, resource }) => {
  const [manual, setManual] = useState(false);
  const dispatch = useDispatch();
  const setFilters = useCallback(
    (filter = {}) => dispatch(changeListParams(resource, { filter })),
    [],
  );

  const onSubmit = values => {
    const filterValue = manual
      ? { ...values, scoringStatus: 'MANUAL' }
      : omit(['scoringStatus'], values);
    if (Object.keys(filterValue).length > 0) {
      setFilters(filterValue);
    } else {
      setFilters({}, []);
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
    setManual(false);
  };

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box mt={8} />
            <Box display="flex" alignItems="center" mb={1}>
              <Box component="span" mr={2}>
                <TextInput
                  label="Поиск по почте"
                  source="email"
                  alwaysOn
                  style={{ marginRight: '15px' }}
                />
                <TextInput
                  style={{ width: 250 }}
                  label="Поиск по номеру телефона"
                  source="phone"
                  alwaysOn
                />
              </Box>
              <Box component="span" mr={2} mb={1.5}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={manual}
                      onChange={({ target: { checked } }) => setManual(checked)}
                      name="manual"
                    />
                  }
                  label="Только ручной скоринг"
                />
              </Box>
              <Box component="span" mr={2} mb={1.5}>
                <Button variant="outlined" color="primary" type="submit">
                  Поиск
                </Button>
              </Box>
              <Box component="span" mb={1.5}>
                <Button variant="outlined" onClick={resetFilter}>
                  Очистить поиск
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </div>
  );
};

export const UserFilter = props => {
  return props.context !== 'button' ? <FilterForm {...props} /> : null;
};
