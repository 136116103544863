import 'react-day-picker/lib/style.css';

import React, { useCallback } from 'react';
import Grid from '@material-ui/core/Grid';
import './style.scss';
import { addDays, parseISO } from 'date-fns';
import { DayPicker } from 'react-day-picker';
import { fromUTCtoLocalDate } from '../../../../utils/date';
import localizationUtils from '../../../../utils/localizationUtils';

const ACCESS_FOR_RENT_COLOR = '#06bb9b';
const RENT_COLOR = '#fdcb07';
const BOOKED_COLOR = '#0072f0';
const BLOCKED_COLOR = '#f53b45';

const renderDay = day => {
  const date = day.getDate();

  const cellStyle = {
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(221,237,232,0.43)',
    position: 'relative',
  };
  return (
    <div style={cellStyle}>
      <div>{date}</div>
    </div>
  );
};

export const ManagementCalendar = ({ record: { unavailabilityDates = {} } }) => {
  const modifiers = {
    booked: unavailabilityDates.booked?.map(period => ({
      after: addDays(parseISO(fromUTCtoLocalDate(period.startDate)), -1),
      before: addDays(parseISO(fromUTCtoLocalDate(period.endDate)), 1),
    })),
    blocked: unavailabilityDates.blocked?.map(period => ({
      after: addDays(parseISO(fromUTCtoLocalDate(period.startDate)), -1),
      before: parseISO(fromUTCtoLocalDate(period.endDate)),
    })),
    rent: unavailabilityDates.rent?.map(period => ({
      after: addDays(parseISO(fromUTCtoLocalDate(period.startDate)), -1),
      before: addDays(parseISO(fromUTCtoLocalDate(period.endDate)), 1),
    })),
  };
  const modifiersStyles = {
    booked: {
      backgroundColor: 'rgba(0,115,249,0.09) !important',
      borderRadius: '0',
      border: '1px solid #0072F0',
    },
    blocked: {
      backgroundColor: 'rgba(255,21,59,0.09) !important',
      borderRadius: '0',
      border: '1px solid #F53B45',
    },
    rent: {
      backgroundColor: 'rgba(253, 203, 7 0.05) !important',
      borderRadius: '0',
      border: '1px solid #FDCB07',
    },
  };

  return (
    <Grid direction="column" container spacing={3} style={{ margin: 0 }}>
      <h3 className="title" style={{ width: '100%', margin: '30px 0 20px' }}>
        Календарь доступности авто
      </h3>

      <DayPicker
        disabledDays={{ before: new Date() }}
        renderDay={renderDay}
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
        locale="ru"
        localeUtils={localizationUtils}
      />

      <div className="management-calendar__colors">
        <div className="management-calendar__colors-item">
          <div
            className="management-calendar__colors-item__block"
            style={{ color: ACCESS_FOR_RENT_COLOR }}
          />
          <div className="management-calendar__colors-item__name">Доступно для аренды</div>
        </div>
        <div className="management-calendar__colors-item">
          <div className="management-calendar__colors-item__block" style={{ color: RENT_COLOR }} />
          <div className="management-calendar__colors-item__name">Находится в аренде</div>
        </div>
        <div className="management-calendar__colors-item">
          <div
            className="management-calendar__colors-item__block"
            style={{ color: BOOKED_COLOR }}
          />
          <div className="management-calendar__colors-item__name">Забронировано</div>
        </div>
        <div className="management-calendar__colors-item">
          <div
            className="management-calendar__colors-item__block"
            style={{ color: BLOCKED_COLOR }}
          />
          <div className="management-calendar__colors-item__name">Недоступно для аренды</div>
        </div>
      </div>
    </Grid>
  );
};
