import { stringify } from 'querystring';
import simpleRestProvider from 'ra-data-simple-rest';
import { SERVICE_URL } from './core/constants';
import { userDataProvider } from './modules/User';
import { carDataProvider } from './modules/Car';
import { rentDataProvider } from './modules/Rent';
import { promocodeDataProvider } from './modules/Promocode';
import { rentExpiredDataProvider } from './modules/Rent/RentExpired';
import { makeCall } from './services/api-service';

const defaultDataProvider = simpleRestProvider(SERVICE_URL);

export const dataProvider = {
  ...defaultDataProvider,
  getList: async (resource, params) => {
    // todo refactor
    let curResource = resource;
    if (resource === 'rent-expired') {
      curResource = 'rent/list-with-expired-payment';
    }

    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const sortField = field === 'id' ? 'uuid' : field;
    const query = {
      order: JSON.stringify({ [sortField]: order }),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      where: JSON.stringify(params.filter),
    };

    // todo refactor
    if (resource === 'rent' || resource === 'rent-expired') {
      query.order = JSON.stringify({ [field]: order });
    }

    const url = `${SERVICE_URL}/${resource}?${stringify(query)}`;

    const { headers, data } = await makeCall(url, { method: 'GET' });
    return {
      data,
      total: parseInt(headers['content-range'].split('/').pop(), 10),
    };
  },

  getOne: async (resource, params) => {
    let curResource = resource;
    if (resource === 'rent-expired') {
      curResource = 'rent';
    }
    const { data } = await makeCall(`${SERVICE_URL}/${curResource}/${params.id}`, {
      method: 'GET',
    });
    return {
      data,
    };
  },
  ...userDataProvider,
  ...carDataProvider,
  ...rentExpiredDataProvider,
  ...promocodeDataProvider,
  ...rentDataProvider,
};
