import React from 'react';
import { Toolbar } from 'react-admin';
import { ScoringApproveButton } from './ScoringApprove';
import { ScoringRejectButton } from './ScoringReject';
import { BanButton } from './Ban';
import { UnbanButton } from './Unban';
import { UpdateButton } from './Update';
import { SetRoleButton } from './SetRole';
import { DeleteButton } from './Delete';

export const UserToolbar = props => (
  <Toolbar {...props} alignItems="flex-start">
    <UpdateButton style={{ marginRight: '10px' }} />
    <SetRoleButton />
    <ScoringApproveButton style={{ marginRight: '10px', whiteSpace: 'nowrap' }} />
    <ScoringRejectButton style={{ marginRight: '10px', whiteSpace: 'nowrap' }} />
    <BanButton style={{ marginRight: '10px' }} />
    <UnbanButton style={{ marginRight: '10px' }} />
    <DeleteButton />
  </Toolbar>
);
