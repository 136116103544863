import React, { useCallback, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useDataProvider, useNotify } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import { Loader } from '../../../common/Loader';

export const AdditionalServicesForm = ({ record, resource }) => {
  const {
    id: rentId,
    car: { brand = '', model = '', productionYear = '', registrationNumber = '' } = {},
  } = record;
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const onSubmit = useCallback(
    async ({ amount, reason }, { reset }) => {
      setLoading(true);
      try {
        await dataProvider.requestAdditionalFeaturesPayment(resource, {
          rentId,
          amount: amount !== '' ? parseFloat(amount, 10) : amount,
          reason,
        });
        await dataProvider.getOne(resource, { id: rentId });

        setTimeout(() => {
          reset();
        }, 0);
        setLoading(false);
        notify('Деньги успешно списаны');
      } catch (error) {
        setLoading(false);
        notify(`Error: ${error}`);
      }
    },
    [dataProvider, notify, resource, rentId],
  );

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        amount: '',
        reason: `${brand} ${model} ${productionYear} ${registrationNumber}`,
      }}
    >
      {props => (
        <form onSubmit={props.handleSubmit} className="form">
          <Loader open={loading} />
          <Field name="amount">
            {props => (
              <>
                <label className="label" htmlFor={props.input.name}>
                  Сумма:
                </label>
                <TextField
                  name={props.input.name}
                  value={props.input.value}
                  onChange={props.input.onChange}
                  className="field"
                  variant="outlined"
                  placeholder="Например, 10000 или 1230.44"
                  inputProps={{ className: 'input' }}
                  type="number"
                />
              </>
            )}
          </Field>
          <Field name="reason">
            {props => (
              <>
                <label className="label" htmlFor={props.input.name}>
                  Пояснительная записка:
                </label>
                <TextField
                  name={props.input.name}
                  value={props.input.value}
                  onChange={props.input.onChange}
                  className="field"
                  variant="outlined"
                  placeholder="Например, перерасход топлива, перепробег"
                  inputProps={{ className: 'textarea' }}
                  multiline
                  rows={3}
                  type="text"
                  defaultValue={`${brand} ${model} ${productionYear} ${registrationNumber}`}
                />
              </>
            )}
          </Field>
          <Button type="submit" className="btn">
            Списать
          </Button>
        </form>
      )}
    </Form>
  );
};
