import React, { useState, useEffect } from 'react';
import { useDataProvider } from 'react-admin';
import Card from '@material-ui/core/Card';
import '../cards-wrapper.scss';

export const RentHistory = ({ record = {} }) => {
  const dataProvider = useDataProvider();
  const [rentHistory, setRentHistory] = useState([]);

  useEffect(() => {
    const getRentHistory = async () => {
      const { data } = await dataProvider.getRentHistory(record.id);
      setRentHistory(data);
    };
    getRentHistory();
  }, [dataProvider, setRentHistory, record.id]);
  return (
    <div className="cards-wrapper">
      {rentHistory.map(note => {
        const { recordId, event, dateOccured, reason } = note;
        return (
          <Card className="card">
            <div className="text-field">
              <span className="label">ID:</span>
              <span className="text">{recordId}</span>
            </div>
            <div className="text-field">
              <span className="label">Событие:</span>
              <span className="text">{event}</span>
            </div>
            <div className="text-field">
              <span className="label">Дата изменения:</span>
              <span className="text">{dateOccured}</span>
            </div>
            <div className="text-field">
              <span className="label">Причина изменения:</span>
              <span className="text">{reason}</span>
            </div>
          </Card>
        );
      })}
    </div>
  );
};
