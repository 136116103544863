import React, { useCallback, useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { useDataProvider, useNotify } from 'react-admin';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Button from '@material-ui/core/Button';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';

import { DaysPicker } from '../../../../common/DaysPicker';
import { MaskedInput } from '../../../../common/MaskedInput';
import './styles.scss';

import { Loader } from '../../../../common/Loader';

const timeSlots = Array.from(new Array(24 * 2)).map(
  (_, index) => `${Math.floor(index / 2)}-${index % 2 === 0 ? '00' : '30'}`,
);
const checkIfEndTimeNotBefore = (startTime, endTime) => {
  const normalise = time =>
    time
      .split('-')
      .join('')
      .replace(/^000$/, '0')
      .replace(/^030$/, '30');

  return Number(normalise(startTime)) < Number(normalise(endTime));
};

export const HostPreferences = ({ record, resource }) => {
  const [loading, setLoading] = useState(false);
  const [hostPreferences, setHostPreferences] = useState({});
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { id: userId = '' } = record;
  const { workingHours = {} } = hostPreferences;
  const { days = [], startTime = '0-00', endTime = '0-00' } = workingHours || {};

  const onSubmit = useCallback(
    async (values, { reset }) => {
      const { days, startTime, endTime } = values;
      const payload = {};
      const isDefaultTime = startTime === '0-00' && endTime === '0-00';

      if (days.length && startTime && endTime) {
        if (!isDefaultTime && !checkIfEndTimeNotBefore(startTime, endTime)) {
          setTimeout(() => {
            reset();
          }, 0);
          notify('Время начала позже времени окончания');
          return;
        }
        payload.workingHours = { days, startTime, endTime };
      }

      setLoading(true);
      try {
        const { data } = await dataProvider.createOrUpdateHostPreferences({
          data: payload,
          id: userId,
        });
        setHostPreferences(data);

        setLoading(false);
        notify('Настройки хоста установлены');
      } catch (error) {
        setLoading(false);
        const err = error?.response?.data?.message || error;
        notify(`Ошибка: ${err}`);
      }
    },
    [dataProvider, notify, userId],
  );

  useEffect(() => {
    const getHostPreferences = async () => {
      const { data } = await dataProvider.getHostPreferences({
        id: userId,
      });
      setHostPreferences(data);
    };
    getHostPreferences();
  }, [dataProvider, userId]);

  return (
    <div className="container -preferences">
      <Box className="card">
        <h3 className="title">Рабочее время</h3>
        <div className="form-wrap">
          <Form
            onSubmit={onSubmit}
            initialValues={{
              days,
              startTime,
              endTime,
            }}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit} className="form">
                <Loader open={loading} />
                <Field name="startTime">
                  {({ input: { name, value, onChange } }) => (
                    <div className="wrap">
                      <InputLabel id="startTime" className="label">
                        Начало:
                      </InputLabel>
                      <Select
                        id="startTime"
                        native
                        className="select"
                        name={name}
                        value={value}
                        input={<Input className="input" />}
                        onChange={({ target: { value } }) => onChange(value)}
                      >
                        {timeSlots.map(time => (
                          <option key={`start-${time}`} value={time}>
                            {time}
                          </option>
                        ))}
                      </Select>
                    </div>
                  )}
                </Field>
                <Field name="endTime">
                  {({ input: { name, value, onChange } }) => (
                    <div className="wrap">
                      <InputLabel id="startTime" className="label">
                        Конец:
                      </InputLabel>
                      <Select
                        id="endTime"
                        native
                        className="select"
                        style={{ height: 30 }}
                        name={name}
                        value={value}
                        input={<Input className="input" />}
                        onChange={({ target: { value } }) => onChange(value)}
                      >
                        {timeSlots.map(time => (
                          <option key={`start-${time}`} value={time}>
                            {time}
                          </option>
                        ))}
                      </Select>
                    </div>
                  )}
                </Field>
                <Field name="days">
                  {({ input: { name, value, onChange } }) => (
                    <DaysPicker
                      className="days-picker"
                      name={name}
                      days={value}
                      onChange={(event, value) => {
                        onChange(value);
                      }}
                    />
                  )}
                </Field>
                <Button type="submit" className="btn -set">
                  Сохранить
                </Button>
              </form>
            )}
          </Form>
        </div>
      </Box>
    </div>
  );
};
