import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

export const ObjectCommentForm = ({ handleCommentCreate, contentValue, setContentValue }) => {
  return (
    <form
      onSubmit={event => {
        event.preventDefault();
        handleCommentCreate(contentValue);
      }}
    >
      <TextField
        style={{ width: '100%' }}
        label="Новый комментарий"
        multiline
        rows={4}
        variant="standard"
        onChange={event => {
          setContentValue(event.target.value);
        }}
        value={contentValue}
      />
      <Button style={{ marginTop: 14 }} variant="outlined" type="submit">
        Сохранить новый комментарий
      </Button>
    </form>
  );
};
