import { stringify } from 'querystring';
import { SERVICE_URL } from '../../core/constants';
import { makeCall } from '../../services/api-service';

export const rentDataProvider = {
  // getList: async (resource, params) => {
  //   let curResource = resource;
  //   if (resource === 'rent-expired') {
  //     curResource = 'rent/list-with-expired-payment';
  //   }
  //   const { page, perPage } = params.pagination;
  //   const { field, order } = params.sort;
  //   const query = {
  //     range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
  //     where: JSON.stringify(params.filter),
  //   };
  //   if (resource === 'rent' || resource === 'rent-expired') {
  //     query.order = JSON.stringify({ [field]: order });
  //   }
  //   const url = `${SERVICE_URL}/${curResource}?${stringify(query)}`;
  //
  //   const { headers, data } = await makeCall(url, { method: 'GET' });
  //   return {
  //     data,
  //     total: parseInt(headers['content-range'].split('/').pop(), 10),
  //   };
  // },
  getRentHistory: async id => {
    const url = `${SERVICE_URL}/rent/${id}/history`;

    const { data } = await makeCall(url, { method: 'GET' });
    return {
      data,
    };
  },
  cancelRent: async (_resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/rent/${params.id}/decline`, {
      method: 'PUT',
      data: {
        comment: 'Declined by admin',
        photoKeys: [],
      },
    });
    return { data };
  },
  confirmRent: async (_resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/pre-rental-inspection/confirm/${params.id}`, {
      method: 'PATCH',
      data: {},
    });
    return { data };
  },
  requestAdditionalFeaturesPayment: async (_resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/payments/additional-services`, {
      method: 'POST',
      data: {
        amount: params.amount,
        reason: params.reason,
        rentId: params.rentId,
      },
    });
    return { data };
  },
  requestFinePayment: async (_resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/payments/fine`, {
      method: 'POST',
      data: {
        amount: params.amount,
        reason: params.reason,
        rentId: params.rentId,
      },
    });
    return { data };
  },
  requestRentProlongation: async (_resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/rent/${params.rentId}/prolongation`, {
      method: 'POST',
      data: params.data,
    });
    return { data };
  },
  repayHostBill: async (_resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/payments/host-repay/${params.billId}`, {
      method: 'POST',
      data: {},
    });
    return { data };
  },
  getRentInfo: async params => {
    const { data } = await makeCall(`${SERVICE_URL}/rent/${params.rentId}/info`, {
      method: 'GET',
    });
    return { data };
  },
  startRentManually: async params => {
    const { data } = await makeCall(`${SERVICE_URL}/rent/${params.rentId}/start`, {
      method: 'PATCH',
      data: {},
    });
    return { data };
  },
  finishRentManually: async params => {
    const { data } = await makeCall(`${SERVICE_URL}/rent/${params.rentId}/finish`, {
      method: 'PATCH',
      data: {},
    });
    return { data };
  },
  finishPreRentalInspection: async params => {
    const { data } = await makeCall(
      `${SERVICE_URL}/pre-rental-inspection/${params.id}/finish/${params.role}`,
      {
        method: 'PATCH',
        data: {},
      },
    );
    return { data };
  },
  finishPostRentalInspection: async params => {
    const { data } = await makeCall(
      `${SERVICE_URL}/post-rental-inspection/${params.id}/finish/${params.role}`,
      {
        method: 'PATCH',
        data: {},
      },
    );
    return { data };
  },
  setRentStatusManually: async params => {
    const { data } = await makeCall(`${SERVICE_URL}/rent/${params.id}/status`, {
      method: 'PATCH',
      data: { status: params.status },
    });
    return { data };
  },
  changeDates: async params => {
    const { data } = await makeCall(`${SERVICE_URL}/rent/${params.id}/dates`, {
      method: 'PATCH',
      data: params.data,
    });
    return { data };
  },
  getCompensations: async params => {
    const { data } = await makeCall(`${SERVICE_URL}/rent/${params.id}/compensations`, {
      method: 'GET',
      data: params.data,
    });
    return { data };
  },
};
