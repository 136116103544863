import React from 'react';
import { useInput } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { update } from 'ramda';

const getAdditionalFeatureNameByCode = featureCode => {
  switch (featureCode) {
    case 'CHILD_SEAT': {
      return 'Детское кресло';
    }

    case 'NAVIGATOR': {
      return 'Навигатор';
    }

    case 'REGISTRATOR': {
      return 'Регистратор';
    }

    case 'RADAR_DETECTOR': {
      return 'Радар';
    }

    case 'ROOF_RACK': {
      return 'Багажник на крыше';
    }

    case 'FRIDGE': {
      return 'Холодильник';
    }

    case 'BIKE_RACK': {
      return 'Стойка велосипеда';
    }

    case 'SNOWBOARD_RACK': {
      return 'Стойка для сноуборда';
    }

    case 'SKIING_RACK': {
      return 'Лыжная стойка';
    }

    default: {
      return featureCode;
    }
  }
};

export const AdditionalFeatures = props => {
  const inputProps = useInput({
    ...props,
    id: 'predefinedAdditionalFeatures',
    source: 'predefinedAdditionalFeatures',
  });
  const { value, onChange } = inputProps.input;

  return (
    <Grid container wrap="wrap" justify="space-around">
      <h3 className="title" style={{ width: '100%', margin: '30px 0 20px' }}>
        Доп. услуги
      </h3>
      {value &&
        value.map((field, i) => {
          const { name: code, dailyPrice, wholeRentPrice } = field;
          return (
            <Grid container xs={3}>
              <Typography
                gutterBottom
                display="block"
                style={{ margin: '0 0 30px', fontSize: '14px' }}
              >
                {getAdditionalFeatureNameByCode(code)}
              </Typography>
              <TextField
                fullWidth
                label="Цена за день"
                key="dailyPrice"
                margin="dense"
                name="dailyPrice"
                onChange={({ target }) =>
                  onChange(
                    update(i, { ...field, [target.name]: parseInt(target.value, 10) }, value),
                  )
                }
                value={dailyPrice}
                variant="outlined"
              />
              <TextField
                fullWidth
                label="Вся стоимость аренды"
                key="wholeRentPrice"
                margin="dense"
                name="wholeRentPrice"
                onChange={({ target }) =>
                  onChange(
                    update(i, { ...field, [target.name]: parseInt(target.value, 10) }, value),
                  )
                }
                value={wholeRentPrice}
                variant="outlined"
              />
            </Grid>
          );
        })}
    </Grid>
  );
};
