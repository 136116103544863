import { omit } from 'ramda';

const omitUserProps = [
  'createdAt',
  'updatedAt',
  'id',
  'scoringLog',
  'scoringStatus',
  'role',
  'isBlocked',
  'report',
];
const omitDocProps = ['createdAt', 'updatedAt'];

export const modifyUserResponse = data => {
  const { passport, driversLicense, firstLicenseIssueYear, ...restUserData } = data;
  const user = omit(omitUserProps, restUserData);
  const licenseYearToNumber =
    firstLicenseIssueYear !== '' ? parseInt(firstLicenseIssueYear, 10) : null;
  return {
    ...user,
    firstLicenseIssueYear: licenseYearToNumber,
    passport: passport.map(pass => omit(omitDocProps, pass)),
    driversLicense: driversLicense.map(license => omit(omitDocProps, license)),
  };
};
