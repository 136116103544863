import React, { useCallback } from 'react';
import { useNotify } from 'react-admin';
// eslint-disable-next-line
import qs from 'qs';
import { utils, writeFileXLSX } from 'xlsx';
import { Form, Field } from 'react-final-form';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Box from '@material-ui/core/Box';

import { SERVICE_URL } from '../../core/constants';
import { makeCall } from '../../services/api-service';
import { MaskedInput } from '../../common/MaskedInput';
import { statsMap, columnsMap } from './mappers';

export const StatsForm = ({ setLoading }) => {
  const notify = useNotify();

  const onSubmit = useCallback(
    async ({ type, startDate, endDate }) => {
      if (type === 'INSURANCE' && (!startDate || !endDate)) {
        notify('Заполните даты отчета');
        return false;
      }
      try {
        setLoading(true);

        const query = {
          type,
          startDate,
          endDate,
        };
        const { data } = await makeCall(`${SERVICE_URL}/stats?${qs.stringify(query)}`, {
          method: 'GET',
        });
        const formattedData = data.map(record => {
          const { columns } = columnsMap.find(({ name }) => type === name);
          return Object.keys(record).reduce((acc, key) => {
            const newAcc = { ...acc, [columns[key]]: record[key] };
            return newAcc;
          }, {});
        });
        const { tableName } = columnsMap.find(({ name }) => type === name);

        setLoading(false);

        const ws = utils.json_to_sheet(formattedData);
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, tableName);
        return writeFileXLSX(wb, `${tableName}.xlsx`);
      } catch (error) {
        setLoading(false);
        const err = error?.response?.data?.message || error;
        return notify(`Ошибка: ${err}`);
      }
    },
    [notify, setLoading],
  );
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{
        type: statsMap[0].status,
        startDate: null,
        endDate: null,
      }}
    >
      {({ values, handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Box className="stats-wrapper">
            <h3 className="title -section">Скачать отчет</h3>
            <Box className="download-box">
              <Field name="type">
                {props => (
                  <FormControl className="select-wrap" size="small">
                    <Select
                      labelId="select-small"
                      id="select-small"
                      className="select"
                      label="Статус"
                      name={props.input.name}
                      value={props.input.value}
                      onChange={event => props.input.onChange(event.target.value)}
                    >
                      {statsMap.map(({ status, label }) => (
                        <MenuItem value={status}>{label}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
              </Field>
              <Button className="btn" type="submit">
                Скачать
              </Button>
            </Box>
            {values.type === 'INSURANCE' && (
              <Box className="date-selection">
                <Field name="startDate">
                  {props => (
                    <MaskedInput
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      className="field -start"
                      variant="outlined"
                      placeholder="Дата от ГГГГ-ММ-ДД"
                      inputProps={{ className: 'input' }}
                      type="text"
                      mask={[/\d/, /\d/, /\d/, /\d/, /-/, /\d/, /\d/, /-/, /\d/, /\d/]}
                    />
                  )}
                </Field>
                <Field name="endDate">
                  {props => (
                    <MaskedInput
                      name={props.input.name}
                      value={props.input.value}
                      onChange={props.input.onChange}
                      className="field"
                      variant="outlined"
                      placeholder="Дата до ГГГГ-ММ-ДД"
                      inputProps={{ className: 'input' }}
                      type="text"
                      mask={[/\d/, /\d/, /\d/, /\d/, /-/, /\d/, /\d/, /-/, /\d/, /\d/]}
                    />
                  )}
                </Field>
                <span className="text -danger">
                  <b>* Дата ДО должна быть на один день позже нужной</b>
                </span>
              </Box>
            )}
          </Box>
        </form>
      )}
    </Form>
  );
};
