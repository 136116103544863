import React, { useState, useEffect, useCallback } from 'react';
import { Title, useAuthenticated, useNotify } from 'react-admin';
// eslint-disable-next-line
import qs from 'qs';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';

import { SERVICE_URL } from '../../core/constants';
import { makeCall } from '../../services/api-service';
import { Loader } from '../../common/Loader';
import { StatsForm } from './StatsForm';
import { ScoringStatsForm } from './ScoringStatsForm';
import './styles.scss';

export const StatsPage = () => {
  useAuthenticated({ path: '/stats' });

  const [{ registered, published, onSearch, inProgress }, setCommonStats] = useState({
    registered: '',
    published: '',
    onSearch: '',
    inProgress: '',
  });
  const [loading, setLoading] = useState(false);
  const notify = useNotify();

  useEffect(() => {
    const fetchCommonStats = async () => {
      try {
        setLoading(true);

        const query = {
          type: 'COMMON_STATS',
        };
        const { data } = await makeCall(`${SERVICE_URL}/stats?${qs.stringify(query)}`, {
          method: 'GET',
        });

        setCommonStats(data[0]);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        const err = error?.response?.data?.message || error;
        notify(`Ошибка: ${err}`);
      }
    };
    fetchCommonStats();
  }, [notify]);

  return (
    <Card className="container stats-page">
      <Loader open={loading} />
      <Title title="Отчеты" />
      <CardContent className="info-wrapper">
        <div className="wrap">
          <h3 className="title -section">Статистика сервиса</h3>
          <div className="text-field">
            <span className="label">Зарегистрировано машин:</span>
            <span className="text">{registered}</span>
          </div>
          <div className="text-field">
            <span className="label">Опубликовано машин:</span>
            <span className="text">{published}</span>
          </div>
          <div className="text-field">
            <span className="label">Машины на поиске:</span>
            <span className="text">{onSearch}</span>
          </div>
          <div className="text-field">
            <span className="label">Машины в аренде:</span>
            <span className="text">{inProgress}</span>
          </div>
        </div>
        <ScoringStatsForm setLoading={setLoading} />
        <StatsForm setLoading={setLoading} />
      </CardContent>
    </Card>
  );
};
