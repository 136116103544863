import React, { useCallback } from 'react';
import { useDataProvider, useRedirect, useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';

export const CancelButton = props => {
  const dataProvider = useDataProvider();
  const redirectTo = useRedirect();
  const notify = useNotify();
  const { basePath, resource, data: { id = '' } = {} } = props;

  const handleSave = useCallback(async () => {
    try {
      await dataProvider.cancelExpiredRent(resource, { id });

      notify('Rent canceled successfuly');
      redirectTo('list', basePath);
    } catch (error) {
      notify(`Rent canceling was failed with error: ${error}`);
    }
  }, [dataProvider, notify, resource, basePath, id, redirectTo]);

  // TODO: remove pristine prop from SaveButton because of hardcode - create custom button instead SaveButton
  return (
    <Button
      {...props}
      onClick={handleSave}
      label="Cancel"
      style={{ background: '#3f51b5', color: '#fff' }}
    >
      Cancel Rent
    </Button>
  );
};
