import React, { useCallback } from 'react';
import { SaveButton, useDataProvider, useNotify, useRedirect } from 'react-admin';

export const DeleteButton = props => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const { resource, basePath } = props;

  const handleSave = useCallback(
    async values => {
      try {
        await dataProvider.deleteUser(resource, { id: values.id });
        await dataProvider.getOne(resource, { id: values.id });

        notify('User deleted successfuly');
      } catch (error) {
        if (error?.response?.status === 404) {
          redirectTo(basePath);
        } else {
          notify(`User deletion was failed with error: ${error}`);
        }
      }
    },
    [dataProvider, notify, resource, basePath, redirectTo],
  );

  // TODO: remove pristine prop because of hardcode - create custom button instead SaveButton
  return <SaveButton {...props} onSave={handleSave} label="Удалить" pristine={false} />;
};
