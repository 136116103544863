import React from 'react';
import TextField from '@material-ui/core/TextField';
import { Button, makeStyles } from '@material-ui/core';
import { SettingsPageLayout } from '../SettingsPageLayout';
import { StartModerationButton } from '../../Car/components/Toolbar/StartModeration';

const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      width: 'calc((100%/2) - 16px)',
      '&:not(:last-child)': {
        marginRight: theme.spacing(2),
      },
    },
  },
}));

export const MobileVersionForm = ({ data, onUpdate, onFieldUpdate }) => {
  const classes = useStyles();
  const {
    androidProdRecommended,
    androidProdRequired,
    iosProdRecommended,
    iosProdRequired,
    devRecommended,
    devRequired,
  } = data;

  return (
    <SettingsPageLayout title="Настройка заглушки версий мобильного приложения">
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        style={{ display: 'flex', flexDirection: 'column' }}
        onSubmit={onUpdate}
      >
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <fieldset style={{ width: '100%' }}>
            <legend>
              <b>Production</b>
            </legend>
            <div>
              <h3 style={{ marginTop: 5, marginBottom: 13 }}>Android</h3>
              <div>
                <TextField
                  id="outlined-basic"
                  label="Рекомендуемая версия"
                  onChange={event => onFieldUpdate('androidProdRecommended', event.target.value)}
                  variant="outlined"
                  defaultValue={androidProdRecommended}
                />
                <TextField
                  id="outlined-basic"
                  label="Обязательная версия"
                  onChange={event => onFieldUpdate('androidProdRequired', event.target.value)}
                  variant="outlined"
                  defaultValue={androidProdRequired}
                />
              </div>
              <h3 style={{ marginTop: 10, marginBottom: 13 }}>IOS</h3>
              <div>
                <TextField
                  id="outlined-basic"
                  label="Рекомендуемая версия"
                  onChange={event => onFieldUpdate('iosProdRecommended', event.target.value)}
                  variant="outlined"
                  defaultValue={iosProdRecommended}
                />
                <TextField
                  id="outlined-basic"
                  label="Обязательная версия"
                  onChange={event => onFieldUpdate('iosProdRequired', event.target.value)}
                  variant="outlined"
                  defaultValue={iosProdRequired}
                />
              </div>
            </div>
          </fieldset>

          <fieldset style={{ width: '100%' }}>
            <legend>
              <b>Development</b>
            </legend>
            <h3 style={{ marginTop: 5, marginBottom: 13 }}>Android, IOS</h3>
            <div>
              <TextField
                id="outlined-basic"
                onChange={event => onFieldUpdate('devRecommended', event.target.value)}
                label="Рекомендуемая версия"
                variant="outlined"
                defaultValue={devRecommended}
              />
              <TextField
                id="outlined-basic"
                onChange={event => onFieldUpdate('devRequired', event.target.value)}
                label="Обязательная версия"
                variant="outlined"
                defaultValue={devRequired}
              />
            </div>
          </fieldset>
        </div>

        <Button
          style={{ marginTop: 15, width: 150 }}
          color="primary"
          size="large"
          variant="outlined"
          type="submit"
        >
          <span>Сохранить</span>
        </Button>
      </form>
    </SettingsPageLayout>
  );
};
