import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Field } from 'react-final-form';
import { makeStyles } from '@material-ui/styles';
import { renderer } from '../../renderer';
import { fieldsSchema } from './form-schema';
import { AdditionalFeatures } from '../AdditionalFeatures';
import { DistancePackages } from '../DistancePackages';
import { ManagementCalendar } from '../ManagementCalendar/ManagementCalendar';
import { CarLabels } from '../CarLabels';

const useStyles = makeStyles(theme => ({
  outlined: {
    padding: '10.5px 14px',
  },
  margin: {
    margin: '8px 0 4px',
  },
}));

const FieldGrid = ({ fields, classes, count }) => (
  <Grid container spacing={2} style={{ marginBottom: '40px' }}>
    {fields.map(field => (
      <Grid item xs={count === 0 ? 12 : 4} key={`grid-${field.name}`}>
        <Field name={field.name}>{({ input }) => renderer(field, input, classes)}</Field>
      </Grid>
    ))}
  </Grid>
);

const fieldGroups = [
  fieldsSchema.slice(0, 1),
  fieldsSchema.slice(1, 4),
  fieldsSchema.slice(4, 12),
  fieldsSchema.slice(12, 23),
  fieldsSchema.slice(23, 25),
  fieldsSchema.slice(25, 28),
  fieldsSchema.slice(28, 32),
  fieldsSchema.slice(32, 36),
  fieldsSchema.slice(36, 39),
  fieldsSchema.slice(39),
];

export const OtherInformation = props => {
  const classes = useStyles();

  return (
    <Grid container justify="space-between" alignItems="flex-start">
      <CarLabels {...props} />

      <h3 className="title" style={{ width: '100%', margin: '30px 0 20px' }}>
        Доп. информация о машине
      </h3>
      {fieldGroups.map((fields, index) => (
        <FieldGrid
          key={`field-grid-${fields[0].key}`}
          fields={fields}
          classes={classes}
          count={index}
        />
      ))}
      <ManagementCalendar {...props} />
      <AdditionalFeatures {...props} />
      <DistancePackages {...props} />
    </Grid>
  );
};
