import React, { useCallback, useState } from 'react';
import { useDataProvider, useRedirect, useNotify } from 'react-admin';
import Card from '@material-ui/core/Card';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';

import { AdditionalServicesForm } from './AdditionalServicesForm';
import { FineForm } from './FineForm';
import { RentProlongationForm } from './RentProlongationForm';
import '../cards-wrapper.scss';

export const Bills = ({ record, resource }) => {
  const [radioValue, setRadioValue] = React.useState('ADDITIONAL_SERVICES');
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const repayHandler = useCallback(
    async billId => {
      try {
        await dataProvider.repayHostBill(resource, { billId });
        await dataProvider.getOne(resource, { id: record.rent.id });

        notify('Bill was repayed successfuly');
      } catch (error) {
        notify(`Bill repayment was failed with error: ${error}`);
      }
    },
    [dataProvider, notify, resource],
  );

  const handleRadioChange = event => {
    setRadioValue(event.target.value);
  };

  const { bills = [] } = record;
  const guestBills = bills.filter(({ cashFlow }) => cashFlow === 'GUEST_WRITEOFF');
  const hostBills = bills.filter(({ cashFlow }) => cashFlow === 'HOST_PAYOFF');

  return (
    <div className="container">
      <h3 className="title">Безакцептное списание с госта</h3>
      <div className="form-wrap">
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="write-offs"
            name="radio-buttons-group"
            value={radioValue}
            onChange={handleRadioChange}
          >
            <FormControlLabel value="ADDITIONAL_SERVICES" control={<Radio />} label="Доп. услуги" />
            <FormControlLabel value="FINE" control={<Radio />} label="Штраф" />
            <FormControlLabel
              value="RENT_PROLONGATION"
              control={<Radio />}
              label="Продление аренды"
            />
          </RadioGroup>
        </FormControl>
        {radioValue === 'ADDITIONAL_SERVICES' && (
          <AdditionalServicesForm record={record} resource={resource} />
        )}
        {radioValue === 'FINE' && <FineForm record={record} resource={resource} />}
        {radioValue === 'RENT_PROLONGATION' && (
          <RentProlongationForm record={record} resource={resource} />
        )}
      </div>
      <div>
        <h3 className="section-title">Платежи госта</h3>
        <div className="cards-wrapper">
          {guestBills.map(bill => {
            const {
              id,
              createdAt,
              updatedAt,
              userId,
              orderId,
              paymentId,
              rentId,
              amount,
              reason,
              cashFlow,
              purpose,
              status,
              details,
            } = bill;
            return (
              <Card className="card">
                <div className="text-field">
                  <span className="label">ID:</span>
                  <span className="text">{id}</span>
                </div>
                <div className="text-field">
                  <span className="label">Дата создания:</span>
                  <span className="text">{createdAt}</span>
                </div>
                <div className="text-field">
                  <span className="label">Дата изменения:</span>
                  <span className="text">{updatedAt}</span>
                </div>
                <div className="text-field">
                  <span className="label">Пользователь:</span>
                  <span className="text">{userId}</span>
                </div>
                <div className="text-field">
                  <span className="label">Номер заказа (OrderId):</span>
                  <span className="text">{orderId}</span>
                </div>
                <div className="text-field">
                  <span className="label">Номер платежа (PaymentId):</span>
                  <span className="text">{paymentId}</span>
                </div>
                <div className="text-field">
                  <span className="label">Номер аренды:</span>
                  <span className="text">{rentId}</span>
                </div>
                <div className="text-field">
                  <span className="label">Сумма:</span>
                  <span className="text">{amount}</span>
                </div>
                <div className="text-field">
                  <span className="label">Примечание:</span>
                  <span className="text">{reason}</span>
                </div>
                <div className="text-field">
                  <span className="label">Направление платежа:</span>
                  <span className="text">{cashFlow}</span>
                </div>
                <div className="text-field">
                  <span className="label">Цель платежа:</span>
                  <span className="text">{purpose}</span>
                </div>
                <div className="text-field">
                  <span className="label">Статус платежа:</span>
                  <span className="text">{status}</span>
                </div>
                <div className="text-field">
                  <span className="label">Детали платежа:</span>
                  <pre className="text">{JSON.stringify(details, null, 2)}</pre>
                </div>
              </Card>
            );
          })}
        </div>
        <h3 className="section-title">Платежи хоста</h3>
        <div className="cards-wrapper">
          {hostBills.map(bill => {
            const {
              id,
              createdAt,
              updatedAt,
              userId,
              orderId,
              paymentId,
              rentId,
              amount,
              reason,
              cashFlow,
              purpose,
              status,
              details,
            } = bill;
            return (
              <Card className="card">
                <div className="text-field">
                  <span className="label">ID:</span>
                  <span className="text">{id}</span>
                </div>
                <div className="text-field">
                  <span className="label">Дата создания:</span>
                  <span className="text">{createdAt}</span>
                </div>
                <div className="text-field">
                  <span className="label">Дата изменения:</span>
                  <span className="text">{updatedAt}</span>
                </div>
                <div className="text-field">
                  <span className="label">Пользователь:</span>
                  <span className="text">{userId}</span>
                </div>
                <div className="text-field">
                  <span className="label">Номер заказа (OrderId):</span>
                  <span className="text">{orderId}</span>
                </div>
                <div className="text-field">
                  <span className="label">Номер платежа (PaymentId):</span>
                  <span className="text">{paymentId}</span>
                </div>
                <div className="text-field">
                  <span className="label">Номер аренды:</span>
                  <span className="text">{rentId}</span>
                </div>
                <div className="text-field">
                  <span className="label">Сумма:</span>
                  <span className="text">{amount}</span>
                </div>
                <div className="text-field">
                  <span className="label">Примечание:</span>
                  <span className="text">{reason}</span>
                </div>
                <div className="text-field">
                  <span className="label">Направление платежа:</span>
                  <span className="text">{cashFlow}</span>
                </div>
                <div className="text-field">
                  <span className="label">Цель платежа:</span>
                  <span className="text">{purpose}</span>
                </div>
                <div className="text-field">
                  <span className="label">Статус платежа:</span>
                  <span className="text">{status}</span>
                </div>
                <div className="text-field">
                  <span className="label">Детали платежа:</span>
                  <pre className="text">{JSON.stringify(details, null, 2)}</pre>
                </div>
                {status !== 'COMPLETED' && (
                  <div className="text-field">
                    <Button
                      onClick={() => repayHandler(id)}
                      style={{ background: '#3f51b5', color: '#fff', height: '30px' }}
                    >
                      Погасить платеж
                    </Button>
                  </div>
                )}
              </Card>
            );
          })}
        </div>
      </div>
    </div>
  );
};
