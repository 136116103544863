import { omit } from 'ramda';

export const modifyCarResponse = data => {
  return omit(
    [
      'createdAt',
      'updatedAt',
      'deletedAt',
      'uuid',
      'ownerId',
      'scoringLog',
      'address',
      'status',
      'scoringStatus',
      'moderationStatus',
    ],
    { ...data },
  );
};
