import React, { useCallback, useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { useDataProvider, useNotify } from 'react-admin';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import { Loader } from '../../../common/Loader';
import { ChangeDatesForm } from './ChangeDatesFrom';
import { cancelBtnDisableStatuses } from './actionsDisables';
import { rentStatuses } from './rentStatuses';
import './styles.scss';

const renderBoolean = value => {
  if (value === null || typeof value !== 'boolean') {
    return '--';
  }
  if (value) {
    return 'да';
  }
  return 'нет';
};

export const RentActions = ({ record, resource }) => {
  const { id: rentId, rent: { startDate, endDate } = {} } = record;
  const [rentInfo, setRentInfo] = useState({});
  const [manualRentStatus, setManualRentStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { status, preRentalInspection, postRentalInspection } = rentInfo;

  const getRentInfo = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await dataProvider.getRentInfo({
        rentId,
      });
      setRentInfo(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify(`Error: ${error}`);
    }
  }, [dataProvider, notify, rentId]);

  const onChangeManualStatus = event => {
    setManualRentStatus(event.target.value);
  };

  const startRentManually = useCallback(async () => {
    setLoading(true);
    try {
      await dataProvider.startRentManually({
        rentId,
      });
      await getRentInfo();
      setLoading(false);
      notify('Аренда успешно начата');
    } catch (error) {
      setLoading(false);
      const err = error?.response?.data?.message || error;
      notify(`Ошибка: ${err}`);
    }
  }, [dataProvider, notify, rentId, getRentInfo]);

  const finishRentManually = useCallback(async () => {
    setLoading(true);
    try {
      await dataProvider.finishRentManually({
        rentId,
      });
      await getRentInfo();
      setLoading(false);
      notify('Аренда успешно завершена');
    } catch (error) {
      setLoading(false);
      const err = error?.response?.data?.message || error;
      notify(`Ошибка: ${err}`);
    }
  }, [dataProvider, notify, rentId, getRentInfo]);

  const changeStatus = useCallback(async () => {
    if (!manualRentStatus) {
      return;
    }

    setLoading(true);
    try {
      await dataProvider.setRentStatusManually({ id: rentId, status: manualRentStatus });
      await getRentInfo();
      setLoading(false);
      notify('Статус аренды успешно изменен');
    } catch (error) {
      setLoading(false);
      const err = error?.response?.data?.message || error;
      notify(`Ошибка: ${err}`);
    }
  }, [dataProvider, notify, rentId, getRentInfo, manualRentStatus]);

  const cancelRent = useCallback(async () => {
    setLoading(true);
    try {
      await dataProvider.cancelExpiredRent(resource, { id: rentId });
      await getRentInfo();
      setLoading(false);
      notify('Аренда успешно отменена');
    } catch (error) {
      setLoading(false);
      const err = error?.response?.data?.message || error;
      notify(`Ошибка: ${err}`);
    }
  }, [dataProvider, notify, resource, rentId, getRentInfo]);

  const finishPreRentalInspection = useCallback(
    role => async () => {
      setLoading(true);
      try {
        await dataProvider.finishPreRentalInspection({
          id: preRentalInspection.id,
          role,
        });
        await getRentInfo();
        setLoading(false);
        notify('Предарендная инспекция обновлена');
      } catch (error) {
        setLoading(false);
        const err = error?.response?.data?.message || error;
        notify(`Ошибка: ${err}`);
      }
    },
    [dataProvider, notify, preRentalInspection, getRentInfo],
  );

  const finishPostRentalInspection = useCallback(
    role => async () => {
      setLoading(true);
      try {
        await dataProvider.finishPostRentalInspection({
          id: postRentalInspection.id,
          role,
        });
        await getRentInfo();
        setLoading(false);
        notify('Постарендная инспекция обновлена');
      } catch (error) {
        setLoading(false);
        const err = error?.response?.data?.message || error;
        notify(`Ошибка: ${err}`);
      }
    },
    [dataProvider, notify, postRentalInspection, getRentInfo],
  );

  useEffect(() => {
    getRentInfo();
  }, [getRentInfo]);

  return (
    <div className="container -rent-actions">
      <Loader open={loading} />
      <Box className="card">
        <h3 className="title">Аренда</h3>
        <div className="text-field">
          <span className="label">ID:</span>
          <span className="text">{rentId}</span>
        </div>
        <div className="text-field">
          <span className="label">Статус:</span>
          <span className="text">{status}</span>
        </div>
        <div className="text-field">
          <ChangeDatesForm
            resource={resource}
            rentId={rentId}
            startDate={startDate}
            endDate={endDate}
            disabled={cancelBtnDisableStatuses.includes(status)}
          />
        </div>
        <div className="text-field">
          <Button
            className="btn"
            onClick={startRentManually}
            disabled={status !== 'APPROVED_BY_HOST'}
          >
            Начать аренду
          </Button>
        </div>
        <div className="text-field">
          <Button className="btn" onClick={finishRentManually} disabled={status !== 'IN_PROGRESS'}>
            Завершить аренду
          </Button>
        </div>
        <div className="text-field">
          <Button
            className="btn"
            onClick={cancelRent}
            disabled={cancelBtnDisableStatuses.includes(status)}
          >
            Отменить аренду
          </Button>
        </div>
        <div className="text-field -footnote">
          <span className="text">
            * Начать аренду можно только из статуса <b>APPROVED_BY_HOST</b>
          </span>
        </div>
        <div className="text-field">
          <span className="text">
            * Завершить аренду можно только из статуса <b>IN_PROGRESS</b>
          </span>
        </div>
        <div className="text-field">
          <span className="text -danger">
            <b>* Изменяйте статус вручную только в крайнем случае!</b>
          </span>
        </div>
        <div className="danger-wrap">
          <FormControl className="select-wrap" size="small">
            <Select
              labelId="select-small"
              id="select-small"
              className="select"
              value={manualRentStatus}
              label="Статус"
              onChange={onChangeManualStatus}
            >
              {rentStatuses.map(({ status, label }) => (
                <MenuItem value={status}>{label}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button className="btn" onClick={changeStatus}>
            Изменить статус
          </Button>
        </div>
      </Box>
      <Box className="card -inspection">
        <h3 className="title">Предарендный осмотр</h3>
        {preRentalInspection && (
          <>
            <div className="text-field">
              <span className="label">ID:</span>
              <span className="text">{preRentalInspection.id}</span>
            </div>
            <div className="text-field">
              <span className="label">Завершен?</span>
              <span className="text">{renderBoolean(preRentalInspection.finished)}</span>
            </div>
            <div className="text-field">
              <span className="label">Хост завершил?</span>
              <span className="text">{renderBoolean(preRentalInspection.hostFinished)}</span>
              <Button
                className="btn"
                onClick={finishPreRentalInspection('host')}
                disabled={preRentalInspection.hostFinished}
              >
                Завершить
              </Button>
            </div>
            <div className="text-field">
              <span className="label">Гост завершил?</span>
              <span className="text">{renderBoolean(preRentalInspection.guestFinished)}</span>
              <Button
                className="btn"
                onClick={finishPreRentalInspection('guest')}
                disabled={preRentalInspection.guestFinished}
              >
                Завершить
              </Button>
            </div>
          </>
        )}
      </Box>
      <Box className="card -inspection">
        <h3 className="title">Постарендный осмотр</h3>
        {postRentalInspection && (
          <>
            <div className="text-field">
              <span className="label">ID:</span>
              <span className="text">{postRentalInspection.id}</span>
            </div>
            <div className="text-field">
              <span className="label">Завершен?</span>
              <span className="text">{renderBoolean(postRentalInspection.finished)}</span>
            </div>
            <div className="text-field">
              <span className="label">Хост завершил?</span>
              <span className="text">{renderBoolean(postRentalInspection.hostFinished)}</span>
              <Button
                className="btn"
                onClick={finishPostRentalInspection('host')}
                disabled={postRentalInspection.hostFinished}
              >
                Завершить
              </Button>
            </div>
            <div className="text-field">
              <span className="label">Гост завершил?</span>
              <span className="text">{renderBoolean(postRentalInspection.guestFinished)}</span>
              <Button
                className="btn"
                onClick={finishPostRentalInspection('guest')}
                disabled={postRentalInspection.guestFinished}
              >
                Завершить
              </Button>
            </div>
          </>
        )}
      </Box>
    </div>
  );
};
