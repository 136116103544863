import React, { useCallback } from 'react';
import { SaveButton, useDataProvider, useNotify } from 'react-admin';

export const RejectButton = props => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { resource } = props;

  const handleSave = useCallback(
    async values => {
      try {
        await dataProvider.rejectCar(resource, { id: values.id });
        await dataProvider.getOne(resource, { id: values.id });

        notify('Car rejected successfuly');
      } catch (error) {
        notify(`Car reject was failed with error: ${error}`);
      }
    },
    [dataProvider, notify, resource],
  );

  // TODO: remove pristine prop from SaveButton because of hardcode - create custom button instead SaveButton
  return (
    <SaveButton {...props} onSave={handleSave} label="Отклонить автомобиль" pristine={false} />
  );
};
