import React from 'react';
import { Edit, TabbedForm, FormTab, useAuthenticated, Tab } from 'react-admin';
import {
  StatusBar,
  Moderation,
  OtherInformation,
  CarEditTitle,
  Refinement,
  OwnerPassport,
  RegistrationCertificate,
  VehiclePassport,
  InsuranceTab,
} from '../components';
import { CarToolbar } from '../components/Toolbar';
import { TabPhotos } from '../components/Photos';
import { RentTableList } from '../../../common/RentTableList';
import { ObjectCommentSection } from '../../../common/ObjectCommentSection/ObjectComment.Section';
import { CarHistory } from '../CarHistory';

export const CarEdit = props => {
  useAuthenticated();
  return (
    <Edit {...props} title={<CarEditTitle />}>
      <TabbedForm toolbar={<CarToolbar alwaysEnableSaveButton />}>
        <FormTab label="Инфо">
          <StatusBar />
          <Moderation />
          <Refinement />
        </FormTab>
        <FormTab label="Доп. инфо">
          <OtherInformation />
        </FormTab>
        <FormTab label="Аренды">
          <RentTableList />
        </FormTab>
        <FormTab label="Фото">
          <TabPhotos />
        </FormTab>
        <FormTab label="Страховка">
          <InsuranceTab />
        </FormTab>
        <FormTab label="Владелец">
          <OwnerPassport />
        </FormTab>
        <FormTab label="История">
          <CarHistory />
        </FormTab>
        <FormTab label="СТС">
          <RegistrationCertificate />
        </FormTab>
        <FormTab label="ПТС">
          <VehiclePassport />
        </FormTab>
        <FormTab label="Комментарии">
          <ObjectCommentSection title="Комментарии к автомобилю" />
        </FormTab>
      </TabbedForm>
    </Edit>
  );
};
