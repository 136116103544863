import React, { useCallback, useState, useEffect } from 'react';
import { Form, Field } from 'react-final-form';
import { useDataProvider, useNotify } from 'react-admin';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';

import { Loader } from '../../../../common/Loader';
import './styles.scss';

export const InsuranceTab = ({ record, resource }) => {
  const [loading, setLoading] = useState(false);
  const [insurance, setInsurance] = useState({});
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { id: carId = '' } = record;
  const { insured = false, unavailable = false, cascoFee, ingosFee } = insurance;

  const getInsurance = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await dataProvider.getInsurance(resource, {
        carId,
      });
      setInsurance(data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      notify(`Error: ${error}`);
    }
  }, [dataProvider, notify, resource, carId]);

  const onChange = useCallback(
    async payload => {
      setLoading(true);
      try {
        const { data } = await dataProvider.updateInsurance(resource, {
          carId,
          data: payload,
        });
        setInsurance(data);

        setLoading(false);
        notify('Страховые данные успешно обновлены');
      } catch (error) {
        setLoading(false);
        const err = error?.response?.data?.message || error;
        notify(`Ошибка: ${err}`);
      }
    },
    [dataProvider, notify, resource, carId],
  );

  const onChangeAvailability = (e, value) => onChange({ unavailable: value });

  const onChangeInsurability = (e, value) => onChange({ insured: value });

  useEffect(() => {
    getInsurance();
  }, []);

  return (
    <div className="container -report">
      <Loader open={loading} />
      <Box className="card">
        <h3 className="title">Страховка</h3>
        <div className="text-field">
          <span className="label">Размер страхового взноса:</span>
          <span className="text">{cascoFee}</span>
        </div>
        <div className="text-field">
          <span className="label">Размер страхового взноса ИНГОССТРАХ:</span>
          <span className="text">{ingosFee}</span>
        </div>
        <div className="form-wrap">
          <div className="text-field">
            <span className="label">Страховка активирована?</span>
            <span className="text">
              <Checkbox
                className="checkbox"
                edge="start"
                checked={insured}
                disableRipple
                onChange={onChangeInsurability}
              />
            </span>
          </div>
        </div>
        <div className="form-wrap">
          <div className="text-field">
            <span className="label">Страховка недоступна?</span>
            <span className="text">
              <Checkbox
                className="checkbox"
                edge="start"
                checked={unavailable}
                disableRipple
                onChange={onChangeAvailability}
              />
            </span>
          </div>
        </div>
      </Box>
    </div>
  );
};
