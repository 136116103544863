import React, { useState, useCallback } from 'react';
import { useDataProvider, useNotify, useListContext } from 'react-admin';
import Box from '@material-ui/core/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

import { Loader } from '../../common/Loader';

export const ActiveCheckbox = ({ record, resource }) => {
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { currentSort, page, perPage, filter } = useListContext();
  const { id, active: isPromocodeActive = false } = record;
  const onChange = useCallback(
    async event => {
      setLoading(true);
      try {
        await dataProvider.toggleActive(resource, { id, active: event.target.checked });
        await dataProvider.getList(resource, {
          pagination: { page, perPage },
          sort: {},
          filter,
        });

        setLoading(false);
        notify('Промокод успешно изменен');
      } catch (error) {
        setLoading(false);
        notify(`Error: ${error}`);
      }
    },
    [dataProvider, notify, resource, id, currentSort, page, perPage, filter],
  );
  return (
    <Box>
      <Loader open={loading} />
      <FormControlLabel
        control={<Checkbox name="checkbox" onChange={onChange} checked={isPromocodeActive} />}
      />
    </Box>
  );
};
