import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import '../styles.scss';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CheckActions = ({ children, isSaveDisabled, isInfoShown }) => {
  const [open, setDialogOption] = useState(false);
  const handleClose = () => {
    setDialogOption(false);
  };

  return (
    <div className="check-actions">
      <Dialog disablePortal open={open} TransitionComponent={Transition} className="dialog">
        <Box className="wrap">{children}</Box>
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          className="icon"
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
      <Box className="actions">
        <Button type="submit" className="btn" disabled={isSaveDisabled}>
          Сохранить
        </Button>
        {isInfoShown && (
          <Button className="text-btn" onClick={() => setDialogOption(true)}>
            Доп. информация
          </Button>
        )}
      </Box>
    </div>
  );
};
