import React, { useState, useCallback } from 'react';
import { Button, Box, Checkbox, FormControlLabel } from '@material-ui/core';
import { Form } from 'react-final-form';
import { TextInput, changeListParams } from 'react-admin';
import { useDispatch } from 'react-redux';
import { omit } from 'ramda';

export const FilterForm = ({ filterValues, resource }) => {
  const [verification, setVerification] = useState(false);
  const dispatch = useDispatch();
  const setFilters = useCallback(
    (filter = {}) => dispatch(changeListParams(resource, { filter })),
    [],
  );
  const onSubmit = values => {
    const filterValue = verification
      ? { ...values, status: 'VERIFICATION' }
      : omit(['status'], values);
    if (Object.keys(filterValue).length > 0) {
      setFilters(filterValue);
    } else {
      setFilters({}, []);
    }
  };

  const resetFilter = () => {
    setFilters({}, []);
    setVerification(false);
  };

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Box display="flex" alignItems="center" mb={1}>
              <Box component="span" mr={2}>
                <TextInput label="Рег. номер" source="registrationNumber" alwaysOn />
              </Box>
              <Box component="span" mr={2} mb={1.5}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={verification}
                      onChange={({ target: { checked } }) => setVerification(checked)}
                      name="verification"
                    />
                  }
                  label="Показать только с обязательным подтверждением"
                />
              </Box>
              <Box component="span" mr={2} mb={1.5}>
                <Button variant="outlined" color="primary" type="submit">
                  Поиск
                </Button>
              </Box>
              <Box component="span" mb={1.5}>
                <Button variant="outlined" onClick={resetFilter}>
                  Очистить поиск
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Form>
    </div>
  );
};

export const CarFilter = props => {
  return props.context !== 'button' ? <FilterForm {...props} /> : null;
};
