import React from 'react';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Link } from 'react-router-dom';
import { Loader } from '../Loader';

export const ObjectCommentList = ({ comments, loading }) => {
  return (
    <div>
      <Loader open={loading} />
      {comments.length
        ? comments.map(item => (
            <Card key={item.id} style={{ margin: '14px 0', width: '100%' }}>
              <CardContent>
                <Typography color="text.secondary" gutterBottom>
                  <strong style={{ fontSize: 18 }}>
                    <Link
                      target="_blank"
                      to={{
                        pathname: `/user/${item.author.id}`,
                      }}
                    >
                      {item.author.fullName}
                    </Link>
                    <span> | </span>
                    <span>{new Date(item.createdAt).toLocaleString()}</span>
                  </strong>
                </Typography>

                <Typography color="text.secondary">
                  <span style={{ wordBreak: 'break-all' }}>{item.content}</span>
                </Typography>
              </CardContent>
            </Card>
          ))
        : (loading && '') || <h4>Комментариев пока нет...</h4>}
    </div>
  );
};
