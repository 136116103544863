import React, { useEffect, useState } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { PhotoPreview } from '../../../../common/PhotoPreview';
import './styles.scss';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const OwnerPassport = ({ record, ...rest }) => {
  const { id } = record;
  const { resource } = rest;
  const [ownerPass, updateLocalOwnerPass] = useState([]);
  const [dialogOption, setDialogOption] = useState({ open: false, url: '' });
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const handleClose = () => {
    setDialogOption({ open: false, url: '' });
  };

  useEffect(() => {
    const fetchRegCert = async () => {
      try {
        const { data } = await dataProvider.fetchOwnerPassport(resource, { id });
        updateLocalOwnerPass(data.ownerPassport);
        return data;
      } catch (error) {
        notify(`During owner passport fetching following error was occured: ${error}`);
        return error;
      }
    };
    fetchRegCert();
  }, [dataProvider, notify, resource, id]);

  if (!ownerPass) {
    return null;
  }
  const {
    id: ownerPassId = '',
    type = '',
    firstName = '',
    lastName = '',
    midName = '',
    serialNumber = '',
    registrationAddress = '',
    companyName = '',
    inn = '',
    companyAddress = '',
    frontSidePhotoUrl = '',
    registrationSidePhotoUrl = '',
  } = ownerPass;

  return (
    <div className="documents-tab">
      <Dialog
        disablePortal
        fullScreen
        open={dialogOption.open}
        TransitionComponent={Transition}
        className="photo-dialog"
      >
        <CardMedia image={dialogOption.url} className="image" />
        <IconButton
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          className="icon"
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
      <div className="wrap">
        <h3 className="title -section">Данные владельца</h3>
        <div className="text-field">
          <span className="label">ID Документа:</span>
          <span className="text">{ownerPassId}</span>
        </div>
        <div className="text-field">
          <span className="label">Тип:</span>
          <span className="text">
            {type === 'INDIVIDUAL' ? ' Физическое лицо' : 'Юридическое лицо'}
          </span>
        </div>
        {type === 'INDIVIDUAL' && (
          <>
            <div className="text-field">
              <span className="label">Фамилия:</span>
              <span className="text">{lastName}</span>
            </div>
            <div className="text-field">
              <span className="label">Имя:</span>
              <span className="text">{firstName}</span>
            </div>
            <div className="text-field">
              <span className="label">Отчество:</span>
              <span className="text">{midName}</span>
            </div>
            <div className="text-field">
              <span className="label">Номер паспорта:</span>
              <span className="text">{serialNumber}</span>
            </div>
            <div className="text-field">
              <span className="label">Адрес регистрации:</span>
              <span className="text">{registrationAddress}</span>
            </div>
          </>
        )}
        {type === 'LEGAL' && (
          <>
            <div className="text-field">
              <span className="label">Название компании:</span>
              <span className="text">{companyName}</span>
            </div>
            <div className="text-field">
              <span className="label">ИНН:</span>
              <span className="text">{inn}</span>
            </div>
            <div className="text-field">
              <span className="label">Адрес компании:</span>
              <span className="text">{companyAddress}</span>
            </div>
          </>
        )}
      </div>
      {type === 'INDIVIDUAL' && (
        <div className="photos-wrap">
          {frontSidePhotoUrl && <PhotoPreview photo={frontSidePhotoUrl} />}
          {registrationSidePhotoUrl && <PhotoPreview photo={registrationSidePhotoUrl} />}
        </div>
      )}
    </div>
  );
};
