import React, { useState } from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import './styles.scss';

const getPhotoKey = (url = '') => {
  const regexp = /-(\d|\w+)\./;
  const match = url.match(regexp)[0];
  return match.slice(1, match.length - 1);
};
const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const PhotoPreview = ({ photo }) => {
  const [dialogOption, setDialogOption] = useState({ open: false, url: '' });
  const handleClose = () => {
    setDialogOption({ open: false, url: '' });
  };

  if (!photo) {
    return null;
  }
  const key = getPhotoKey(photo);

  return (
    <div className="info-wrapper">
      <Dialog
        disablePortal
        fullScreen
        open={dialogOption.open}
        TransitionComponent={Transition}
        className="photo-dialog"
      >
        <CardMedia component="img" src={dialogOption.url} className="image" />
        <IconButton
          style={{
            marginTop: 70,
            marginRight: 30,
          }}
          edge="start"
          color="inherit"
          onClick={handleClose}
          aria-label="close"
          className="icon"
        >
          <CloseIcon />
        </IconButton>
      </Dialog>
      <div className="wrap">
        <div className="additional-photos">
          <CardMedia
            image={photo}
            key={key}
            className="image"
            onClick={() => setDialogOption({ open: true, url: photo })}
          />
        </div>
      </div>
    </div>
  );
};
