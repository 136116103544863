import React, { useState, useCallback } from 'react';
import { Create, useDataProvider, useNotify, useRedirect } from 'react-admin';
import { Form, Field } from 'react-final-form';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';

import { Loader } from '../../common/Loader';
import './styles.scss';

export const PromocodeCreate = props => {
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const redirectTo = useRedirect();
  const { resource, basePath } = props;

  const onSubmit = useCallback(
    async ({ name, discount }) => {
      setLoading(true);
      try {
        await dataProvider.createPromocode(resource, { name, discount });

        setLoading(false);
        notify('Даты аренды успешно изменены');
        redirectTo('list', basePath);
      } catch (error) {
        setLoading(false);
        if (error.response?.status === 409) {
          notify(`Ошибка: Такой промокод уже существует`);
          return;
        }
        const err = error?.response?.data?.message || error;
        notify(`Ошибка: ${err}`);
      }
    },
    [dataProvider, notify, resource, basePath, redirectTo],
  );
  return (
    <Create {...props} title="Создать промокод" className="cards-wrapper -promocode">
      <Box className="card">
        <Form
          onSubmit={onSubmit}
          initialValues={{
            name: '',
            discount: '',
          }}
        >
          {props => {
            const {
              form: { getState },
            } = props;
            const { invalid } = getState();
            return (
              <form onSubmit={props.handleSubmit} className="card">
                <Loader open={loading} />
                <Field name="name" validate={value => (value ? undefined : 'Обязательное поле')}>
                  {props => (
                    <div className="text-field">
                      <span className="label">Название:</span>
                      <TextField
                        id="datetime-local"
                        type="text"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        className="field"
                        variant="outlined"
                        inputProps={{ className: 'input' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  )}
                </Field>
                <Field
                  name="discount"
                  validate={value => (value ? undefined : 'Обязательное поле')}
                >
                  {props => (
                    <div className="text-field">
                      <span className="label">Скидка:</span>
                      <TextField
                        id="datetime-local"
                        type="number"
                        name={props.input.name}
                        value={props.input.value}
                        onChange={props.input.onChange}
                        className="field"
                        variant="outlined"
                        inputProps={{ className: 'input' }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </div>
                  )}
                </Field>
                <Button type="submit" className="btn" disabled={invalid}>
                  Сохранить
                </Button>
              </form>
            );
          }}
        </Form>
      </Box>
    </Create>
  );
};
