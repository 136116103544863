import React from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  Pagination,
  TextField,
  useAuthenticated,
} from 'react-admin';
import { CarFilter } from './components/Filter';
import { Actions } from './components/Actions';
import { excelExporter } from '../../services/excel-exporter';

export const CarList = props => {
  useAuthenticated();
  return (
    <List
      {...props}
      actions={<Actions />}
      exporter={excelExporter}
      filters={<CarFilter />}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    >
      <Datagrid rowClick="edit" isRowSelectable={() => false}>
        <FunctionField
          label="Наименование"
          render={record => `${record.brand} ${record.model} ${record.productionYear}`}
        />
        <TextField label="Статус" source="status" />
        <TextField label="Статус скоринга" source="scoringStatus" />
        <TextField label="Статус модерации" source="moderationStatus" />
        <TextField label="Создан" source="createdAt" />
        <TextField label="Последнее обновление" source="updatedAt" />
        <TextField label="География аренды" source="rentLocations" />
        <TextField label="Адрес" source="address" />
      </Datagrid>
    </List>
  );
};
