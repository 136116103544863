import React from 'react';
import { Show, TabbedShowLayout, Tab, useAuthenticated } from 'react-admin';
import { RentData } from '../RentData';
import { RentalInspection } from '../RentInspection';
import { RentDecline } from '../RentDecline';
import { Bills } from '../Bills';
import { RentExpiredToolbar } from './Toolbar';
import '../styles.scss';

export const RentDeclinedShow = props => {
  useAuthenticated();
  return (
    <Show {...props} actions={<RentExpiredToolbar />}>
      <TabbedShowLayout>
        <Tab label="Аренда">
          <RentData />
        </Tab>
        <Tab label="Предарендный осмотр">
          <RentalInspection stage="preRentalInspection" />
        </Tab>
        <Tab label="Постарендный осмотр">
          <RentalInspection stage="postRentalInspection" />
        </Tab>
        <Tab label="Отмена аренды">
          <RentDecline />
        </Tab>
        <Tab label="Платежи">
          <Bills />
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};
