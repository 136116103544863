import { SERVICE_URL } from '../../../core/constants';
import { makeCall } from '../../../services/api-service';

export const rentExpiredDataProvider = {
  cancelExpiredRent: async (_resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/rent/${params.id}/decline`, {
      method: 'PUT',
      data: {
        comment: 'Decline by admin because of payment expiration',
        photoKeys: [],
      },
    });
    return { data };
  },
};
