import axios from 'axios';
import { REFRESH_TOKENS_URL } from '../core/constants';
import { updateUserInfo, deleteUserFromStorage } from './auth-service';

const defaultHeaders = {};

export const apiFetch = (url, { method = 'GET', data = {}, headers = {}, params = {} }) =>
  axios.request({
    url,
    method,
    data,
    params,
    headers: { ...defaultHeaders, ...headers },
    withCredentials: true,
    crossDomain: true,
  });

export const makeRefreshCall = async () => {
  const { data } = await apiFetch(REFRESH_TOKENS_URL, { method: 'POST' });
  return data;
};

export const makeCall = async (url, options = {}) => {
  let response;
  try {
    response = await apiFetch(url, options);
    return response;
  } catch (error) {
    if (error.response && error.response.status === 401) {
      await makeRefreshCall();
      response = await apiFetch(url, options);
      return response;
    }
    throw error;
  }
};

export const makeAuthCall = async (url, options) => {
  const {
    data: { user },
  } = await apiFetch(url, options);
  updateUserInfo(user);
  return user;
};
