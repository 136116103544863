import React, { useState, useCallback, useEffect } from 'react';
import { useDataProvider, useNotify } from 'react-admin';
import Box from '@material-ui/core/Box';

import { Loader } from '../../../../common/Loader';
import { TextField } from './components/TextField';
import { ReportActions } from './components/ReportActions';
import {
  PassportCheck,
  DriversLicenseCheck,
  OpenFsspDebtsCheck,
  ClosedFsspDebtsCheck,
  ClosedByArticleFsspDebtsCheck,
  BanksBlackListCheck,
  RentalBlackListCheck,
  ProceedingsCheck,
} from './checks';
import './styles.scss';

const checksMap = {
  PASSPORT: PassportCheck,
  DRIVERS_LICENSE: DriversLicenseCheck,
  FSSP_OPEN_DEBTS: OpenFsspDebtsCheck,
  FSSP_CLOSED_DEBTS: ClosedFsspDebtsCheck,
  FSSP_CLOSED_BY_ARTICLE_DEBTS: ClosedByArticleFsspDebtsCheck,
  BANKS_BLACK_LIST: BanksBlackListCheck,
  RENTAL_BLACK_LIST: RentalBlackListCheck,
  PROCEEDINGS: ProceedingsCheck,
};

export const GuestScoringReport = ({ record, resource }) => {
  const [loading, setLoading] = useState(false);
  const [scoringChecks, setScoringChecks] = useState([]);
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const { id: userId = '', report = {}, kbm } = record;
  const { createdAt = '', updatedAt = '', checks = [] } = report || {};

  const onCheckUpdate = useCallback(
    initialCheck => async (fields, { reset }) => {
      const { id: checkId, data: initialData, comment: initialComment } = initialCheck;
      const { comment } = fields;

      setLoading(true);
      try {
        const { data: updatedCheck } = await dataProvider.updateCheck({
          checkId,
          data: {
            data: {
              ...initialData,
              ...fields,
            },
            comment: comment || initialComment,
            isError: false,
          },
        });
        console.log(updatedCheck);
        const updatedScoringChecks = scoringChecks.reduce((acc, check) => {
          if (check.type === updatedCheck.type) {
            return [...acc, updatedCheck];
          }

          return [...acc, check];
        }, []);
        setScoringChecks(updatedScoringChecks);
        reset();

        setLoading(false);
        notify('Отчет проверки водителя успешно обновлен');
      } catch (error) {
        setLoading(false);
        const err = error?.response?.data?.message || error;
        notify(`Ошибка: ${err}`);
      }
    },
    [dataProvider, notify, setLoading, scoringChecks],
  );

  const onScoringRefresh = useCallback(async () => {
    setLoading(true);
    try {
      await dataProvider.refreshUserScoring({
        userId,
      });

      setLoading(false);
      notify('Скоринг перезапущен, ожидайте генерацию отчета');
    } catch (error) {
      setLoading(false);
      const err = error?.response?.data?.message || error;
      notify(`Ошибка: ${err}`);
    }
  }, [dataProvider, notify, setLoading, userId]);

  const onScoringRegenerate = useCallback(async () => {
    setLoading(true);
    try {
      await dataProvider.regenerateUserScoring({
        userId,
      });

      setLoading(false);
      notify('Генерация скоринга запущена, ожидайте отчет');
    } catch (error) {
      setLoading(false);
      const err = error?.response?.data?.message || error;
      notify(`Ошибка: ${err}`);
    }
  }, [dataProvider, notify, setLoading, userId]);

  useEffect(() => {
    if (checks.length) {
      setScoringChecks(checks);
    }
  }, [checks]);

  return (
    <div className="container -report">
      <Loader open={loading} />
      <Box className="card">
        <h3 className="title">Отчет проверки водителя</h3>
        <TextField label="Создан:" value={createdAt} />
        <TextField label="Изменен:" value={updatedAt} />
        <ReportActions
          report={report}
          onRefresh={onScoringRefresh}
          onRegenerate={onScoringRegenerate}
        />
        <div className="checks-wrap">
          {Object.keys(checksMap).map(key => {
            const check = scoringChecks.find(({ type }) => type === key);

            if (!check) {
              return null;
            }

            const CheckComponent = checksMap[key];

            if (key === 'DRIVERS_LICENSE') {
              return (
                <CheckComponent
                  check={check}
                  setLoading={setLoading}
                  onSubmit={onCheckUpdate}
                  kbm={kbm}
                />
              );
            }

            return (
              <CheckComponent check={check} onSubmit={onCheckUpdate} setLoading={setLoading} />
            );
          })}
        </div>
      </Box>
    </div>
  );
};
