import React from 'react';
import { Link } from 'react-router-dom';
import { getBirthday } from '../../../utils/getBirthday';
import { num2word } from '../../../utils/num2word';

export const UserInfo = ({ user = {}, role = '' }) => {
  const { id = '', fullName = '', email = '', phone = '' } = user;

  const getDrivingExperienceYears = () => {
    let str = 'отсутствует';

    if (!user.drivingExperienceYears) {
      return str;
    }

    const { age, ageDeclination, birthDate } = getBirthday(`01.01.${user.drivingExperienceYears}`);

    if (age > 0) {
      str = `${age} ${ageDeclination}`;
    }

    return str;
  };

  return (
    <div className="info-wrapper">
      <div className="wrap">
        <h3 className="title -section">{`Данные ${role === 'guest' ? 'госта' : 'хоста'}`}</h3>
        <div className="text-field">
          <span className="label">ID:</span>
          <Link
            to={{
              pathname: `/user/${id}`,
            }}
            className="link"
          >
            <span className="text">{id}</span>
          </Link>
        </div>
        <div className="text-field">
          <span className="label">Имя:</span>
          <span className="text">{fullName}</span>
        </div>
        <div className="text-field">
          <span className="label">Email:</span>
          <span className="text">{email}</span>
        </div>
        <div className="text-field">
          <span className="label">Телефон:</span>
          <span className="text">{phone}</span>
        </div>
        {user.birthDate && (
          <div className="text-field">
            <span className="label">Дата рождения:</span>
            <span className="text">
              {user.birthDate} ({getBirthday(user.birthDate).toString()})
            </span>
          </div>
        )}
        {user.drivingExperienceYears && (
          <div className="text-field">
            <span className="label">Стаж водителя:</span>
            <span className="text">{getDrivingExperienceYears()}</span>
          </div>
        )}
        {user.kbm && (
          <div className="text-field">
            <span className="label">КБМ:</span>
            <span className="text">{user.kbm}</span>
          </div>
        )}
      </div>
    </div>
  );
};
