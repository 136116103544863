import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
  makeStyles,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { Link } from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

export const CarsList = ({ record: { cars } }) => {
  const classes = useStyles();

  return (
    <div>
      {cars?.length ? (
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="rents table">
            <TableHead>
              <TableRow>
                <StyledTableCell>№</StyledTableCell>
                <StyledTableCell>Идентификатор автомобиля</StyledTableCell>
                <StyledTableCell>Статус авто</StyledTableCell>
                <StyledTableCell>Статус модерации</StyledTableCell>
                <StyledTableCell>Статус скоринга</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {cars.map((row, rowIndex) => (
                <StyledTableRow key={row.id}>
                  <StyledTableCell>{rowIndex + 1}</StyledTableCell>
                  <StyledTableCell component="th" scope="row">
                    <Link
                      target="_blank"
                      to={{
                        pathname: `/car/${row.uuid}`,
                      }}
                    >
                      <span>
                        <b>
                          {row.brand} {row.model}
                        </b>{' '}
                        <sup>{row.registrationNumber}</sup>
                      </span>
                    </Link>
                  </StyledTableCell>
                  <StyledTableCell>{row.status}</StyledTableCell>
                  <StyledTableCell>{row.moderationStatus}</StyledTableCell>
                  <StyledTableCell>{row.scoringStatus}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Alert variant="filled" severity="info">
          <AlertTitle>Пусто</AlertTitle>У этого пользователя пока нет ни одного авто!
        </Alert>
      )}
    </div>
  );
};

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);
