import React, { useCallback } from 'react';
import { SaveButton, useDataProvider, useNotify } from 'react-admin';

export const ScoringApproveButton = props => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { resource, record } = props;

  const { role, scoringStatus, report } = record;

  const handleSave = useCallback(
    async values => {
      try {
        await dataProvider.scoringApprove(resource, { id: values.id });
        await dataProvider.getOne(resource, { id: values.id });

        notify('User scoring approved successfuly');
      } catch (error) {
        notify(`User scoring approve was failed with error: ${error}`);
      }
    },
    [resource, dataProvider, notify],
  );

  // TODO: remove pristine prop because of hardcode - create custom button instead SaveButton
  return (
    <SaveButton
      {...props}
      onSave={handleSave}
      label="Подтвердить скоринг"
      pristine={false}
      disabled={role === 'GUEST' && scoringStatus !== 'SUCCESS' && !report}
    />
  );
};
