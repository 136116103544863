import { SERVICE_URL } from '../../core/constants';
import { modifyUserResponse } from './utils/modifyResponse';
import { makeCall } from '../../services/api-service';

export const userDataProvider = {
  updateUser: async (resource, params) => {
    const requestData = modifyUserResponse(params.data);
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}`, {
      method: 'PATCH',
      data: requestData,
    });
    return { data };
  },
  scoringApprove: async (resource, params) => {
    const response = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/approve-scoring`, {
      method: 'PATCH',
      data: {},
    });
    return { data: response };
  },
  scoringReject: async (resource, params) => {
    const response = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/reject-scoring`, {
      method: 'PATCH',
      data: {},
    });
    return { data: response };
  },
  roleUpdate: async (resource, params) => {
    const { role } = params;
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/set-role`, {
      method: 'PATCH',
      data: { role },
    });
    return { data };
  },
  banUser: async (resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/ban`, {
      method: 'PATCH',
      data: {},
    });
    return { data };
  },
  unbanUser: async (resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/unban`, {
      method: 'PATCH',
      data: {},
    });
    return { data };
  },
  deleteUser: async (resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}`, {
      method: 'DELETE',
      data: {},
    });
    return { data };
  },
  attachCard: async (resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/attach-card`, {
      method: 'PATCH',
      data: {},
    });
    return { data };
  },
  setUserLabel: async (resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/set-label`, {
      method: 'POST',
      data: { label: params.label },
    });
    return { data };
  },
  removeUserLabel: async (resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/remove-label`, {
      method: 'POST',
      data: { label: params.label },
    });
    return { data };
  },
  setTemporaryPassword: async params => {
    const { data } = await makeCall(`${SERVICE_URL}/auth/${params.id}/set-temporary-password`, {
      method: 'POST',
      data: { password: params.password },
    });
    return { data };
  },
  resetTemporaryPassword: async params => {
    const { data } = await makeCall(`${SERVICE_URL}/auth/${params.id}/reset-temporary-password`, {
      method: 'POST',
      data: {},
    });
    return { data };
  },
  detachPaymentCard: async params => {
    const { data } = await makeCall(`${SERVICE_URL}/payments/${params.id}/card`, {
      method: 'DELETE',
      data: {},
    });
    return { data };
  },
  createOrUpdateHostPreferences: async params => {
    const { data } = await makeCall(`${SERVICE_URL}/user/${params.id}/host-preferences`, {
      method: 'PATCH',
      data: params.data,
    });
    return { data };
  },
  getHostPreferences: async params => {
    const { data } = await makeCall(`${SERVICE_URL}/user/${params.id}/host-preferences`, {
      method: 'GET',
    });
    return { data };
  },
  getScoringReport: async params => {
    const { data } = await makeCall(`${SERVICE_URL}/user/${params.userId}/report`, {
      method: 'GET',
    });
    return { data };
  },
  updateCheck: async params => {
    const { data } = await makeCall(`${SERVICE_URL}/user/${params.checkId}/scoring-check`, {
      method: 'PATCH',
      data: params.data,
    });
    return { data };
  },
  refreshUserScoring: async params => {
    const { data } = await makeCall(`${SERVICE_URL}/user/${params.userId}/refresh-scoring`, {
      method: 'POST',
      data: {},
    });
    return { data };
  },
  regenerateUserScoring: async params => {
    const { data } = await makeCall(`${SERVICE_URL}/user/${params.userId}/regenerate-report`, {
      method: 'POST',
      data: {},
    });
    return { data };
  },
};
