import React, { useCallback, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { useDataProvider, useNotify } from 'react-admin';
import { Field } from 'react-final-form';
import CircularProgress from '@material-ui/core/CircularProgress';

const renderLabelList = [
  {
    name: 'MAXIMIZE_PROFIT',
    title: "Тариф 'Максимизировать прибыль'",
  },
];

export const CarLabels = ({ resource, record: car }) => {
  const [loadingLabelQueue, setLoadingLabelQueue] = useState([]);
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const onChange = useCallback(
    async (input, labelName, checked) => {
      let values = [...input.value];

      if (checked) {
        values.push(labelName);
      } else {
        values = values.filter(item => item !== labelName);
      }

      setLoadingLabelQueue([...loadingLabelQueue, labelName]);

      try {
        await dataProvider.toggleLabel(resource, {
          id: car.id,
          label: labelName,
          isSetLabel: checked,
        });
        await dataProvider.getOne(resource, { id: car.id });
        input.onChange(values);

        notify(`Ярлык '${labelName}' успешно ${checked ? 'добавлен' : 'удален'}`);
      } catch (error) {
        notify(
          `При ${
            checked ? 'добавлении' : 'удалении'
          } ярлыка '${labelName}' возникла ошибка; ${error}`,
        );
        console.error(error);
      } finally {
        setLoadingLabelQueue(loadingLabelQueue.filter(item => item !== labelName));
      }
    },
    [resource, car, loadingLabelQueue],
  );

  return (
    <div>
      <h3>
        Ярлыки <sup>Labels</sup>
      </h3>

      <Grid container>
        <Grid item>
          <Field name="labels">
            {({ input }) =>
              renderLabelList.map(label => (
                <>
                  <FormControlLabel
                    key={label.name}
                    control={
                      <Checkbox
                        checked={input.value.includes(label.name)}
                        onChange={(event, checked) => onChange(input, label.name, checked)}
                        name={label.name}
                      />
                    }
                    label={
                      <span>
                        {label.title}
                        {loadingLabelQueue.includes(label.name) ? (
                          <CircularProgress
                            style={{
                              width: 15,
                              height: 15,
                              marginLeft: 15,
                            }}
                          />
                        ) : null}
                      </span>
                    }
                    justify="flex-start"
                  />
                </>
              ))
            }
          </Field>
        </Grid>
      </Grid>
    </div>
  );
};
