import React from 'react';
import { PhotoPreview } from '../../../common/PhotoPreview';

export const RentAdditionalPhoto = ({ record: { additionalRentPhoto } }) => {
  return (
    <div className="rent-container">
      <h2 className="title" style={{ marginBottom: 35 }}>
        Дополнительные фотографии аренды
      </h2>

      <div className="info-wrapper">
        <div className="photos-container">
          <div className="wrap">
            <div className="inspection-photos" style={{ width: '100%', marginRight: 0 }}>
              {additionalRentPhoto.map(photo => (
                <PhotoPreview photo={photo} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
