import { SERVICE_URL } from '../../core/constants';
import { makeCall } from '../../services/api-service';

export const promocodeDataProvider = {
  toggleActive: async (resource, params) => {
    const { data } = await makeCall(`${SERVICE_URL}/${resource}/${params.id}/active`, {
      method: 'PATCH',
      data: { active: params.active },
    });
    return { data };
  },
  createPromocode: async (resource, data) => {
    const { responseData } = await makeCall(`${SERVICE_URL}/${resource}`, {
      method: 'POST',
      data,
    });
    return {
      data: responseData,
    };
  },
};
