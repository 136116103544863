import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { Field } from 'react-final-form';

import CardContent from '@material-ui/core/CardContent';
import Card from '@material-ui/core/Card';
import { reportStatuses } from './GuestScoringReport/reportStatuses';
import { renderer } from '../renderer';

const informationFields = [
  {
    name: 'firstName',
    label: 'Имя',
  },
  {
    name: 'lastName',
    label: 'Фамилия',
  },
  {
    name: 'midName',
    label: 'Отчество',
  },
  {
    name: 'email',
    label: 'Почта',
  },
  {
    name: 'phone',
    label: 'Номер телефона',
  },
  {
    name: 'birthDate',
    label: 'Дата рождения',
  },
  {
    name: 'trademark',
    label: 'Торговая марка',
  },
  {
    name: 'useTrademark',
    label: 'Использовать торговую марку?',
    type: 'checkbox',
  },
  {
    name: 'kbm',
    label: 'КБМ',
  },
];

export const UserInformation = ({ record, ...rest }) => {
  const isSuperHost = record.labels ? record.labels.includes('SUPERHOST') : false;
  const isSuperGuest = record.labels ? record.labels.includes('SUPERGUEST') : false;

  const isGuest = record.role === 'GUEST';
  const { card } = record;

  return (
    <Grid
      container
      spacing={1}
      wrap="wrap"
      direction="row"
      justify="space-between"
      alignItems="flex-start"
    >
      <Grid container alignItems="center" direction="column" xs={4}>
        <Card style={{ margin: '14px 0', width: '100%' }}>
          <CardContent>
            <Typography color="text.secondary" gutterBottom>
              <strong style={{ fontSize: 18 }}>Личные данные</strong>
            </Typography>

            <UserAvatar url={record.avatar} />
            <Typography color="text.secondary">
              <strong>Идентификатор: </strong>
              <span>{record.id}</span>
            </Typography>

            <Typography color="text.secondary">
              <strong>Создан: </strong>
              <span>{record.createdAt}</span>
            </Typography>

            <Typography color="text.secondary">
              <strong>Последнее обновление: </strong>
              <span>{record.updatedAt}</span>
            </Typography>

            <Typography color="text.secondary">
              <strong>{isGuest ? "Статус 'Супергост'" : "Статус 'Суперхост'"}: </strong>
              <span>{(isGuest ? isSuperGuest : isSuperHost) ? 'Да' : 'Нет'}</span>
            </Typography>

            <Typography color="text.secondary">
              <strong>Роль пользователя: </strong>
              <span>{record.role}</span>
            </Typography>

            <Typography color="text.secondary">
              <strong>Статус скоринга: </strong>
              <span>{record.scoringStatus}</span>
            </Typography>

            <Typography color="text.secondary">
              <strong>Заблокирован?: </strong>
              <span>{record.blocked ? 'Да' : 'Нет'}</span>
            </Typography>

            {record.blockedReason ? (
              <Typography color="text.secondary">
                <strong>Причина блокировки: </strong>
                <span>{record.blockedReason}</span>
              </Typography>
            ) : null}
          </CardContent>
        </Card>

        {card ? (
          <Card style={{ margin: '14px 0', width: '100%' }}>
            <CardContent>
              <Typography color="text.secondary" gutterBottom>
                <strong style={{ fontSize: 18 }}>Данные банковской карты</strong>
              </Typography>
              <Typography color="text.secondary">
                <strong>Идентификатор данных в базе: </strong>
                <span>{card.uuid}</span>
              </Typography>
              <Typography color="text.secondary">
                <strong>Номер карты: </strong>
                <span>{card.pan}</span>
              </Typography>
              <Typography color="text.secondary">
                <strong>Годен до: </strong>
                <span>{card.expireDate}</span>
              </Typography>
            </CardContent>
          </Card>
        ) : null}
      </Grid>
      <Grid container xs={8} justify="center" alignItems="center">
        <Typography variant="h6" gutterBottom display="block">
          Информация
        </Typography>
        <Grid container xs={12}>
          {informationFields.map(field => (
            <Grid container xs={6} justify="center">
              <Field name={field.name}>{({ input }) => renderer(field, input)}</Field>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const UserAvatar = ({ url }) => {
  const avatarStyles = {
    width: 100,
    height: 100,
    borderRadius: 50,
    overflow: 'hidden',
    margin: '8px 0',
  };

  if (!url) {
    return (
      <div
        style={{
          ...avatarStyles,
          background: 'gray',
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        Нет
        <br />
        аватара
      </div>
    );
  }

  return <img src={url} style={avatarStyles} alt="Нет аватара" />;
};
