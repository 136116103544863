import React from 'react';
import { Link } from 'react-router-dom';

const rentLocationsRuMatcher = {
  CITY: 'Только в городе',
  COUNTRY: 'По региону и области',
  WORLD: 'По всей стране',
};

export const CarInfo = ({
  car = {},
  carSnapshot = {},
  deliveryPopularPlaces = [],
  deliveryRadii = [],
}) => {
  const {
    id = '',
    brand,
    model,
    productionYear,
    greetingMessage = '',
    guestInstructions = '',
    rentRecomendations = '',
  } = car;

  return (
    <div className="info-wrapper">
      <div className="wrap">
        <h3 className="title -section">Данные машины</h3>
        <div className="text-field">
          <span className="label">ID:</span>
          <Link
            to={{
              pathname: `/car/${id}`,
            }}
            className="link"
          >
            <span className="text">{id}</span>
          </Link>
        </div>
        <div className="text-field">
          <span className="label">Марка:</span>
          <span className="text">{`${brand} ${model} ${productionYear}`}</span>
        </div>
      </div>
      <div className="wrap">
        <h3 className="title -section">Рекомендации хоста</h3>
        <div className="text-field">
          <span className="label">Приветственное сообщение:</span>
          <span className="text">{greetingMessage}</span>
        </div>
        <div className="text-field">
          <span className="label">Инструкции для госта:</span>
          <span className="text">{guestInstructions}</span>
        </div>
        <div className="text-field">
          <span className="label">Рекомендации:</span>
          <span className="text">{rentRecomendations}</span>
        </div>
      </div>
      {Object.keys(carSnapshot).length !== 0 && (
        <div className="wrap">
          <h3 className="title -section">Настройки авто на момент аренды</h3>
          <div className="text-field">
            <span className="label">ID слепка:</span>
            <span className="text">{carSnapshot.id}</span>
          </div>
          <div className="text-field">
            <span className="label">География использования:</span>
            <span className="text">{rentLocationsRuMatcher[carSnapshot.rentLocations]}</span>
          </div>
          <div className="text-field">
            <span className="label">Мин/макс кол-во дней аренды:</span>
            <span className="text">
              От {parseInt(carSnapshot.minRentDays, 10)} до{' '}
              {carSnapshot.maxRentDays === 'infinity'
                ? carSnapshot.maxRentDays
                : parseInt(carSnapshot.maxRentDays, 10)}
              {' дней'}
            </span>
          </div>
          <div className="text-field">
            <span className="label">Лимиты на пробег (д/н/м):</span>
            <span className="text">{`${
              carSnapshot?.milageLimits?.daily ? carSnapshot.milageLimits.daily : 'Не задано'
            }/${
              carSnapshot?.milageLimits?.weekly ? carSnapshot.milageLimits.weekly : 'Не задано'
            }/${
              carSnapshot?.milageLimits?.monthly ? carSnapshot.milageLimits.monthly : 'Не задано'
            }`}</span>
          </div>
          <div className="text-field">
            <span className="label">Цена за день:</span>
            <span className="text">{String(carSnapshot.rentPricePerDay)}&#8201;₽</span>
          </div>
          {carSnapshot.rentPricePerDayForGuest !== null && (
            <div className="text-field">
              <span className="label">Цена за день (для госта):</span>
              <span className="text">{String(carSnapshot.rentPricePerDayForGuest)}&#8201;₽</span>
            </div>
          )}
          {carSnapshot.services.additionalKmPrice && (
            <div className="text-field">
              <span className="label">Цена за перепробег:</span>
              <span className="text">{carSnapshot.services.additionalKmPrice}&#8201;₽</span>
            </div>
          )}
          {carSnapshot.services.afterRentWashingPrice && (
            <div className="text-field">
              <span className="label">Цена мойки после аренды:</span>
              <span className="text">{carSnapshot.services.afterRentWashingPrice}&#8201;₽</span>
            </div>
          )}
          {carSnapshot.services.offHoursReturnPrice && (
            <div className="text-field">
              <span className="label">Цена возврата в нерабочее время:</span>
              <span className="text">{carSnapshot.services.offHoursReturnPrice}&#8201;₽</span>
            </div>
          )}
          {carSnapshot.services.fuelLiterCompensationPrice && (
            <div className="text-field">
              <span className="label">Компенсация за литр топлива:</span>
              <span className="text">
                {carSnapshot.services.fuelLiterCompensationPrice}&#8201;₽
              </span>
            </div>
          )}
          {carSnapshot.services.unlimitedMilagePrice && (
            <div className="text-field">
              <span className="label">Цена неограниченного пробега:</span>
              <span className="text">{carSnapshot.services.unlimitedMilagePrice}&#8201;₽</span>
            </div>
          )}
          {carSnapshot.deliveryOptions?.length ? (
            <div className="text-field">
              <span className="label">Форматы доставки:</span>
              <br />
              <span className="text">
                {carSnapshot.deliveryOptions.map(item => (
                  <>
                    <strong>
                      <span>
                        До{' '}
                        {item.type === 'place'
                          ? deliveryPopularPlaces.find(pPlace => pPlace.id === item.placeId)
                              ?.name || 'Места'
                          : deliveryRadii.find(radius => radius.id === item.radiusId)?.text ||
                            'Радиуса'}
                        :{' '}
                      </span>
                      <span>{item.price}&#8201;₽</span>
                    </strong>
                    <br />
                  </>
                ))}
              </span>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};
