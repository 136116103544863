import React from 'react';
import {
  Datagrid,
  FunctionField,
  List,
  Pagination,
  TextField,
  useAuthenticated,
} from 'react-admin';
import { UserFilter } from './components/Filter';

export const UserList = props => {
  useAuthenticated();
  return (
    <List
      {...props}
      filters={<UserFilter />}
      pagination={<Pagination rowsPerPageOptions={[10, 25, 50, 100]} />}
    >
      <Datagrid rowClick="edit" isRowSelectable={() => false}>
        <FunctionField
          label="ФИО"
          render={record => {
            return `${record.lastName} ${record.firstName} ${record.midName}`;
          }}
        />
        <TextField label="Роль" source="role" />
        <TextField label="Статус скоринга" source="scoringStatus" />
        <TextField label="Почта" source="email" />
        <TextField label="Телефон" source="phone" />
      </Datagrid>
    </List>
  );
};
