export const preRentalInspectionSteps = {
  GENERAL: 'Общие фото машины',
  VIN: 'Вин-номер',
  WINDSHIELD_TRUNK_ROOF: 'Крыша/Лобовое/Багажник',
  INTERIOR: 'Салон',
  MILAGE_AND_FUEL: 'Одометр',
  DEFECTS_AND_STAINS: 'Повреждения',
  ADDITIONAL: 'Доп. фотографии',
};
export const postRentalInspectionSteps = {
  GENERAL: 'Фото машины после аренды',
};
